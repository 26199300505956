'use client';

import { useSearchParams } from 'next/navigation';

import { env } from '~/env.mjs';

export const useIsDev = () => {
  const isDev = ['stg', 'dev_r2', 'local'].includes(env.NEXT_PUBLIC_APP_ENV ?? '');
  const searchParams = useSearchParams();

  const isDevDisabledQueryParam = searchParams.has('devDisabled');

  if (isDevDisabledQueryParam) {
    return {
      isDev: false,
    };
  }

  return {
    isDev,
  };
};
