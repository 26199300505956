export type ErrorWrapper<TError> =
  | TError
  | {
      status: number;
      statusText: 'unknown' | (string & {});
      payload: string | undefined;
    };

export interface FetcherOptions<TBody, THeaders, TQueryParams, TPathParams> {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
  origin?: string;
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {},
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) {
    query = `?${query}`;
  }
  return url.replaceAll(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)] ?? '') + query;
};

/**
 * @internal
 */
export const buildFetcher =
  (baseUrl: string) =>
  async <
    TData,
    TError,
    TBody extends {} | undefined | null,
    THeaders extends {},
    TQueryParams extends {},
    TPathParams extends {},
  >({
    url,
    method,
    body,
    headers,
    pathParams,
    queryParams,
    signal,
    origin = '',
  }: FetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> => {
    let error: ErrorWrapper<TError>;
    try {
      let finalBody: string | FormData | undefined;
      const requestHeaders: HeadersInit = {
        'Content-Type': 'application/json',
        ...headers,
      };
      /**
       * As the fetch API is being used, when multipart/form-data is specified
       * the Content-Type header must be deleted so that the browser can set
       * the correct boundary.
       * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
       */
      if (
        requestHeaders['Content-Type'].toLowerCase().includes('multipart/form-data') ||
        body instanceof FormData
      ) {
        delete requestHeaders['Content-Type'];
      }

      if (body instanceof FormData) {
        finalBody = body;
      } else if (body) {
        finalBody = JSON.stringify(body);
      }

      const response = await globalThis.fetch(
        `${origin}${baseUrl}${resolveUrl(url, queryParams, pathParams)}`,
        {
          body: finalBody,
          headers: requestHeaders,
          method: method.toUpperCase(),
          signal,
        },
      );

      if (!response.ok) {
        try {
          if (response.headers.get('content-type')?.includes('json')) {
            error = await response.json();
          } else {
            error = {
              payload: await response.text(),
              status: response.status,
              statusText: response.statusText,
            };
          }
        } catch (error_) {
          error = {
            payload:
              error_ instanceof Error ? `Unexpected error (${error_.message})` : 'Unexpected error',
            status: response.status,
            statusText: 'unknown' as const,
          };
        }

        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw error;
      }

      if (response.headers.get('content-type')?.includes('json')) {
        return await response.json();
      } else {
        // if it is not a json response, assume it is a blob and cast it to TData
        return (await response.blob()) as unknown as TData;
      }
    } catch (error_) {
      const errorObject: Error = {
        name: 'unknown' as const,
        message: error_ instanceof Error ? `Network error (${error_.message})` : 'Network error',
        ...(typeof error_ === 'string' && { message: error_ }),
        ...(typeof error_ === 'object' && error_),
      };

      throw errorObject;
    }
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw error;
  };
