import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    API_DICE_FORWARDING_URL: z.string(),
    API_ERASE_FORWARDING_URL: z.string(),
    API_RAVE_FORWARDING_URL: z.string(),
    API_GRIDDY_FORWARDING_URL: z.string(),
    DEV_USER: z.string().default(''),
    ENABLE_DEV_USER: z.enum(['true', 'false']).default('false'),
    NEXTAUTH_SECRET: z.string(),
    NEXTAUTH_URL: z.string(),
    NODE_ENV: z.enum(['development', 'test', 'production']).default('development'),
    PIAM_CLIENT_SCRT: z.string(),
    PIAM_HOST: z.string(),
    PIAM_URL: z.string(),
  },
  client: {
    NEXT_PUBLIC_AG_GRID_LICENSE: z.string(),
    NEXT_PUBLIC_APP_ENV: z.enum(['prod', 'stg', 'dev_r2', 'local']).default('local').optional(),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    API_DICE_FORWARDING_URL: process.env.API_DICE_FORWARDING_URL,
    API_ERASE_FORWARDING_URL: process.env.API_ERASE_FORWARDING_URL,
    API_RAVE_FORWARDING_URL: process.env.API_RAVE_FORWARDING_URL,
    API_GRIDDY_FORWARDING_URL: process.env.API_GRIDDY_FORWARDING_URL,
    DEV_USER: process.env.DEV_USER,
    ENABLE_DEV_USER: process.env.ENABLE_DEV_USER,
    PIAM_CLIENT_SCRT: process.env.PIAM_CLIENT_SCRT,
    PIAM_HOST: process.env.PIAM_HOST,
    NEXT_PUBLIC_AG_GRID_LICENSE: process.env.NEXT_PUBLIC_AG_GRID_LICENSE,
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    PIAM_URL: process.env.PIAM_URL,
  },

  skipValidation: !!process.env.SKIP_ENV_VALIDATION,

  emptyStringAsUndefined: true,
});
