'use client';

/**
 * `useQueryParam` is a function that returns the value of a query parameter.
 */
import { useParams } from 'next/navigation';

import type { ClientPublicFacilityConfigurationPage } from '~/urls';

/**
 * `usePathParams` is a function that returns the value of a path parameter.
 */
export const usePathParams = (
  defaultValue: Partial<ClientPublicFacilityConfigurationPage> = {},
) => {
  const params = useParams<ClientPublicFacilityConfigurationPage>();
  return {
    ...defaultValue,
    ...params,
  } as unknown as ClientPublicFacilityConfigurationPage;
};
