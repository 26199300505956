/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from '@tanstack/react-query';

import type { DiceContext } from './diceContext';
import type * as Fetcher from './diceFetcher';
import type * as Schemas from './diceSchemas';
import { queryKeyFn, useDiceContext } from './diceContext';
import { diceFetch } from './diceFetcher';
import { deepMerge } from './diceUtils';

interface QueryFnOptions {
  signal?: AbortController['signal'];
}

export interface UpdateAssetExtendedInfoPathParams {
  clientId: string;
}

export type UpdateAssetExtendedInfoError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetExtendedInfoVariables = {
  body: Schemas.ConfigRequest;
  pathParams: UpdateAssetExtendedInfoPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetExtendedInfo = (
  variables: UpdateAssetExtendedInfoVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    string,
    UpdateAssetExtendedInfoError,
    Schemas.ConfigRequest,
    {},
    {},
    UpdateAssetExtendedInfoPathParams
  >({ url: '/clients/{clientId}', method: 'put', ...variables, signal });

export const useUpdateAssetExtendedInfo = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      UpdateAssetExtendedInfoError,
      UpdateAssetExtendedInfoVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    string,
    UpdateAssetExtendedInfoError,
    UpdateAssetExtendedInfoVariables
  >({
    mutationFn: (variables: UpdateAssetExtendedInfoVariables) =>
      fetchUpdateAssetExtendedInfo(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteClientPathParams {
  clientId: string;
}

export interface DeleteClientHeaders {
  authorization?: string;
}

export type DeleteClientError = Fetcher.ErrorWrapper<undefined>;

export type DeleteClientVariables = {
  headers?: DeleteClientHeaders;
  pathParams: DeleteClientPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteClient = (variables: DeleteClientVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    DeleteClientError,
    undefined,
    DeleteClientHeaders,
    {},
    DeleteClientPathParams
  >({ url: '/clients/{clientId}', method: 'delete', ...variables, signal });

export const useDeleteClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteClientError, DeleteClientVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteClientError, DeleteClientVariables>({
    mutationFn: (variables: DeleteClientVariables) =>
      fetchDeleteClient(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllDataContainerPathParams {
  clientId: string;
}

export type GetAllDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDataContainerResponse = Schemas.YearDTO[];

export type GetAllDataContainerVariables = {
  pathParams: GetAllDataContainerPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllDataContainer = (
  variables: GetAllDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllDataContainerResponse,
    GetAllDataContainerError,
    undefined,
    {},
    {},
    GetAllDataContainerPathParams
  >({ url: '/clients/{clientId}/years', method: 'get', ...variables, signal });

export function getAllDataContainerQuery(variables: GetAllDataContainerVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllDataContainerResponse>;
};

export function getAllDataContainerQuery(
  variables: GetAllDataContainerVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAllDataContainerResponse>)
    | reactQuery.SkipToken;
};

export function getAllDataContainerQuery(
  variables: GetAllDataContainerVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/years',
      operationId: 'getAllDataContainer',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllDataContainer(variables, signal),
  };
}

export const useSuspenseGetAllDataContainer = <TData = GetAllDataContainerResponse>(
  variables: GetAllDataContainerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllDataContainerResponse, GetAllDataContainerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllDataContainerResponse, GetAllDataContainerError, TData>({
    ...getAllDataContainerQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllDataContainer = <TData = GetAllDataContainerResponse>(
  variables: GetAllDataContainerVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllDataContainerResponse, GetAllDataContainerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllDataContainerResponse, GetAllDataContainerError, TData>({
    ...getAllDataContainerQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface UpdateDataContainerPathParams {
  clientId: string;
}

export type UpdateDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type UpdateDataContainerVariables = {
  body?: Schemas.YearDTO;
  pathParams: UpdateDataContainerPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateDataContainer = (
  variables: UpdateDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.YearDTO,
    UpdateDataContainerError,
    Schemas.YearDTO,
    {},
    {},
    UpdateDataContainerPathParams
  >({ url: '/clients/{clientId}/years', method: 'put', ...variables, signal });

export const useUpdateDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.YearDTO,
      UpdateDataContainerError,
      UpdateDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.YearDTO,
    UpdateDataContainerError,
    UpdateDataContainerVariables
  >({
    mutationFn: (variables: UpdateDataContainerVariables) =>
      fetchUpdateDataContainer(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface InsertDataContainerPathParams {
  clientId: string;
}

export type InsertDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type InsertDataContainerVariables = {
  body?: Schemas.YearDTO;
  pathParams: InsertDataContainerPathParams;
} & DiceContext['fetcherOptions'];

export const fetchInsertDataContainer = (
  variables: InsertDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.YearDTO,
    InsertDataContainerError,
    Schemas.YearDTO,
    {},
    {},
    InsertDataContainerPathParams
  >({ url: '/clients/{clientId}/years', method: 'post', ...variables, signal });

export const useInsertDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.YearDTO,
      InsertDataContainerError,
      InsertDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.YearDTO,
    InsertDataContainerError,
    InsertDataContainerVariables
  >({
    mutationFn: (variables: InsertDataContainerVariables) =>
      fetchInsertDataContainer(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpsertFileMetadataPathParams {
  clientId: string;
}

export type UpsertFileMetadataError = Fetcher.ErrorWrapper<undefined>;

export type UpsertFileMetadataVariables = {
  body?: Schemas.FileMetadataDTO;
  pathParams: UpsertFileMetadataPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpsertFileMetadata = (
  variables: UpsertFileMetadataVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.FileMetadataDTO,
    UpsertFileMetadataError,
    Schemas.FileMetadataDTO,
    {},
    {},
    UpsertFileMetadataPathParams
  >({
    url: '/clients/{clientId}/year-calculations/files-metadata',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpsertFileMetadata = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FileMetadataDTO,
      UpsertFileMetadataError,
      UpsertFileMetadataVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.FileMetadataDTO,
    UpsertFileMetadataError,
    UpsertFileMetadataVariables
  >({
    mutationFn: (variables: UpsertFileMetadataVariables) =>
      fetchUpsertFileMetadata(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetPublicFacilitiesByClientPathParams {
  clientId: string;
}

export interface GetPublicFacilitiesByClientHeaders {
  authorization?: string;
}

export type GetPublicFacilitiesByClientError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicFacilitiesByClientResponse = Schemas.PublicFacilityDTO[];

export type GetPublicFacilitiesByClientVariables = {
  headers?: GetPublicFacilitiesByClientHeaders;
  pathParams: GetPublicFacilitiesByClientPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPublicFacilitiesByClient = (
  variables: GetPublicFacilitiesByClientVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    undefined,
    GetPublicFacilitiesByClientHeaders,
    {},
    GetPublicFacilitiesByClientPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'get', ...variables, signal });

export function getPublicFacilitiesByClientQuery(variables: GetPublicFacilitiesByClientVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetPublicFacilitiesByClientResponse>;
};

export function getPublicFacilitiesByClientQuery(
  variables: GetPublicFacilitiesByClientVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetPublicFacilitiesByClientResponse>)
    | reactQuery.SkipToken;
};

export function getPublicFacilitiesByClientQuery(
  variables: GetPublicFacilitiesByClientVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/public-facilities',
      operationId: 'getPublicFacilitiesByClient',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetPublicFacilitiesByClient(variables, signal),
  };
}

export const useSuspenseGetPublicFacilitiesByClient = <TData = GetPublicFacilitiesByClientResponse>(
  variables: GetPublicFacilitiesByClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPublicFacilitiesByClientResponse,
      GetPublicFacilitiesByClientError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    TData
  >({
    ...getPublicFacilitiesByClientQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetPublicFacilitiesByClient = <TData = GetPublicFacilitiesByClientResponse>(
  variables: GetPublicFacilitiesByClientVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPublicFacilitiesByClientResponse,
      GetPublicFacilitiesByClientError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    TData
  >({
    ...getPublicFacilitiesByClientQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface PutPublicFacilityPathParams {
  clientId: string;
}

export type PutPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type PutPublicFacilityVariables = {
  body?: Schemas.PublicFacilityDTO;
  pathParams: PutPublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutPublicFacility = (
  variables: PutPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PublicFacilityDTO,
    PutPublicFacilityError,
    Schemas.PublicFacilityDTO,
    {},
    {},
    PutPublicFacilityPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'put', ...variables, signal });

export const usePutPublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PublicFacilityDTO,
      PutPublicFacilityError,
      PutPublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PublicFacilityDTO,
    PutPublicFacilityError,
    PutPublicFacilityVariables
  >({
    mutationFn: (variables: PutPublicFacilityVariables) =>
      fetchPutPublicFacility(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface PostPublicFacilityPathParams {
  clientId: string;
}

export type PostPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type PostPublicFacilityVariables = {
  body?: Schemas.PublicFacilityDTO;
  pathParams: PostPublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostPublicFacility = (
  variables: PostPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PublicFacilityDTO,
    PostPublicFacilityError,
    Schemas.PublicFacilityDTO,
    {},
    {},
    PostPublicFacilityPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'post', ...variables, signal });

export const usePostPublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PublicFacilityDTO,
      PostPublicFacilityError,
      PostPublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PublicFacilityDTO,
    PostPublicFacilityError,
    PostPublicFacilityVariables
  >({
    mutationFn: (variables: PostPublicFacilityVariables) =>
      fetchPostPublicFacility(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetYearPathParams {
  publicFacilityId: string;
  yearId: string;
  clientId: string;
}

export type GetYearError = Fetcher.ErrorWrapper<undefined>;

export type GetYearVariables = {
  pathParams: GetYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetYear = (variables: GetYearVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.YearDTOV2, GetYearError, undefined, {}, {}, GetYearPathParams>({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/{yearId}',
    method: 'get',
    ...variables,
    signal,
  });

export function getYearQuery(variables: GetYearVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.YearDTOV2>;
};

export function getYearQuery(variables: GetYearVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.YearDTOV2>) | reactQuery.SkipToken;
};

export function getYearQuery(variables: GetYearVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/{yearId}',
      operationId: 'getYear',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetYear(variables, signal),
  };
}

export const useSuspenseGetYear = <TData = Schemas.YearDTOV2>(
  variables: GetYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearDTOV2, GetYearError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.YearDTOV2, GetYearError, TData>({
    ...getYearQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetYear = <TData = Schemas.YearDTOV2>(
  variables: GetYearVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearDTOV2, GetYearError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.YearDTOV2, GetYearError, TData>({
    ...getYearQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface PutYearPathParams {
  publicFacilityId: string;
  yearId: string;
  clientId: string;
}

export type PutYearError = Fetcher.ErrorWrapper<undefined>;

export type PutYearVariables = {
  body?: Schemas.YearDTOV2;
  pathParams: PutYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutYear = (variables: PutYearVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.YearDTOV2, PutYearError, Schemas.YearDTOV2, {}, {}, PutYearPathParams>({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/{yearId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.YearDTOV2, PutYearError, PutYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.YearDTOV2, PutYearError, PutYearVariables>({
    mutationFn: (variables: PutYearVariables) => fetchPutYear(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationVariables = {
  pathParams: GetMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfiguration = (
  variables: GetMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    GetMasterConfigurationError,
    undefined,
    {},
    {},
    GetMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export function getMasterConfigurationQuery(variables: GetMasterConfigurationVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.MasterConfiguration>;
};

export function getMasterConfigurationQuery(
  variables: GetMasterConfigurationVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.MasterConfiguration>)
    | reactQuery.SkipToken;
};

export function getMasterConfigurationQuery(
  variables: GetMasterConfigurationVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}',
      operationId: 'getMasterConfiguration',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetMasterConfiguration(variables, signal),
  };
}

export const useSuspenseGetMasterConfiguration = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.MasterConfiguration,
    GetMasterConfigurationError,
    TData
  >({
    ...getMasterConfigurationQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfiguration = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>({
    ...getMasterConfigurationQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface PutMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type PutMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type PutMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
  pathParams: PutMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutMasterConfiguration = (
  variables: PutMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    PutMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    PutMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfiguration,
      PutMasterConfigurationError,
      PutMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfiguration,
    PutMasterConfigurationError,
    PutMasterConfigurationVariables
  >({
    mutationFn: (variables: PutMasterConfigurationVariables) =>
      fetchPutMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export interface DeleteMasterConfigurationHeaders {
  authorization?: string;
}

export type DeleteMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteMasterConfigurationVariables = {
  headers?: DeleteMasterConfigurationHeaders;
  pathParams: DeleteMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteMasterConfiguration = (
  variables: DeleteMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteMasterConfigurationError,
    undefined,
    DeleteMasterConfigurationHeaders,
    {},
    DeleteMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteMasterConfigurationError,
      DeleteMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteMasterConfigurationError,
    DeleteMasterConfigurationVariables
  >({
    mutationFn: (variables: DeleteMasterConfigurationVariables) =>
      fetchDeleteMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetTechnicalDataByMasterConfigurationIdPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetTechnicalDataByMasterConfigurationIdError = Fetcher.ErrorWrapper<undefined>;

export type GetTechnicalDataByMasterConfigurationIdResponse = Schemas.TechnicalDataDTO[];

export type GetTechnicalDataByMasterConfigurationIdVariables = {
  pathParams: GetTechnicalDataByMasterConfigurationIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetTechnicalDataByMasterConfigurationId = (
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    undefined,
    {},
    {},
    GetTechnicalDataByMasterConfigurationIdPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/technical-data',
    method: 'get',
    ...variables,
    signal,
  });

export function getTechnicalDataByMasterConfigurationIdQuery(
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetTechnicalDataByMasterConfigurationIdResponse>;
};

export function getTechnicalDataByMasterConfigurationIdQuery(
  variables: GetTechnicalDataByMasterConfigurationIdVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetTechnicalDataByMasterConfigurationIdResponse>)
    | reactQuery.SkipToken;
};

export function getTechnicalDataByMasterConfigurationIdQuery(
  variables: GetTechnicalDataByMasterConfigurationIdVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/technical-data',
      operationId: 'getTechnicalDataByMasterConfigurationId',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) =>
            fetchGetTechnicalDataByMasterConfigurationId(variables, signal),
  };
}

export const useSuspenseGetTechnicalDataByMasterConfigurationId = <
  TData = GetTechnicalDataByMasterConfigurationIdResponse,
>(
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTechnicalDataByMasterConfigurationIdResponse,
      GetTechnicalDataByMasterConfigurationIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    TData
  >({
    ...getTechnicalDataByMasterConfigurationIdQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetTechnicalDataByMasterConfigurationId = <
  TData = GetTechnicalDataByMasterConfigurationIdResponse,
>(
  variables: GetTechnicalDataByMasterConfigurationIdVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTechnicalDataByMasterConfigurationIdResponse,
      GetTechnicalDataByMasterConfigurationIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    TData
  >({
    ...getTechnicalDataByMasterConfigurationIdQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface PutTechnicalDataEntriesPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type PutTechnicalDataEntriesError = Fetcher.ErrorWrapper<undefined>;

export type PutTechnicalDataEntriesResponse = Schemas.TechnicalDataDTO[];

export type PutTechnicalDataEntriesRequestBody = Schemas.TechnicalDataDTO[];

export type PutTechnicalDataEntriesVariables = {
  body?: PutTechnicalDataEntriesRequestBody;
  pathParams: PutTechnicalDataEntriesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutTechnicalDataEntries = (
  variables: PutTechnicalDataEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    PutTechnicalDataEntriesResponse,
    PutTechnicalDataEntriesError,
    PutTechnicalDataEntriesRequestBody,
    {},
    {},
    PutTechnicalDataEntriesPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/technical-data',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutTechnicalDataEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PutTechnicalDataEntriesResponse,
      PutTechnicalDataEntriesError,
      PutTechnicalDataEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    PutTechnicalDataEntriesResponse,
    PutTechnicalDataEntriesError,
    PutTechnicalDataEntriesVariables
  >({
    mutationFn: (variables: PutTechnicalDataEntriesVariables) =>
      fetchPutTechnicalDataEntries(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface PostTechnicalDataEntryPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type PostTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type PostTechnicalDataEntryVariables = {
  body?: Schemas.TechnicalDataDTO;
  pathParams: PostTechnicalDataEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostTechnicalDataEntry = (
  variables: PostTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.TechnicalDataDTO,
    PostTechnicalDataEntryError,
    Schemas.TechnicalDataDTO,
    {},
    {},
    PostTechnicalDataEntryPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/technical-data',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TechnicalDataDTO,
      PostTechnicalDataEntryError,
      PostTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.TechnicalDataDTO,
    PostTechnicalDataEntryError,
    PostTechnicalDataEntryVariables
  >({
    mutationFn: (variables: PostTechnicalDataEntryVariables) =>
      fetchPostTechnicalDataEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface PutTechnicalDataEntryPathParams {
  masterConfigurationId: string;
  id: string;
  clientId: string;
}

export type PutTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutTechnicalDataEntryVariables = {
  body?: Schemas.TechnicalDataDTO;
  pathParams: PutTechnicalDataEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutTechnicalDataEntry = (
  variables: PutTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.TechnicalDataDTO,
    PutTechnicalDataEntryError,
    Schemas.TechnicalDataDTO,
    {},
    {},
    PutTechnicalDataEntryPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/technical-data/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TechnicalDataDTO,
      PutTechnicalDataEntryError,
      PutTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.TechnicalDataDTO,
    PutTechnicalDataEntryError,
    PutTechnicalDataEntryVariables
  >({
    mutationFn: (variables: PutTechnicalDataEntryVariables) =>
      fetchPutTechnicalDataEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface RestoreBackupOfMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type RestoreBackupOfMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type RestoreBackupOfMasterConfigurationVariables = {
  pathParams: RestoreBackupOfMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchRestoreBackupOfMasterConfiguration = (
  variables: RestoreBackupOfMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    RestoreBackupOfMasterConfigurationError,
    undefined,
    {},
    {},
    RestoreBackupOfMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/restore-backup',
    method: 'put',
    ...variables,
    signal,
  });

export const useRestoreBackupOfMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RestoreBackupOfMasterConfigurationError,
      RestoreBackupOfMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    RestoreBackupOfMasterConfigurationError,
    RestoreBackupOfMasterConfigurationVariables
  >({
    mutationFn: (variables: RestoreBackupOfMasterConfigurationVariables) =>
      fetchRestoreBackupOfMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface PromoteMasterConfigurationToPrimaryPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type PromoteMasterConfigurationToPrimaryError = Fetcher.ErrorWrapper<undefined>;

export type PromoteMasterConfigurationToPrimaryVariables = {
  pathParams: PromoteMasterConfigurationToPrimaryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPromoteMasterConfigurationToPrimary = (
  variables: PromoteMasterConfigurationToPrimaryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    PromoteMasterConfigurationToPrimaryError,
    undefined,
    {},
    {},
    PromoteMasterConfigurationToPrimaryPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/promote',
    method: 'put',
    ...variables,
    signal,
  });

export const usePromoteMasterConfigurationToPrimary = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PromoteMasterConfigurationToPrimaryError,
      PromoteMasterConfigurationToPrimaryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    PromoteMasterConfigurationToPrimaryError,
    PromoteMasterConfigurationToPrimaryVariables
  >({
    mutationFn: (variables: PromoteMasterConfigurationToPrimaryVariables) =>
      fetchPromoteMasterConfigurationToPrimary(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SavePlannedCostRevenueCostTypeAccountEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
  clientId: string;
}

export type SavePlannedCostRevenueCostTypeAccountEntryError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueCostTypeAccountEntryVariables = {
  body?: Schemas.PlannedCostTypeAccountEntryDTO;
  pathParams: SavePlannedCostRevenueCostTypeAccountEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueCostTypeAccountEntry = (
  variables: SavePlannedCostRevenueCostTypeAccountEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostTypeAccountEntryDTO,
    SavePlannedCostRevenueCostTypeAccountEntryError,
    Schemas.PlannedCostTypeAccountEntryDTO,
    {},
    {},
    SavePlannedCostRevenueCostTypeAccountEntryPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueCostTypeAccountEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostTypeAccountEntryDTO,
      SavePlannedCostRevenueCostTypeAccountEntryError,
      SavePlannedCostRevenueCostTypeAccountEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostTypeAccountEntryDTO,
    SavePlannedCostRevenueCostTypeAccountEntryError,
    SavePlannedCostRevenueCostTypeAccountEntryVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueCostTypeAccountEntryVariables) =>
      fetchSavePlannedCostRevenueCostTypeAccountEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SavePlannedCostRevenueAccountEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
  plannedAccountEntryId: string;
  clientId: string;
}

export type SavePlannedCostRevenueAccountEntryError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueAccountEntryVariables = {
  body?: Schemas.PlannedAccountEntryDTO;
  pathParams: SavePlannedCostRevenueAccountEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueAccountEntry = (
  variables: SavePlannedCostRevenueAccountEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedAccountEntryDTO,
    SavePlannedCostRevenueAccountEntryError,
    Schemas.PlannedAccountEntryDTO,
    {},
    {},
    SavePlannedCostRevenueAccountEntryPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}/account/{plannedAccountEntryId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueAccountEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedAccountEntryDTO,
      SavePlannedCostRevenueAccountEntryError,
      SavePlannedCostRevenueAccountEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedAccountEntryDTO,
    SavePlannedCostRevenueAccountEntryError,
    SavePlannedCostRevenueAccountEntryVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueAccountEntryVariables) =>
      fetchSavePlannedCostRevenueAccountEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SavePlannedCostRevenueConfigDetailsPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type SavePlannedCostRevenueConfigDetailsError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueConfigDetailsVariables = {
  body?: Schemas.PlannedCostRevenueDetailDTO;
  pathParams: SavePlannedCostRevenueConfigDetailsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueConfigDetails = (
  variables: SavePlannedCostRevenueConfigDetailsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    SavePlannedCostRevenueConfigDetailsError,
    Schemas.PlannedCostRevenueDetailDTO,
    {},
    {},
    SavePlannedCostRevenueConfigDetailsPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueConfigDetails = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      SavePlannedCostRevenueConfigDetailsError,
      SavePlannedCostRevenueConfigDetailsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostRevenueConfigDTO,
    SavePlannedCostRevenueConfigDetailsError,
    SavePlannedCostRevenueConfigDetailsVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueConfigDetailsVariables) =>
      fetchSavePlannedCostRevenueConfigDetails(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreatePlannedCostRevenueConfigDetailsPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type CreatePlannedCostRevenueConfigDetailsError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlannedCostRevenueConfigDetailsVariables = {
  body?: Schemas.PlannedCostRevenueDetailDTO;
  pathParams: CreatePlannedCostRevenueConfigDetailsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePlannedCostRevenueConfigDetails = (
  variables: CreatePlannedCostRevenueConfigDetailsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    CreatePlannedCostRevenueConfigDetailsError,
    Schemas.PlannedCostRevenueDetailDTO,
    {},
    {},
    CreatePlannedCostRevenueConfigDetailsPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePlannedCostRevenueConfigDetails = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      CreatePlannedCostRevenueConfigDetailsError,
      CreatePlannedCostRevenueConfigDetailsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostRevenueConfigDTO,
    CreatePlannedCostRevenueConfigDetailsError,
    CreatePlannedCostRevenueConfigDetailsVariables
  >({
    mutationFn: (variables: CreatePlannedCostRevenueConfigDetailsVariables) =>
      fetchCreatePlannedCostRevenueConfigDetails(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface FinalizeMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type FinalizeMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type FinalizeMasterConfigurationVariables = {
  pathParams: FinalizeMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchFinalizeMasterConfiguration = (
  variables: FinalizeMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    FinalizeMasterConfigurationError,
    undefined,
    {},
    {},
    FinalizeMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/finalize',
    method: 'put',
    ...variables,
    signal,
  });

export const useFinalizeMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FinalizeMasterConfigurationError,
      FinalizeMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    FinalizeMasterConfigurationError,
    FinalizeMasterConfigurationVariables
  >({
    mutationFn: (variables: FinalizeMasterConfigurationVariables) =>
      fetchFinalizeMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface PutMasterConfigurationEntityPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type PutMasterConfigurationEntityError = Fetcher.ErrorWrapper<undefined>;

export type PutMasterConfigurationEntityVariables = {
  body?: Schemas.MasterConfigurationEntityDTO;
  pathParams: PutMasterConfigurationEntityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutMasterConfigurationEntity = (
  variables: PutMasterConfigurationEntityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    PutMasterConfigurationEntityError,
    Schemas.MasterConfigurationEntityDTO,
    {},
    {},
    PutMasterConfigurationEntityPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/entities',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutMasterConfigurationEntity = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      PutMasterConfigurationEntityError,
      PutMasterConfigurationEntityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    PutMasterConfigurationEntityError,
    PutMasterConfigurationEntityVariables
  >({
    mutationFn: (variables: PutMasterConfigurationEntityVariables) =>
      fetchPutMasterConfigurationEntity(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetCostUnitAllocationConfigPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetCostUnitAllocationConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetCostUnitAllocationConfigResponse = Schemas.CostUnitAllocationConfigDTO[];

export type GetCostUnitAllocationConfigVariables = {
  pathParams: GetCostUnitAllocationConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetCostUnitAllocationConfig = (
  variables: GetCostUnitAllocationConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostUnitAllocationConfigResponse,
    GetCostUnitAllocationConfigError,
    undefined,
    {},
    {},
    GetCostUnitAllocationConfigPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations',
    method: 'get',
    ...variables,
    signal,
  });

export function getCostUnitAllocationConfigQuery(variables: GetCostUnitAllocationConfigVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetCostUnitAllocationConfigResponse>;
};

export function getCostUnitAllocationConfigQuery(
  variables: GetCostUnitAllocationConfigVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetCostUnitAllocationConfigResponse>)
    | reactQuery.SkipToken;
};

export function getCostUnitAllocationConfigQuery(
  variables: GetCostUnitAllocationConfigVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations',
      operationId: 'getCostUnitAllocationConfig',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetCostUnitAllocationConfig(variables, signal),
  };
}

export const useSuspenseGetCostUnitAllocationConfig = <TData = GetCostUnitAllocationConfigResponse>(
  variables: GetCostUnitAllocationConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCostUnitAllocationConfigResponse,
      GetCostUnitAllocationConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetCostUnitAllocationConfigResponse,
    GetCostUnitAllocationConfigError,
    TData
  >({
    ...getCostUnitAllocationConfigQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetCostUnitAllocationConfig = <TData = GetCostUnitAllocationConfigResponse>(
  variables: GetCostUnitAllocationConfigVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCostUnitAllocationConfigResponse,
      GetCostUnitAllocationConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetCostUnitAllocationConfigResponse,
    GetCostUnitAllocationConfigError,
    TData
  >({
    ...getCostUnitAllocationConfigQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface SaveCostUnitAllocationConfigPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type SaveCostUnitAllocationConfigError = Fetcher.ErrorWrapper<undefined>;

export type SaveCostUnitAllocationConfigResponse = Schemas.CostUnitAllocationConfigDTO[];

export type SaveCostUnitAllocationConfigRequestBody = Schemas.CostUnitAllocationConfigDTO[];

export type SaveCostUnitAllocationConfigVariables = {
  body?: SaveCostUnitAllocationConfigRequestBody;
  pathParams: SaveCostUnitAllocationConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveCostUnitAllocationConfig = (
  variables: SaveCostUnitAllocationConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    SaveCostUnitAllocationConfigResponse,
    SaveCostUnitAllocationConfigError,
    SaveCostUnitAllocationConfigRequestBody,
    {},
    {},
    SaveCostUnitAllocationConfigPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveCostUnitAllocationConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SaveCostUnitAllocationConfigResponse,
      SaveCostUnitAllocationConfigError,
      SaveCostUnitAllocationConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    SaveCostUnitAllocationConfigResponse,
    SaveCostUnitAllocationConfigError,
    SaveCostUnitAllocationConfigVariables
  >({
    mutationFn: (variables: SaveCostUnitAllocationConfigVariables) =>
      fetchSaveCostUnitAllocationConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SaveCostUnitAllocationConfigurationPathParams {
  masterConfigurationId: string;
  costUnitAllocationConfigId: string;
  clientId: string;
}

export type SaveCostUnitAllocationConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type SaveCostUnitAllocationConfigurationVariables = {
  body?: Schemas.CostUnitAllocationConfigDTO;
  pathParams: SaveCostUnitAllocationConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveCostUnitAllocationConfiguration = (
  variables: SaveCostUnitAllocationConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitAllocationConfigDTO,
    SaveCostUnitAllocationConfigurationError,
    Schemas.CostUnitAllocationConfigDTO,
    {},
    {},
    SaveCostUnitAllocationConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations/{costUnitAllocationConfigId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveCostUnitAllocationConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitAllocationConfigDTO,
      SaveCostUnitAllocationConfigurationError,
      SaveCostUnitAllocationConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitAllocationConfigDTO,
    SaveCostUnitAllocationConfigurationError,
    SaveCostUnitAllocationConfigurationVariables
  >({
    mutationFn: (variables: SaveCostUnitAllocationConfigurationVariables) =>
      fetchSaveCostUnitAllocationConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ActivateMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type ActivateMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type ActivateMasterConfigurationVariables = {
  pathParams: ActivateMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchActivateMasterConfiguration = (
  variables: ActivateMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    ActivateMasterConfigurationError,
    undefined,
    {},
    {},
    ActivateMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/activate',
    method: 'put',
    ...variables,
    signal,
  });

export const useActivateMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActivateMasterConfigurationError,
      ActivateMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    ActivateMasterConfigurationError,
    ActivateMasterConfigurationVariables
  >({
    mutationFn: (variables: ActivateMasterConfigurationVariables) =>
      fetchActivateMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface PutRangePathParams {
  masterConfigurationId: string;
  id: string;
  clientId: string;
}

export type PutRangeError = Fetcher.ErrorWrapper<undefined>;

export type PutRangeVariables = {
  body?: Schemas.RangeDTO;
  pathParams: PutRangePathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutRange = (variables: PutRangeVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.RangeConfigDTO, PutRangeError, Schemas.RangeDTO, {}, {}, PutRangePathParams>({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, PutRangeError, PutRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, PutRangeError, PutRangeVariables>({
    mutationFn: (variables: PutRangeVariables) =>
      fetchPutRange(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteRangePathParams {
  masterConfigurationId: string;
  id: string;
  clientId: string;
}

export type DeleteRangeError = Fetcher.ErrorWrapper<undefined>;

export type DeleteRangeVariables = {
  pathParams: DeleteRangePathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteRange = (variables: DeleteRangeVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.RangeConfigDTO, DeleteRangeError, undefined, {}, {}, DeleteRangePathParams>({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, DeleteRangeError, DeleteRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, DeleteRangeError, DeleteRangeVariables>({
    mutationFn: (variables: DeleteRangeVariables) =>
      fetchDeleteRange(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UseJournalAccountsPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type UseJournalAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UseJournalAccountsVariables = {
  pathParams: UseJournalAccountsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUseJournalAccounts = (
  variables: UseJournalAccountsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.RangeConfigDTO,
    UseJournalAccountsError,
    undefined,
    {},
    {},
    UseJournalAccountsPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges/use-journal-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUseJournalAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RangeConfigDTO,
      UseJournalAccountsError,
      UseJournalAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.RangeConfigDTO,
    UseJournalAccountsError,
    UseJournalAccountsVariables
  >({
    mutationFn: (variables: UseJournalAccountsVariables) =>
      fetchUseJournalAccounts(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UseAccountPlanAccountsPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type UseAccountPlanAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UseAccountPlanAccountsVariables = {
  pathParams: UseAccountPlanAccountsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUseAccountPlanAccounts = (
  variables: UseAccountPlanAccountsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.RangeConfigDTO,
    UseAccountPlanAccountsError,
    undefined,
    {},
    {},
    UseAccountPlanAccountsPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges/use-account-plan-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUseAccountPlanAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RangeConfigDTO,
      UseAccountPlanAccountsError,
      UseAccountPlanAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.RangeConfigDTO,
    UseAccountPlanAccountsError,
    UseAccountPlanAccountsVariables
  >({
    mutationFn: (variables: UseAccountPlanAccountsVariables) =>
      fetchUseAccountPlanAccounts(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdateMasterConfigurationsPathParams {
  clientId: string;
}

export type UpdateMasterConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateMasterConfigurationsVariables = {
  body?: Schemas.MasterConfigurationRequest;
  pathParams: UpdateMasterConfigurationsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateMasterConfigurations = (
  variables: UpdateMasterConfigurationsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.EntityUpsertedResponse,
    UpdateMasterConfigurationsError,
    Schemas.MasterConfigurationRequest,
    {},
    {},
    UpdateMasterConfigurationsPathParams
  >({ url: '/clients/{clientId}/master-configuration', method: 'put', ...variables, signal });

export const useUpdateMasterConfigurations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EntityUpsertedResponse,
      UpdateMasterConfigurationsError,
      UpdateMasterConfigurationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.EntityUpsertedResponse,
    UpdateMasterConfigurationsError,
    UpdateMasterConfigurationsVariables
  >({
    mutationFn: (variables: UpdateMasterConfigurationsVariables) =>
      fetchUpdateMasterConfigurations(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpsertMasterConfigurationPathParams {
  clientId: string;
}

export type UpsertMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type UpsertMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
  pathParams: UpsertMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpsertMasterConfiguration = (
  variables: UpsertMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.EntityUpsertedResponse,
    UpsertMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    UpsertMasterConfigurationPathParams
  >({ url: '/clients/{clientId}/master-configuration', method: 'post', ...variables, signal });

export const useUpsertMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EntityUpsertedResponse,
      UpsertMasterConfigurationError,
      UpsertMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.EntityUpsertedResponse,
    UpsertMasterConfigurationError,
    UpsertMasterConfigurationVariables
  >({
    mutationFn: (variables: UpsertMasterConfigurationVariables) =>
      fetchUpsertMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdateCostTypeAccountConfigAccountsPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type UpdateCostTypeAccountConfigAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateCostTypeAccountConfigAccountsVariables = {
  pathParams: UpdateCostTypeAccountConfigAccountsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateCostTypeAccountConfigAccounts = (
  variables: UpdateCostTypeAccountConfigAccountsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdateCostTypeAccountConfigAccountsError,
    undefined,
    {},
    {},
    UpdateCostTypeAccountConfigAccountsPathParams
  >({
    url: '/clients/{clientId}/master-configs/{masterConfigurationId}/cost-type-accounts/update-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateCostTypeAccountConfigAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateCostTypeAccountConfigAccountsError,
      UpdateCostTypeAccountConfigAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateCostTypeAccountConfigAccountsError,
    UpdateCostTypeAccountConfigAccountsVariables
  >({
    mutationFn: (variables: UpdateCostTypeAccountConfigAccountsVariables) =>
      fetchUpdateCostTypeAccountConfigAccounts(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SaveAllocationKeyConfigurationPathParams {
  masterConfigurationId: string;
  allocationKeyConfigId: string;
  clientId: string;
}

export type SaveAllocationKeyConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type SaveAllocationKeyConfigurationVariables = {
  body?: Schemas.AllocationKeyConfigDTO;
  pathParams: SaveAllocationKeyConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveAllocationKeyConfiguration = (
  variables: SaveAllocationKeyConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AllocationKeyConfigDTO,
    SaveAllocationKeyConfigurationError,
    Schemas.AllocationKeyConfigDTO,
    {},
    {},
    SaveAllocationKeyConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configs/{masterConfigurationId}/allocation-key-configs/{allocationKeyConfigId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveAllocationKeyConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AllocationKeyConfigDTO,
      SaveAllocationKeyConfigurationError,
      SaveAllocationKeyConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AllocationKeyConfigDTO,
    SaveAllocationKeyConfigurationError,
    SaveAllocationKeyConfigurationVariables
  >({
    mutationFn: (variables: SaveAllocationKeyConfigurationVariables) =>
      fetchSaveAllocationKeyConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpsertConfigPathParams {
  clientId: string;
}

export type UpsertConfigError = Fetcher.ErrorWrapper<undefined>;

export type UpsertConfigVariables = {
  body?: Schemas.CostTypeAccountConfigDTO;
  pathParams: UpsertConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpsertConfig = (variables: UpsertConfigVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.CostTypeAccountConfigDTO,
    UpsertConfigError,
    Schemas.CostTypeAccountConfigDTO,
    {},
    {},
    UpsertConfigPathParams
  >({
    url: '/clients/{clientId}/master-configs/cost-type-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpsertConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostTypeAccountConfigDTO,
      UpsertConfigError,
      UpsertConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostTypeAccountConfigDTO,
    UpsertConfigError,
    UpsertConfigVariables
  >({
    mutationFn: (variables: UpsertConfigVariables) =>
      fetchUpsertConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SaveAllocationKeyConfigPathParams {
  clientId: string;
}

export type SaveAllocationKeyConfigError = Fetcher.ErrorWrapper<undefined>;

export type SaveAllocationKeyConfigResponse = Schemas.AllocationKeyConfigDTO[];

export type SaveAllocationKeyConfigRequestBody = Schemas.AllocationKeyConfigDTO[];

export type SaveAllocationKeyConfigVariables = {
  body?: SaveAllocationKeyConfigRequestBody;
  pathParams: SaveAllocationKeyConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveAllocationKeyConfig = (
  variables: SaveAllocationKeyConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    SaveAllocationKeyConfigResponse,
    SaveAllocationKeyConfigError,
    SaveAllocationKeyConfigRequestBody,
    {},
    {},
    SaveAllocationKeyConfigPathParams
  >({
    url: '/clients/{clientId}/master-configs/allocation-key-configs',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveAllocationKeyConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SaveAllocationKeyConfigResponse,
      SaveAllocationKeyConfigError,
      SaveAllocationKeyConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    SaveAllocationKeyConfigResponse,
    SaveAllocationKeyConfigError,
    SaveAllocationKeyConfigVariables
  >({
    mutationFn: (variables: SaveAllocationKeyConfigVariables) =>
      fetchSaveAllocationKeyConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetFileErpTypesPathParams {
  clientId: string;
}

export interface GetFileErpTypesQueryParams {
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  yearId: string;
}

export type GetFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type GetFileErpTypesResponse = Schemas.FileErpTypeDTO[];

export type GetFileErpTypesVariables = {
  pathParams: GetFileErpTypesPathParams;
  queryParams: GetFileErpTypesQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFileErpTypes = (variables: GetFileErpTypesVariables, signal?: AbortSignal) =>
  diceFetch<
    GetFileErpTypesResponse,
    GetFileErpTypesError,
    undefined,
    {},
    GetFileErpTypesQueryParams,
    GetFileErpTypesPathParams
  >({ url: '/clients/{clientId}/imports/fileErpTypes', method: 'get', ...variables, signal });

export function getFileErpTypesQuery(variables: GetFileErpTypesVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetFileErpTypesResponse>;
};

export function getFileErpTypesQuery(variables: GetFileErpTypesVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetFileErpTypesResponse>) | reactQuery.SkipToken;
};

export function getFileErpTypesQuery(variables: GetFileErpTypesVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/imports/fileErpTypes',
      operationId: 'getFileErpTypes',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetFileErpTypes(variables, signal),
  };
}

export const useSuspenseGetFileErpTypes = <TData = GetFileErpTypesResponse>(
  variables: GetFileErpTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFileErpTypesResponse, GetFileErpTypesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetFileErpTypesResponse, GetFileErpTypesError, TData>({
    ...getFileErpTypesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetFileErpTypes = <TData = GetFileErpTypesResponse>(
  variables: GetFileErpTypesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFileErpTypesResponse, GetFileErpTypesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetFileErpTypesResponse, GetFileErpTypesError, TData>({
    ...getFileErpTypesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface UpdateFileErpTypesPathParams {
  clientId: string;
}

export type UpdateFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFileErpTypesVariables = {
  body?: Schemas.FileErpTypeDTO;
  pathParams: UpdateFileErpTypesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateFileErpTypes = (
  variables: UpdateFileErpTypesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    string,
    UpdateFileErpTypesError,
    Schemas.FileErpTypeDTO,
    {},
    {},
    UpdateFileErpTypesPathParams
  >({ url: '/clients/{clientId}/imports/fileErpTypes', method: 'put', ...variables, signal });

export const useUpdateFileErpTypes = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, UpdateFileErpTypesError, UpdateFileErpTypesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, UpdateFileErpTypesError, UpdateFileErpTypesVariables>({
    mutationFn: (variables: UpdateFileErpTypesVariables) =>
      fetchUpdateFileErpTypes(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SaveFileErpTypesPathParams {
  clientId: string;
}

export type SaveFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type SaveFileErpTypesVariables = {
  body?: Schemas.FileErpTypeDTO;
  pathParams: SaveFileErpTypesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveFileErpTypes = (variables: SaveFileErpTypesVariables, signal?: AbortSignal) =>
  diceFetch<
    string,
    SaveFileErpTypesError,
    Schemas.FileErpTypeDTO,
    {},
    {},
    SaveFileErpTypesPathParams
  >({ url: '/clients/{clientId}/imports/fileErpTypes', method: 'post', ...variables, signal });

export const useSaveFileErpTypes = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, SaveFileErpTypesError, SaveFileErpTypesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, SaveFileErpTypesError, SaveFileErpTypesVariables>({
    mutationFn: (variables: SaveFileErpTypesVariables) =>
      fetchSaveFileErpTypes(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteFileErpTypesPathParams {
  clientId: string;
}

export type DeleteFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileErpTypesVariables = {
  body?: Schemas.FileErpTypeDTO;
  pathParams: DeleteFileErpTypesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFileErpTypes = (
  variables: DeleteFileErpTypesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    string,
    DeleteFileErpTypesError,
    Schemas.FileErpTypeDTO,
    {},
    {},
    DeleteFileErpTypesPathParams
  >({ url: '/clients/{clientId}/imports/fileErpTypes', method: 'delete', ...variables, signal });

export const useDeleteFileErpTypes = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, DeleteFileErpTypesError, DeleteFileErpTypesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, DeleteFileErpTypesError, DeleteFileErpTypesVariables>({
    mutationFn: (variables: DeleteFileErpTypesVariables) =>
      fetchDeleteFileErpTypes(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllAssetsPathParams {
  clientId: string;
}

export interface GetAllAssetsQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
  assetType?: 'UPLOADED_ASSET' | 'ERP_ASSET' | 'FORECAST_ASSET' | 'FUTURE_ASSET';
}

export type GetAllAssetsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssetsResponse = Schemas.Asset[];

export type GetAllAssetsVariables = {
  pathParams: GetAllAssetsPathParams;
  queryParams: GetAllAssetsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssets = (variables: GetAllAssetsVariables, signal?: AbortSignal) =>
  diceFetch<
    GetAllAssetsResponse,
    GetAllAssetsError,
    undefined,
    {},
    GetAllAssetsQueryParams,
    GetAllAssetsPathParams
  >({ url: '/clients/{clientId}/assets', method: 'get', ...variables, signal });

export function getAllAssetsQuery(variables: GetAllAssetsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllAssetsResponse>;
};

export function getAllAssetsQuery(variables: GetAllAssetsVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetAllAssetsResponse>) | reactQuery.SkipToken;
};

export function getAllAssetsQuery(variables: GetAllAssetsVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/assets',
      operationId: 'getAllAssets',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllAssets(variables, signal),
  };
}

export const useSuspenseGetAllAssets = <TData = GetAllAssetsResponse>(
  variables: GetAllAssetsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllAssetsResponse, GetAllAssetsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllAssetsResponse, GetAllAssetsError, TData>({
    ...getAllAssetsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssets = <TData = GetAllAssetsResponse>(
  variables: GetAllAssetsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllAssetsResponse, GetAllAssetsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllAssetsResponse, GetAllAssetsError, TData>({
    ...getAllAssetsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface UpdateAssetPathParams {
  clientId: string;
}

export type UpdateAssetError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetVariables = {
  body?: Schemas.Asset;
  pathParams: UpdateAssetPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAsset = (variables: UpdateAssetVariables, signal?: AbortSignal) =>
  diceFetch<undefined, UpdateAssetError, Schemas.Asset, {}, {}, UpdateAssetPathParams>({
    url: '/clients/{clientId}/assets',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateAsset = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdateAssetError, UpdateAssetVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, UpdateAssetError, UpdateAssetVariables>({
    mutationFn: (variables: UpdateAssetVariables) =>
      fetchUpdateAsset(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface InsertAssetsPathParams {
  clientId: string;
}

export type InsertAssetsError = Fetcher.ErrorWrapper<undefined>;

export type InsertAssetsResponse = Schemas.Asset[];

export type InsertAssetsVariables = {
  body: Schemas.AssetFileRequest;
  pathParams: InsertAssetsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchInsertAssets = (variables: InsertAssetsVariables, signal?: AbortSignal) =>
  diceFetch<
    InsertAssetsResponse,
    InsertAssetsError,
    Schemas.AssetFileRequest,
    {},
    {},
    InsertAssetsPathParams
  >({ url: '/clients/{clientId}/assets', method: 'post', ...variables, signal });

export const useInsertAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<InsertAssetsResponse, InsertAssetsError, InsertAssetsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<InsertAssetsResponse, InsertAssetsError, InsertAssetsVariables>({
    mutationFn: (variables: InsertAssetsVariables) =>
      fetchInsertAssets(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteAssetsPathParams {
  clientId: string;
}

export interface DeleteAssetsQueryParams {
  yearId: string;
  masterConfigurationId?: string;
}

export type DeleteAssetsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAssetsVariables = {
  pathParams: DeleteAssetsPathParams;
  queryParams: DeleteAssetsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteAssets = (variables: DeleteAssetsVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    DeleteAssetsError,
    undefined,
    {},
    DeleteAssetsQueryParams,
    DeleteAssetsPathParams
  >({ url: '/clients/{clientId}/assets', method: 'delete', ...variables, signal });

export const useDeleteAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAssetsError, DeleteAssetsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteAssetsError, DeleteAssetsVariables>({
    mutationFn: (variables: DeleteAssetsVariables) =>
      fetchDeleteAssets(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdateAssetExtendedInfoByAssetIdPathParams {
  assetId: string;
  masterConfigurationId: string;
  clientId: string;
}

export type UpdateAssetExtendedInfoByAssetIdError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetExtendedInfoByAssetIdVariables = {
  body?: Schemas.AssetExtendedInfo;
  pathParams: UpdateAssetExtendedInfoByAssetIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetExtendedInfoByAssetId = (
  variables: UpdateAssetExtendedInfoByAssetIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdateAssetExtendedInfoByAssetIdError,
    Schemas.AssetExtendedInfo,
    {},
    {},
    UpdateAssetExtendedInfoByAssetIdPathParams
  >({
    url: '/clients/{clientId}/assets/{assetId}/assetExtendedInfo/{masterConfigurationId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateAssetExtendedInfoByAssetId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateAssetExtendedInfoByAssetIdError,
      UpdateAssetExtendedInfoByAssetIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateAssetExtendedInfoByAssetIdError,
    UpdateAssetExtendedInfoByAssetIdVariables
  >({
    mutationFn: (variables: UpdateAssetExtendedInfoByAssetIdVariables) =>
      fetchUpdateAssetExtendedInfoByAssetId(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdateFutureAssetsPathParams {
  clientId: string;
}

export type UpdateFutureAssetsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFutureAssetsResponse = Schemas.Asset[];

export type UpdateFutureAssetsVariables = {
  body: Schemas.AssetFileRequest;
  pathParams: UpdateFutureAssetsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateFutureAssets = (
  variables: UpdateFutureAssetsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    UpdateFutureAssetsResponse,
    UpdateFutureAssetsError,
    Schemas.AssetFileRequest,
    {},
    {},
    UpdateFutureAssetsPathParams
  >({ url: '/clients/{clientId}/assets/future-assets', method: 'put', ...variables, signal });

export const useUpdateFutureAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateFutureAssetsResponse,
      UpdateFutureAssetsError,
      UpdateFutureAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    UpdateFutureAssetsResponse,
    UpdateFutureAssetsError,
    UpdateFutureAssetsVariables
  >({
    mutationFn: (variables: UpdateFutureAssetsVariables) =>
      fetchUpdateFutureAssets(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdateAssetGroupPathParams {
  clientId: string;
}

export type UpdateAssetGroupError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetGroupVariables = {
  body?: Schemas.AssetGroupDTO;
  pathParams: UpdateAssetGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetGroup = (variables: UpdateAssetGroupVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.AssetGroupDTO,
    UpdateAssetGroupError,
    Schemas.AssetGroupDTO,
    {},
    {},
    UpdateAssetGroupPathParams
  >({ url: '/clients/{clientId}/asset-group', method: 'put', ...variables, signal });

export const useUpdateAssetGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetGroupDTO,
      UpdateAssetGroupError,
      UpdateAssetGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetGroupDTO,
    UpdateAssetGroupError,
    UpdateAssetGroupVariables
  >({
    mutationFn: (variables: UpdateAssetGroupVariables) =>
      fetchUpdateAssetGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateAssetGroupPathParams {
  clientId: string;
}

export type CreateAssetGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreateAssetGroupVariables = {
  body?: Schemas.AssetGroupDTO;
  pathParams: CreateAssetGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateAssetGroup = (variables: CreateAssetGroupVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.AssetGroupDTO,
    CreateAssetGroupError,
    Schemas.AssetGroupDTO,
    {},
    {},
    CreateAssetGroupPathParams
  >({ url: '/clients/{clientId}/asset-group', method: 'post', ...variables, signal });

export const useCreateAssetGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetGroupDTO,
      CreateAssetGroupError,
      CreateAssetGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetGroupDTO,
    CreateAssetGroupError,
    CreateAssetGroupVariables
  >({
    mutationFn: (variables: CreateAssetGroupVariables) =>
      fetchCreateAssetGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllAssets1PathParams {
  clientId: string;
}

export interface GetAllAssets1QueryParams {
  yearId: string;
  masterConfigId: string;
}

export type GetAllAssets1Error = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssets1Variables = {
  pathParams: GetAllAssets1PathParams;
  queryParams: GetAllAssets1QueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssets1 = (variables: GetAllAssets1Variables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    GetAllAssets1Error,
    undefined,
    {},
    GetAllAssets1QueryParams,
    GetAllAssets1PathParams
  >({ url: '/clients/{clientId}/asset-cc-config', method: 'get', ...variables, signal });

export function getAllAssets1Query(variables: GetAllAssets1Variables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.AssetConfigMappingResponseObject>;
};

export function getAllAssets1Query(variables: GetAllAssets1Variables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.AssetConfigMappingResponseObject>)
    | reactQuery.SkipToken;
};

export function getAllAssets1Query(variables: GetAllAssets1Variables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/asset-cc-config',
      operationId: 'getAllAssets1',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllAssets1(variables, signal),
  };
}

export const useSuspenseGetAllAssets1 = <TData = Schemas.AssetConfigMappingResponseObject>(
  variables: GetAllAssets1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.AssetConfigMappingResponseObject, GetAllAssets1Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.AssetConfigMappingResponseObject,
    GetAllAssets1Error,
    TData
  >({
    ...getAllAssets1Query(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssets1 = <TData = Schemas.AssetConfigMappingResponseObject>(
  variables: GetAllAssets1Variables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.AssetConfigMappingResponseObject, GetAllAssets1Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.AssetConfigMappingResponseObject, GetAllAssets1Error, TData>({
    ...getAllAssets1Query(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface UpdateConfigToAssetPathParams {
  clientId: string;
}

export type UpdateConfigToAssetError = Fetcher.ErrorWrapper<undefined>;

export type UpdateConfigToAssetVariables = {
  body?: Schemas.AssetConfigMappingRequest;
  pathParams: UpdateConfigToAssetPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateConfigToAsset = (
  variables: UpdateConfigToAssetVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    UpdateConfigToAssetError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    UpdateConfigToAssetPathParams
  >({ url: '/clients/{clientId}/asset-cc-config', method: 'put', ...variables, signal });

export const useUpdateConfigToAsset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      UpdateConfigToAssetError,
      UpdateConfigToAssetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    UpdateConfigToAssetError,
    UpdateConfigToAssetVariables
  >({
    mutationFn: (variables: UpdateConfigToAssetVariables) =>
      fetchUpdateConfigToAsset(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface MapConfigToAssetsPathParams {
  clientId: string;
}

export type MapConfigToAssetsError = Fetcher.ErrorWrapper<undefined>;

export type MapConfigToAssetsVariables = {
  body?: Schemas.AssetConfigMappingRequest;
  pathParams: MapConfigToAssetsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchMapConfigToAssets = (
  variables: MapConfigToAssetsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    MapConfigToAssetsError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    MapConfigToAssetsPathParams
  >({ url: '/clients/{clientId}/asset-cc-config', method: 'post', ...variables, signal });

export const useMapConfigToAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      MapConfigToAssetsError,
      MapConfigToAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    MapConfigToAssetsError,
    MapConfigToAssetsVariables
  >({
    mutationFn: (variables: MapConfigToAssetsVariables) =>
      fetchMapConfigToAssets(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CopyAssetCcConfigPathParams {
  destinationYearId: string;
  destinationMasterConfigId: string;
  clientId: string;
}

export type CopyAssetCcConfigError = Fetcher.ErrorWrapper<undefined>;

export type CopyAssetCcConfigVariables = {
  body?: Schemas.AssetConfigMappingRequest;
  pathParams: CopyAssetCcConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCopyAssetCcConfig = (
  variables: CopyAssetCcConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    CopyAssetCcConfigError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    CopyAssetCcConfigPathParams
  >({
    url: '/clients/{clientId}/asset-cc-config/{destinationYearId}/{destinationMasterConfigId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useCopyAssetCcConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      CopyAssetCcConfigError,
      CopyAssetCcConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    CopyAssetCcConfigError,
    CopyAssetCcConfigVariables
  >({
    mutationFn: (variables: CopyAssetCcConfigVariables) =>
      fetchCopyAssetCcConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ResetManuallySetBabCCForAssetsPathParams {
  clientId: string;
}

export type ResetManuallySetBabCCForAssetsError = Fetcher.ErrorWrapper<undefined>;

export type ResetManuallySetBabCCForAssetsVariables = {
  body?: Schemas.AssetConfigMappingRequest;
  pathParams: ResetManuallySetBabCCForAssetsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchResetManuallySetBabCCForAssets = (
  variables: ResetManuallySetBabCCForAssetsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    ResetManuallySetBabCCForAssetsError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    ResetManuallySetBabCCForAssetsPathParams
  >({
    url: '/clients/{clientId}/asset-cc-config/reset-bab-cc',
    method: 'put',
    ...variables,
    signal,
  });

export const useResetManuallySetBabCCForAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      ResetManuallySetBabCCForAssetsError,
      ResetManuallySetBabCCForAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    ResetManuallySetBabCCForAssetsError,
    ResetManuallySetBabCCForAssetsVariables
  >({
    mutationFn: (variables: ResetManuallySetBabCCForAssetsVariables) =>
      fetchResetManuallySetBabCCForAssets(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllAssetCategoryConfigsPathParams {
  clientId: string;
}

export interface GetAllAssetCategoryConfigsQueryParams {
  yearId: string;
  masterConfigId: string;
}

export type GetAllAssetCategoryConfigsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssetCategoryConfigsVariables = {
  pathParams: GetAllAssetCategoryConfigsPathParams;
  queryParams: GetAllAssetCategoryConfigsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssetCategoryConfigs = (
  variables: GetAllAssetCategoryConfigsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetCategoryConfigResponseObject,
    GetAllAssetCategoryConfigsError,
    undefined,
    {},
    GetAllAssetCategoryConfigsQueryParams,
    GetAllAssetCategoryConfigsPathParams
  >({ url: '/clients/{clientId}/asset-category', method: 'get', ...variables, signal });

export function getAllAssetCategoryConfigsQuery(variables: GetAllAssetCategoryConfigsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.AssetCategoryConfigResponseObject>;
};

export function getAllAssetCategoryConfigsQuery(
  variables: GetAllAssetCategoryConfigsVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.AssetCategoryConfigResponseObject>)
    | reactQuery.SkipToken;
};

export function getAllAssetCategoryConfigsQuery(
  variables: GetAllAssetCategoryConfigsVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/asset-category',
      operationId: 'getAllAssetCategoryConfigs',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllAssetCategoryConfigs(variables, signal),
  };
}

export const useSuspenseGetAllAssetCategoryConfigs = <
  TData = Schemas.AssetCategoryConfigResponseObject,
>(
  variables: GetAllAssetCategoryConfigsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AssetCategoryConfigResponseObject,
      GetAllAssetCategoryConfigsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.AssetCategoryConfigResponseObject,
    GetAllAssetCategoryConfigsError,
    TData
  >({
    ...getAllAssetCategoryConfigsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssetCategoryConfigs = <TData = Schemas.AssetCategoryConfigResponseObject>(
  variables: GetAllAssetCategoryConfigsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AssetCategoryConfigResponseObject,
      GetAllAssetCategoryConfigsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.AssetCategoryConfigResponseObject,
    GetAllAssetCategoryConfigsError,
    TData
  >({
    ...getAllAssetCategoryConfigsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface UpdateAssetCategoryConfigPathParams {
  clientId: string;
}

export type UpdateAssetCategoryConfigError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetCategoryConfigVariables = {
  body?: Schemas.AssetCategoryConfigRequest;
  pathParams: UpdateAssetCategoryConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetCategoryConfig = (
  variables: UpdateAssetCategoryConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetCategoryConfigResponseObject,
    UpdateAssetCategoryConfigError,
    Schemas.AssetCategoryConfigRequest,
    {},
    {},
    UpdateAssetCategoryConfigPathParams
  >({ url: '/clients/{clientId}/asset-category', method: 'put', ...variables, signal });

export const useUpdateAssetCategoryConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetCategoryConfigResponseObject,
      UpdateAssetCategoryConfigError,
      UpdateAssetCategoryConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetCategoryConfigResponseObject,
    UpdateAssetCategoryConfigError,
    UpdateAssetCategoryConfigVariables
  >({
    mutationFn: (variables: UpdateAssetCategoryConfigVariables) =>
      fetchUpdateAssetCategoryConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type MigrateExistingUsersError = Fetcher.ErrorWrapper<undefined>;

export type MigrateExistingUsersVariables = DiceContext['fetcherOptions'];

export const fetchMigrateExistingUsers = (
  variables: MigrateExistingUsersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<string, MigrateExistingUsersError, undefined, {}, {}, {}>({
    url: '/migration/user-role-mgmt',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigrateExistingUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, MigrateExistingUsersError, MigrateExistingUsersVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, MigrateExistingUsersError, MigrateExistingUsersVariables>({
    mutationFn: (variables: MigrateExistingUsersVariables) =>
      fetchMigrateExistingUsers(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type MigratePlannedCostRevenueChangeLogConfigError = Fetcher.ErrorWrapper<undefined>;

export type MigratePlannedCostRevenueChangeLogConfigVariables = DiceContext['fetcherOptions'];

export const fetchMigratePlannedCostRevenueChangeLogConfig = (
  variables: MigratePlannedCostRevenueChangeLogConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<string, MigratePlannedCostRevenueChangeLogConfigError, undefined, {}, {}, {}>({
    url: '/migration/planned-cost-revenue-change-log',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigratePlannedCostRevenueChangeLogConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      MigratePlannedCostRevenueChangeLogConfigError,
      MigratePlannedCostRevenueChangeLogConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    string,
    MigratePlannedCostRevenueChangeLogConfigError,
    MigratePlannedCostRevenueChangeLogConfigVariables
  >({
    mutationFn: (variables: MigratePlannedCostRevenueChangeLogConfigVariables) =>
      fetchMigratePlannedCostRevenueChangeLogConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type MigrateImputedWithdrawalCapitalFlagsError = Fetcher.ErrorWrapper<undefined>;

export type MigrateImputedWithdrawalCapitalFlagsVariables = DiceContext['fetcherOptions'];

export const fetchMigrateImputedWithdrawalCapitalFlags = (
  variables: MigrateImputedWithdrawalCapitalFlagsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MigrationResult,
    MigrateImputedWithdrawalCapitalFlagsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/imputed-withdrawal-capital-flags', method: 'post', ...variables, signal });

export const useMigrateImputedWithdrawalCapitalFlags = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      MigrateImputedWithdrawalCapitalFlagsError,
      MigrateImputedWithdrawalCapitalFlagsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    MigrateImputedWithdrawalCapitalFlagsError,
    MigrateImputedWithdrawalCapitalFlagsVariables
  >({
    mutationFn: (variables: MigrateImputedWithdrawalCapitalFlagsVariables) =>
      fetchMigrateImputedWithdrawalCapitalFlags(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type MigrateFutureAssetRanksError = Fetcher.ErrorWrapper<undefined>;

export type MigrateFutureAssetRanksVariables = DiceContext['fetcherOptions'];

export const fetchMigrateFutureAssetRanks = (
  variables: MigrateFutureAssetRanksVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.MigrationResult, MigrateFutureAssetRanksError, undefined, {}, {}, {}>({
    url: '/migration/future-asset-rank',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigrateFutureAssetRanks = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      MigrateFutureAssetRanksError,
      MigrateFutureAssetRanksVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    MigrateFutureAssetRanksError,
    MigrateFutureAssetRanksVariables
  >({
    mutationFn: (variables: MigrateFutureAssetRanksVariables) =>
      fetchMigrateFutureAssetRanks(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type MigrateDeductibleCapitalConfigError = Fetcher.ErrorWrapper<undefined>;

export type MigrateDeductibleCapitalConfigVariables = DiceContext['fetcherOptions'];

export const fetchMigrateDeductibleCapitalConfig = (
  variables: MigrateDeductibleCapitalConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.MigrationResult, MigrateDeductibleCapitalConfigError, undefined, {}, {}, {}>({
    url: '/migration/deductible-capital-config',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigrateDeductibleCapitalConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      MigrateDeductibleCapitalConfigError,
      MigrateDeductibleCapitalConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    MigrateDeductibleCapitalConfigError,
    MigrateDeductibleCapitalConfigVariables
  >({
    mutationFn: (variables: MigrateDeductibleCapitalConfigVariables) =>
      fetchMigrateDeductibleCapitalConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type MigrateCostUnitRevenueConfigError = Fetcher.ErrorWrapper<undefined>;

export type MigrateCostUnitRevenueConfigVariables = DiceContext['fetcherOptions'];

export const fetchMigrateCostUnitRevenueConfig = (
  variables: MigrateCostUnitRevenueConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.MigrationResult, MigrateCostUnitRevenueConfigError, undefined, {}, {}, {}>({
    url: '/migration/cost-unit-revenues',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigrateCostUnitRevenueConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      MigrateCostUnitRevenueConfigError,
      MigrateCostUnitRevenueConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    MigrateCostUnitRevenueConfigError,
    MigrateCostUnitRevenueConfigVariables
  >({
    mutationFn: (variables: MigrateCostUnitRevenueConfigVariables) =>
      fetchMigrateCostUnitRevenueConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type MigrateAllocationKeyConfigStatusError = Fetcher.ErrorWrapper<undefined>;

export type MigrateAllocationKeyConfigStatusVariables = DiceContext['fetcherOptions'];

export const fetchMigrateAllocationKeyConfigStatus = (
  variables: MigrateAllocationKeyConfigStatusVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MigrationResultWithErrorCount,
    MigrateAllocationKeyConfigStatusError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/allocation-key-config-status', method: 'post', ...variables, signal });

export const useMigrateAllocationKeyConfigStatus = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResultWithErrorCount,
      MigrateAllocationKeyConfigStatusError,
      MigrateAllocationKeyConfigStatusVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MigrationResultWithErrorCount,
    MigrateAllocationKeyConfigStatusError,
    MigrateAllocationKeyConfigStatusVariables
  >({
    mutationFn: (variables: MigrateAllocationKeyConfigStatusVariables) =>
      fetchMigrateAllocationKeyConfigStatus(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetClientsQueryParams {
  onlyArchived?: boolean;
}

export interface GetClientsHeaders {
  authorization?: string;
}

export type GetClientsError = Fetcher.ErrorWrapper<undefined>;

export type GetClientsResponse = Schemas.ClientDTO[];

export type GetClientsVariables = {
  headers?: GetClientsHeaders;
  queryParams?: GetClientsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetClients = (variables: GetClientsVariables, signal?: AbortSignal) =>
  diceFetch<
    GetClientsResponse,
    GetClientsError,
    undefined,
    GetClientsHeaders,
    GetClientsQueryParams,
    {}
  >({ url: '/clients', method: 'get', ...variables, signal });

export function getClientsQuery(variables: GetClientsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetClientsResponse>;
};

export function getClientsQuery(variables: GetClientsVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetClientsResponse>) | reactQuery.SkipToken;
};

export function getClientsQuery(variables: GetClientsVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients',
      operationId: 'getClients',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetClients(variables, signal),
  };
}

export const useSuspenseGetClients = <TData = GetClientsResponse>(
  variables: GetClientsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientsResponse, GetClientsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetClientsResponse, GetClientsError, TData>({
    ...getClientsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetClients = <TData = GetClientsResponse>(
  variables: GetClientsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientsResponse, GetClientsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetClientsResponse, GetClientsError, TData>({
    ...getClientsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface SaveClientsHeaders {
  authorization?: string;
}

export type SaveClientsError = Fetcher.ErrorWrapper<undefined>;

export type SaveClientsResponse = Schemas.ClientDTO[];

export type SaveClientsRequestBody = Schemas.ClientDTO[];

export type SaveClientsVariables = {
  body?: SaveClientsRequestBody;
  headers?: SaveClientsHeaders;
} & DiceContext['fetcherOptions'];

export const fetchSaveClients = (variables: SaveClientsVariables, signal?: AbortSignal) =>
  diceFetch<
    SaveClientsResponse,
    SaveClientsError,
    SaveClientsRequestBody,
    SaveClientsHeaders,
    {},
    {}
  >({ url: '/clients', method: 'post', ...variables, signal });

export const useSaveClients = (
  options?: Omit<
    reactQuery.UseMutationOptions<SaveClientsResponse, SaveClientsError, SaveClientsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<SaveClientsResponse, SaveClientsError, SaveClientsVariables>({
    mutationFn: (variables: SaveClientsVariables) =>
      fetchSaveClients(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface PostMasterConfigurationPathParams {
  yearCalculationId: string;
  clientId: string;
}

export type PostMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type PostMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
  pathParams: PostMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostMasterConfiguration = (
  variables: PostMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    PostMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    PostMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/year-calculations/{yearCalculationId}/master-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfiguration,
      PostMasterConfigurationError,
      PostMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfiguration,
    PostMasterConfigurationError,
    PostMasterConfigurationVariables
  >({
    mutationFn: (variables: PostMasterConfigurationVariables) =>
      fetchPostMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateWithdrawCapitalConfigPathParams {
  clientId: string;
}

export interface CreateWithdrawCapitalConfigQueryParams {
  yearId: string;
  masterConfigId?: string;
}

export type CreateWithdrawCapitalConfigError = Fetcher.ErrorWrapper<undefined>;

export type CreateWithdrawCapitalConfigVariables = {
  pathParams: CreateWithdrawCapitalConfigPathParams;
  queryParams: CreateWithdrawCapitalConfigQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateWithdrawCapitalConfig = (
  variables: CreateWithdrawCapitalConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    CreateWithdrawCapitalConfigError,
    undefined,
    {},
    CreateWithdrawCapitalConfigQueryParams,
    CreateWithdrawCapitalConfigPathParams
  >({ url: '/clients/{clientId}/withdraw-capital-config', method: 'post', ...variables, signal });

export const useCreateWithdrawCapitalConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CreateWithdrawCapitalConfigError,
      CreateWithdrawCapitalConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    CreateWithdrawCapitalConfigError,
    CreateWithdrawCapitalConfigVariables
  >({
    mutationFn: (variables: CreateWithdrawCapitalConfigVariables) =>
      fetchCreateWithdrawCapitalConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UploadFilePathParams {
  clientId: string;
}

export interface UploadFileQueryParams {
  type: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type UploadFileError = Fetcher.ErrorWrapper<undefined>;

export interface UploadFileRequestBody {
  /**
   * @format binary
   */
  uploadFile: Blob;
}

export type UploadFileVariables = {
  body: UploadFileRequestBody;
  pathParams: UploadFilePathParams;
  queryParams: UploadFileQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchUploadFile = (variables: UploadFileVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.UploadResponse,
    UploadFileError,
    UploadFileRequestBody,
    {},
    UploadFileQueryParams,
    UploadFilePathParams
  >({ url: '/clients/{clientId}/upload', method: 'post', ...variables, signal });

export const useUploadFile = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.UploadResponse, UploadFileError, UploadFileVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.UploadResponse, UploadFileError, UploadFileVariables>({
    mutationFn: (variables: UploadFileVariables) =>
      fetchUploadFile(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetYearsPathParams {
  publicFacilityId: string;
  clientId: string;
}

export type GetYearsError = Fetcher.ErrorWrapper<undefined>;

export type GetYearsResponse = Schemas.YearDTOV2[];

export type GetYearsVariables = {
  pathParams: GetYearsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetYears = (variables: GetYearsVariables, signal?: AbortSignal) =>
  diceFetch<GetYearsResponse, GetYearsError, undefined, {}, {}, GetYearsPathParams>({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/years',
    method: 'get',
    ...variables,
    signal,
  });

export function getYearsQuery(variables: GetYearsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetYearsResponse>;
};

export function getYearsQuery(variables: GetYearsVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetYearsResponse>) | reactQuery.SkipToken;
};

export function getYearsQuery(variables: GetYearsVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}/years',
      operationId: 'getYears',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetYears(variables, signal),
  };
}

export const useSuspenseGetYears = <TData = GetYearsResponse>(
  variables: GetYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetYearsResponse, GetYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetYearsResponse, GetYearsError, TData>({
    ...getYearsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetYears = <TData = GetYearsResponse>(
  variables: GetYearsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetYearsResponse, GetYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetYearsResponse, GetYearsError, TData>({
    ...getYearsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface PostYearPathParams {
  publicFacilityId: string;
  clientId: string;
}

export type PostYearError = Fetcher.ErrorWrapper<undefined>;

export type PostYearVariables = {
  body?: Schemas.YearDTOV2;
  pathParams: PostYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostYear = (variables: PostYearVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.YearDTOV2, PostYearError, Schemas.YearDTOV2, {}, {}, PostYearPathParams>({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/years',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.YearDTOV2, PostYearError, PostYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.YearDTOV2, PostYearError, PostYearVariables>({
    mutationFn: (variables: PostYearVariables) =>
      fetchPostYear(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface AddPublicFacilityFavoritePathParams {
  publicFacilityId: string;
  yearId: string;
  clientId: string;
}

export interface AddPublicFacilityFavoriteHeaders {
  authorization?: string;
}

export type AddPublicFacilityFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type AddPublicFacilityFavoriteVariables = {
  headers?: AddPublicFacilityFavoriteHeaders;
  pathParams: AddPublicFacilityFavoritePathParams;
} & DiceContext['fetcherOptions'];

export const fetchAddPublicFacilityFavorite = (
  variables: AddPublicFacilityFavoriteVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    AddPublicFacilityFavoriteError,
    undefined,
    AddPublicFacilityFavoriteHeaders,
    {},
    AddPublicFacilityFavoritePathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/{yearId}/favorites',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddPublicFacilityFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddPublicFacilityFavoriteError,
      AddPublicFacilityFavoriteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    AddPublicFacilityFavoriteError,
    AddPublicFacilityFavoriteVariables
  >({
    mutationFn: (variables: AddPublicFacilityFavoriteVariables) =>
      fetchAddPublicFacilityFavorite(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface RemovePublicFacilityFavoritePathParams {
  publicFacilityId: string;
  yearId: string;
  clientId: string;
}

export interface RemovePublicFacilityFavoriteHeaders {
  authorization?: string;
}

export type RemovePublicFacilityFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type RemovePublicFacilityFavoriteVariables = {
  headers?: RemovePublicFacilityFavoriteHeaders;
  pathParams: RemovePublicFacilityFavoritePathParams;
} & DiceContext['fetcherOptions'];

export const fetchRemovePublicFacilityFavorite = (
  variables: RemovePublicFacilityFavoriteVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    RemovePublicFacilityFavoriteError,
    undefined,
    RemovePublicFacilityFavoriteHeaders,
    {},
    RemovePublicFacilityFavoritePathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/{yearId}/favorites',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemovePublicFacilityFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemovePublicFacilityFavoriteError,
      RemovePublicFacilityFavoriteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    RemovePublicFacilityFavoriteError,
    RemovePublicFacilityFavoriteVariables
  >({
    mutationFn: (variables: RemovePublicFacilityFavoriteVariables) =>
      fetchRemovePublicFacilityFavorite(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface AddPublicFacilityFavorite1PathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface AddPublicFacilityFavorite1Headers {
  authorization?: string;
}

export type AddPublicFacilityFavorite1Error = Fetcher.ErrorWrapper<undefined>;

export type AddPublicFacilityFavorite1Variables = {
  headers?: AddPublicFacilityFavorite1Headers;
  pathParams: AddPublicFacilityFavorite1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchAddPublicFacilityFavorite1 = (
  variables: AddPublicFacilityFavorite1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    AddPublicFacilityFavorite1Error,
    undefined,
    AddPublicFacilityFavorite1Headers,
    {},
    AddPublicFacilityFavorite1PathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/favorites',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddPublicFacilityFavorite1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddPublicFacilityFavorite1Error,
      AddPublicFacilityFavorite1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    AddPublicFacilityFavorite1Error,
    AddPublicFacilityFavorite1Variables
  >({
    mutationFn: (variables: AddPublicFacilityFavorite1Variables) =>
      fetchAddPublicFacilityFavorite1(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface RemovePublicFacilityFavorite1PathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface RemovePublicFacilityFavorite1Headers {
  authorization?: string;
}

export type RemovePublicFacilityFavorite1Error = Fetcher.ErrorWrapper<undefined>;

export type RemovePublicFacilityFavorite1Variables = {
  headers?: RemovePublicFacilityFavorite1Headers;
  pathParams: RemovePublicFacilityFavorite1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchRemovePublicFacilityFavorite1 = (
  variables: RemovePublicFacilityFavorite1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    RemovePublicFacilityFavorite1Error,
    undefined,
    RemovePublicFacilityFavorite1Headers,
    {},
    RemovePublicFacilityFavorite1PathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/favorites',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemovePublicFacilityFavorite1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemovePublicFacilityFavorite1Error,
      RemovePublicFacilityFavorite1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    RemovePublicFacilityFavorite1Error,
    RemovePublicFacilityFavorite1Variables
  >({
    mutationFn: (variables: RemovePublicFacilityFavorite1Variables) =>
      fetchRemovePublicFacilityFavorite1(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreatePriceGroupPathParams {
  clientId: string;
}

export type CreatePriceGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreatePriceGroupVariables = {
  body?: Schemas.PostPriceSheetGroupDTO;
  pathParams: CreatePriceGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePriceGroup = (variables: CreatePriceGroupVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.PostPriceSheetGroupDTO,
    CreatePriceGroupError,
    Schemas.PostPriceSheetGroupDTO,
    {},
    {},
    CreatePriceGroupPathParams
  >({ url: '/clients/{clientId}/price-groups', method: 'post', ...variables, signal });

export const useCreatePriceGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PostPriceSheetGroupDTO,
      CreatePriceGroupError,
      CreatePriceGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PostPriceSheetGroupDTO,
    CreatePriceGroupError,
    CreatePriceGroupVariables
  >({
    mutationFn: (variables: CreatePriceGroupVariables) =>
      fetchCreatePriceGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdatePriceGroupPathParams {
  clientId: string;
}

export type UpdatePriceGroupError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePriceGroupRequestBody = Schemas.PatchPriceSheetGroupDTO[];

export type UpdatePriceGroupVariables = {
  body?: UpdatePriceGroupRequestBody;
  pathParams: UpdatePriceGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdatePriceGroup = (variables: UpdatePriceGroupVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    UpdatePriceGroupError,
    UpdatePriceGroupRequestBody,
    {},
    {},
    UpdatePriceGroupPathParams
  >({ url: '/clients/{clientId}/price-groups', method: 'patch', ...variables, signal });

export const useUpdatePriceGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdatePriceGroupError, UpdatePriceGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, UpdatePriceGroupError, UpdatePriceGroupVariables>({
    mutationFn: (variables: UpdatePriceGroupVariables) =>
      fetchUpdatePriceGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreatePriceSubGroupPathParams {
  priceGroupId: string;
  clientId: string;
}

export type CreatePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreatePriceSubGroupVariables = {
  body?: Schemas.PostPriceSheetSubGroupDTO;
  pathParams: CreatePriceSubGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePriceSubGroup = (
  variables: CreatePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PostPriceSheetSubGroupDTO,
    CreatePriceSubGroupError,
    Schemas.PostPriceSheetSubGroupDTO,
    {},
    {},
    CreatePriceSubGroupPathParams
  >({
    url: '/clients/{clientId}/price-groups/{priceGroupId}/sub-groups',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PostPriceSheetSubGroupDTO,
      CreatePriceSubGroupError,
      CreatePriceSubGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PostPriceSheetSubGroupDTO,
    CreatePriceSubGroupError,
    CreatePriceSubGroupVariables
  >({
    mutationFn: (variables: CreatePriceSubGroupVariables) =>
      fetchCreatePriceSubGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreatePriceSubGroupEntryPathParams {
  priceSubGroupId: string;
  clientId: string;
}

export type CreatePriceSubGroupEntryError = Fetcher.ErrorWrapper<undefined>;

export type CreatePriceSubGroupEntryVariables = {
  body?: Schemas.PriceSheetSubGroupEntryDTO;
  pathParams: CreatePriceSubGroupEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePriceSubGroupEntry = (
  variables: CreatePriceSubGroupEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PriceSheetSubGroupEntryDTO,
    CreatePriceSubGroupEntryError,
    Schemas.PriceSheetSubGroupEntryDTO,
    {},
    {},
    CreatePriceSubGroupEntryPathParams
  >({
    url: '/clients/{clientId}/price-groups/sub-groups/{priceSubGroupId}/entries',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePriceSubGroupEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PriceSheetSubGroupEntryDTO,
      CreatePriceSubGroupEntryError,
      CreatePriceSubGroupEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PriceSheetSubGroupEntryDTO,
    CreatePriceSubGroupEntryError,
    CreatePriceSubGroupEntryVariables
  >({
    mutationFn: (variables: CreatePriceSubGroupEntryVariables) =>
      fetchCreatePriceSubGroupEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SyncMasterConfigurationCostUnitBasicFeePathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type SyncMasterConfigurationCostUnitBasicFeeError = Fetcher.ErrorWrapper<undefined>;

export type SyncMasterConfigurationCostUnitBasicFeeVariables = {
  body?: Schemas.UpdateCostUnitBasicFee;
  pathParams: SyncMasterConfigurationCostUnitBasicFeePathParams;
} & DiceContext['fetcherOptions'];

export const fetchSyncMasterConfigurationCostUnitBasicFee = (
  variables: SyncMasterConfigurationCostUnitBasicFeeVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    SyncMasterConfigurationCostUnitBasicFeeError,
    Schemas.UpdateCostUnitBasicFee,
    {},
    {},
    SyncMasterConfigurationCostUnitBasicFeePathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/sync-cost-unit',
    method: 'post',
    ...variables,
    signal,
  });

export const useSyncMasterConfigurationCostUnitBasicFee = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SyncMasterConfigurationCostUnitBasicFeeError,
      SyncMasterConfigurationCostUnitBasicFeeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    SyncMasterConfigurationCostUnitBasicFeeError,
    SyncMasterConfigurationCostUnitBasicFeeVariables
  >({
    mutationFn: (variables: SyncMasterConfigurationCostUnitBasicFeeVariables) =>
      fetchSyncMasterConfigurationCostUnitBasicFee(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreatePlannedCostRevenueDirectEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
  plannedAccountEntryId: string;
  directEntryName: string;
  clientId: string;
}

export type CreatePlannedCostRevenueDirectEntryError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlannedCostRevenueDirectEntryVariables = {
  pathParams: CreatePlannedCostRevenueDirectEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePlannedCostRevenueDirectEntry = (
  variables: CreatePlannedCostRevenueDirectEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedDirectEntryDTO,
    CreatePlannedCostRevenueDirectEntryError,
    undefined,
    {},
    {},
    CreatePlannedCostRevenueDirectEntryPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}/account/{plannedAccountEntryId}/direct-entry/{directEntryName}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePlannedCostRevenueDirectEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedDirectEntryDTO,
      CreatePlannedCostRevenueDirectEntryError,
      CreatePlannedCostRevenueDirectEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedDirectEntryDTO,
    CreatePlannedCostRevenueDirectEntryError,
    CreatePlannedCostRevenueDirectEntryVariables
  >({
    mutationFn: (variables: CreatePlannedCostRevenueDirectEntryVariables) =>
      fetchCreatePlannedCostRevenueDirectEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateVariantConfigurationsPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type CreateVariantConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type CreateVariantConfigurationsVariables = {
  pathParams: CreateVariantConfigurationsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateVariantConfigurations = (
  variables: CreateVariantConfigurationsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    CreateVariantConfigurationsError,
    undefined,
    {},
    {},
    CreateVariantConfigurationsPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/create-variant',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateVariantConfigurations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CreateVariantConfigurationsError,
      CreateVariantConfigurationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CreateVariantConfigurationsError,
    CreateVariantConfigurationsVariables
  >({
    mutationFn: (variables: CreateVariantConfigurationsVariables) =>
      fetchCreateVariantConfigurations(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateBackupOfMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type CreateBackupOfMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type CreateBackupOfMasterConfigurationVariables = {
  pathParams: CreateBackupOfMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateBackupOfMasterConfiguration = (
  variables: CreateBackupOfMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    CreateBackupOfMasterConfigurationError,
    undefined,
    {},
    {},
    CreateBackupOfMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/create-backup',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBackupOfMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CreateBackupOfMasterConfigurationError,
      CreateBackupOfMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CreateBackupOfMasterConfigurationError,
    CreateBackupOfMasterConfigurationVariables
  >({
    mutationFn: (variables: CreateBackupOfMasterConfigurationVariables) =>
      fetchCreateBackupOfMasterConfiguration(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateCostUnitAllocationBasisConfigPathParams {
  masterConfigurationId: string;
  costUnitAllocationConfigId: string;
  costUnitAllocationBasisConfigName: string;
  clientId: string;
}

export type CreateCostUnitAllocationBasisConfigError = Fetcher.ErrorWrapper<undefined>;

export type CreateCostUnitAllocationBasisConfigVariables = {
  pathParams: CreateCostUnitAllocationBasisConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateCostUnitAllocationBasisConfig = (
  variables: CreateCostUnitAllocationBasisConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitAllocationBasisConfigEntryDTO,
    CreateCostUnitAllocationBasisConfigError,
    undefined,
    {},
    {},
    CreateCostUnitAllocationBasisConfigPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations/{costUnitAllocationConfigId}/cost-unit-allocation-basis-configs/{costUnitAllocationBasisConfigName}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateCostUnitAllocationBasisConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitAllocationBasisConfigEntryDTO,
      CreateCostUnitAllocationBasisConfigError,
      CreateCostUnitAllocationBasisConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitAllocationBasisConfigEntryDTO,
    CreateCostUnitAllocationBasisConfigError,
    CreateCostUnitAllocationBasisConfigVariables
  >({
    mutationFn: (variables: CreateCostUnitAllocationBasisConfigVariables) =>
      fetchCreateCostUnitAllocationBasisConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CopyConfigPathParams {
  masterConfigurationId: string;
  targetYearCalculationId: string;
  clientId: string;
}

export type CopyConfigError = Fetcher.ErrorWrapper<undefined>;

export type CopyConfigVariables = {
  pathParams: CopyConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCopyConfig = (variables: CopyConfigVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    CopyConfigError,
    undefined,
    {},
    {},
    CopyConfigPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/copy/year-calculations/{targetYearCalculationId}/master-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CopyConfigError,
      CopyConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CopyConfigError,
    CopyConfigVariables
  >({
    mutationFn: (variables: CopyConfigVariables) =>
      fetchCopyConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetRangeConfigForMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetRangeConfigForMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetRangeConfigForMasterConfigurationVariables = {
  pathParams: GetRangeConfigForMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetRangeConfigForMasterConfiguration = (
  variables: GetRangeConfigForMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    undefined,
    {},
    {},
    GetRangeConfigForMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges',
    method: 'get',
    ...variables,
    signal,
  });

export function getRangeConfigForMasterConfigurationQuery(
  variables: GetRangeConfigForMasterConfigurationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.RangeConfigDTO>;
};

export function getRangeConfigForMasterConfigurationQuery(
  variables: GetRangeConfigForMasterConfigurationVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.RangeConfigDTO>) | reactQuery.SkipToken;
};

export function getRangeConfigForMasterConfigurationQuery(
  variables: GetRangeConfigForMasterConfigurationVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges',
      operationId: 'getRangeConfigForMasterConfiguration',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) =>
            fetchGetRangeConfigForMasterConfiguration(variables, signal),
  };
}

export const useSuspenseGetRangeConfigForMasterConfiguration = <TData = Schemas.RangeConfigDTO>(
  variables: GetRangeConfigForMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RangeConfigDTO,
      GetRangeConfigForMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    TData
  >({
    ...getRangeConfigForMasterConfigurationQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetRangeConfigForMasterConfiguration = <TData = Schemas.RangeConfigDTO>(
  variables: GetRangeConfigForMasterConfigurationVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RangeConfigDTO,
      GetRangeConfigForMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    TData
  >({
    ...getRangeConfigForMasterConfigurationQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface PostRangePathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type PostRangeError = Fetcher.ErrorWrapper<undefined>;

export type PostRangeVariables = {
  body?: Schemas.RangeDTO;
  pathParams: PostRangePathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostRange = (variables: PostRangeVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.RangeConfigDTO, PostRangeError, Schemas.RangeDTO, {}, {}, PostRangePathParams>({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, PostRangeError, PostRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, PostRangeError, PostRangeVariables>({
    mutationFn: (variables: PostRangeVariables) =>
      fetchPostRange(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SyncMasterConfigurationCostUnitBasicFee1PathParams {
  clientId: string;
}

export type SyncMasterConfigurationCostUnitBasicFee1Error = Fetcher.ErrorWrapper<undefined>;

export type SyncMasterConfigurationCostUnitBasicFee1Variables = {
  body?: Schemas.UpdateCostUnitBasicFee;
  pathParams: SyncMasterConfigurationCostUnitBasicFee1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchSyncMasterConfigurationCostUnitBasicFee1 = (
  variables: SyncMasterConfigurationCostUnitBasicFee1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    SyncMasterConfigurationCostUnitBasicFee1Error,
    Schemas.UpdateCostUnitBasicFee,
    {},
    {},
    SyncMasterConfigurationCostUnitBasicFee1PathParams
  >({
    url: '/clients/{clientId}/master-configuration/cost-unit',
    method: 'post',
    ...variables,
    signal,
  });

export const useSyncMasterConfigurationCostUnitBasicFee1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SyncMasterConfigurationCostUnitBasicFee1Error,
      SyncMasterConfigurationCostUnitBasicFee1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    SyncMasterConfigurationCostUnitBasicFee1Error,
    SyncMasterConfigurationCostUnitBasicFee1Variables
  >({
    mutationFn: (variables: SyncMasterConfigurationCostUnitBasicFee1Variables) =>
      fetchSyncMasterConfigurationCostUnitBasicFee1(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CopyConfig1PathParams {
  masterConfigId: string;
  targetMasterConfigId: string;
  clientId: string;
}

export type CopyConfig1Error = Fetcher.ErrorWrapper<undefined>;

export type CopyConfig1Variables = {
  pathParams: CopyConfig1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchCopyConfig1 = (variables: CopyConfig1Variables, signal?: AbortSignal) =>
  diceFetch<undefined, CopyConfig1Error, undefined, {}, {}, CopyConfig1PathParams>({
    url: '/clients/{clientId}/master-configs/{masterConfigId}/copy/master-config/{targetMasterConfigId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfig1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, CopyConfig1Error, CopyConfig1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, CopyConfig1Error, CopyConfig1Variables>({
    mutationFn: (variables: CopyConfig1Variables) =>
      fetchCopyConfig1(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateAllocationBasisConfigPathParams {
  allocationKeyConfigId: string;
  allocationBasisConfigName: string;
  clientId: string;
}

export type CreateAllocationBasisConfigError = Fetcher.ErrorWrapper<undefined>;

export type CreateAllocationBasisConfigVariables = {
  pathParams: CreateAllocationBasisConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateAllocationBasisConfig = (
  variables: CreateAllocationBasisConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AllocationBasisConfigEntryDTO,
    CreateAllocationBasisConfigError,
    undefined,
    {},
    {},
    CreateAllocationBasisConfigPathParams
  >({
    url: '/clients/{clientId}/master-configs/allocation-key-configs/{allocationKeyConfigId}/allocation-basis-configs/{allocationBasisConfigName}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateAllocationBasisConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AllocationBasisConfigEntryDTO,
      CreateAllocationBasisConfigError,
      CreateAllocationBasisConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AllocationBasisConfigEntryDTO,
    CreateAllocationBasisConfigError,
    CreateAllocationBasisConfigVariables
  >({
    mutationFn: (variables: CreateAllocationBasisConfigVariables) =>
      fetchCreateAllocationBasisConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetCostCenterChildrenLedgerPathParams {
  clientId: string;
}

export type GetCostCenterChildrenLedgerError = Fetcher.ErrorWrapper<undefined>;

export type GetCostCenterChildrenLedgerResponse = Schemas.CostCenterNode[];

export type GetCostCenterChildrenLedgerVariables = {
  body?: Schemas.CostCenterTreeRequest;
  pathParams: GetCostCenterChildrenLedgerPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetCostCenterChildrenLedger = (
  variables: GetCostCenterChildrenLedgerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostCenterChildrenLedgerResponse,
    GetCostCenterChildrenLedgerError,
    Schemas.CostCenterTreeRequest,
    {},
    {},
    GetCostCenterChildrenLedgerPathParams
  >({ url: '/clients/{clientId}/ledger-costCenter', method: 'post', ...variables, signal });

export const useGetCostCenterChildrenLedger = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetCostCenterChildrenLedgerResponse,
      GetCostCenterChildrenLedgerError,
      GetCostCenterChildrenLedgerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    GetCostCenterChildrenLedgerResponse,
    GetCostCenterChildrenLedgerError,
    GetCostCenterChildrenLedgerVariables
  >({
    mutationFn: (variables: GetCostCenterChildrenLedgerVariables) =>
      fetchGetCostCenterChildrenLedger(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetCostCenterChildrenJournalPathParams {
  clientId: string;
}

export type GetCostCenterChildrenJournalError = Fetcher.ErrorWrapper<undefined>;

export type GetCostCenterChildrenJournalResponse = Schemas.CostCenterNode[];

export type GetCostCenterChildrenJournalVariables = {
  body?: Schemas.CostCenterTreeRequest;
  pathParams: GetCostCenterChildrenJournalPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetCostCenterChildrenJournal = (
  variables: GetCostCenterChildrenJournalVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostCenterChildrenJournalResponse,
    GetCostCenterChildrenJournalError,
    Schemas.CostCenterTreeRequest,
    {},
    {},
    GetCostCenterChildrenJournalPathParams
  >({ url: '/clients/{clientId}/journal-costCenter', method: 'post', ...variables, signal });

export const useGetCostCenterChildrenJournal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetCostCenterChildrenJournalResponse,
      GetCostCenterChildrenJournalError,
      GetCostCenterChildrenJournalVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    GetCostCenterChildrenJournalResponse,
    GetCostCenterChildrenJournalError,
    GetCostCenterChildrenJournalVariables
  >({
    mutationFn: (variables: GetCostCenterChildrenJournalVariables) =>
      fetchGetCostCenterChildrenJournal(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllIndexFactorsPathParams {
  clientId: string;
}

export type GetAllIndexFactorsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllIndexFactorsResponse = Schemas.IndexFactorConfig[];

export type GetAllIndexFactorsVariables = {
  pathParams: GetAllIndexFactorsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllIndexFactors = (
  variables: GetAllIndexFactorsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllIndexFactorsResponse,
    GetAllIndexFactorsError,
    undefined,
    {},
    {},
    GetAllIndexFactorsPathParams
  >({ url: '/clients/{clientId}/index-factors', method: 'get', ...variables, signal });

export function getAllIndexFactorsQuery(variables: GetAllIndexFactorsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllIndexFactorsResponse>;
};

export function getAllIndexFactorsQuery(
  variables: GetAllIndexFactorsVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAllIndexFactorsResponse>)
    | reactQuery.SkipToken;
};

export function getAllIndexFactorsQuery(
  variables: GetAllIndexFactorsVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/index-factors',
      operationId: 'getAllIndexFactors',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllIndexFactors(variables, signal),
  };
}

export const useSuspenseGetAllIndexFactors = <TData = GetAllIndexFactorsResponse>(
  variables: GetAllIndexFactorsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>({
    ...getAllIndexFactorsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllIndexFactors = <TData = GetAllIndexFactorsResponse>(
  variables: GetAllIndexFactorsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>({
    ...getAllIndexFactorsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface PostIndexFactorsPathParams {
  clientId: string;
}

export type PostIndexFactorsError = Fetcher.ErrorWrapper<undefined>;

export type PostIndexFactorsVariables = {
  body?: Schemas.IndexFactorConfig;
  pathParams: PostIndexFactorsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostIndexFactors = (variables: PostIndexFactorsVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.IndexFactorConfig,
    PostIndexFactorsError,
    Schemas.IndexFactorConfig,
    {},
    {},
    PostIndexFactorsPathParams
  >({ url: '/clients/{clientId}/index-factors', method: 'post', ...variables, signal });

export const usePostIndexFactors = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IndexFactorConfig,
      PostIndexFactorsError,
      PostIndexFactorsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.IndexFactorConfig,
    PostIndexFactorsError,
    PostIndexFactorsVariables
  >({
    mutationFn: (variables: PostIndexFactorsVariables) =>
      fetchPostIndexFactors(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteIndexFactorsPathParams {
  clientId: string;
}

export type DeleteIndexFactorsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteIndexFactorsVariables = {
  pathParams: DeleteIndexFactorsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteIndexFactors = (
  variables: DeleteIndexFactorsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteIndexFactorsError, undefined, {}, {}, DeleteIndexFactorsPathParams>({
    url: '/clients/{clientId}/index-factors',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteIndexFactors = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteIndexFactorsError, DeleteIndexFactorsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteIndexFactorsError, DeleteIndexFactorsVariables>({
    mutationFn: (variables: DeleteIndexFactorsVariables) =>
      fetchDeleteIndexFactors(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SaveToggleFilterDataPathParams {
  clientId: string;
}

export interface SaveToggleFilterDataQueryParams {
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  yearId: string;
  isPostCalculation: boolean;
  erpType: 'SAP_ECC6' | 'SAP_S4' | 'NAVISION_365_BC' | 'KVASY';
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  toggleName: string;
  toggleValue: boolean;
}

export type SaveToggleFilterDataError = Fetcher.ErrorWrapper<undefined>;

export type SaveToggleFilterDataVariables = {
  pathParams: SaveToggleFilterDataPathParams;
  queryParams: SaveToggleFilterDataQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveToggleFilterData = (
  variables: SaveToggleFilterDataVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportFileResponse,
    SaveToggleFilterDataError,
    undefined,
    {},
    SaveToggleFilterDataQueryParams,
    SaveToggleFilterDataPathParams
  >({ url: '/clients/{clientId}/imports/toggleFilterData', method: 'post', ...variables, signal });

export const useSaveToggleFilterData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      SaveToggleFilterDataError,
      SaveToggleFilterDataVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    SaveToggleFilterDataError,
    SaveToggleFilterDataVariables
  >({
    mutationFn: (variables: SaveToggleFilterDataVariables) =>
      fetchSaveToggleFilterData(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ImportLedgerAccountPathParams {
  clientId: string;
}

export type ImportLedgerAccountError = Fetcher.ErrorWrapper<undefined>;

export type ImportLedgerAccountVariables = {
  body: Schemas.ImportLedgerAccountRequest;
  pathParams: ImportLedgerAccountPathParams;
} & DiceContext['fetcherOptions'];

export const fetchImportLedgerAccount = (
  variables: ImportLedgerAccountVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportFileResponse,
    ImportLedgerAccountError,
    Schemas.ImportLedgerAccountRequest,
    {},
    {},
    ImportLedgerAccountPathParams
  >({ url: '/clients/{clientId}/imports/ledger-account', method: 'post', ...variables, signal });

export const useImportLedgerAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportLedgerAccountError,
      ImportLedgerAccountVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportLedgerAccountError,
    ImportLedgerAccountVariables
  >({
    mutationFn: (variables: ImportLedgerAccountVariables) =>
      fetchImportLedgerAccount(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ImportLabelsPathParams {
  clientId: string;
}

export type ImportLabelsError = Fetcher.ErrorWrapper<undefined>;

export type ImportLabelsVariables = {
  body: Schemas.ImportRequest;
  pathParams: ImportLabelsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchImportLabels = (variables: ImportLabelsVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ImportFileResponse,
    ImportLabelsError,
    Schemas.ImportRequest,
    {},
    {},
    ImportLabelsPathParams
  >({ url: '/clients/{clientId}/imports/labels', method: 'post', ...variables, signal });

export const useImportLabels = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportLabelsError,
      ImportLabelsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportLabelsError,
    ImportLabelsVariables
  >({
    mutationFn: (variables: ImportLabelsVariables) =>
      fetchImportLabels(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ImportJournalPathParams {
  clientId: string;
}

export type ImportJournalError = Fetcher.ErrorWrapper<undefined>;

export type ImportJournalVariables = {
  body: Schemas.ImportJournalRequest;
  pathParams: ImportJournalPathParams;
} & DiceContext['fetcherOptions'];

export const fetchImportJournal = (variables: ImportJournalVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ImportFileResponse,
    ImportJournalError,
    Schemas.ImportJournalRequest,
    {},
    {},
    ImportJournalPathParams
  >({ url: '/clients/{clientId}/imports/journals', method: 'post', ...variables, signal });

export const useImportJournal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportJournalError,
      ImportJournalVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportJournalError,
    ImportJournalVariables
  >({
    mutationFn: (variables: ImportJournalVariables) =>
      fetchImportJournal(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface SaveFilterDataPathParams {
  clientId: string;
}

export type SaveFilterDataError = Fetcher.ErrorWrapper<undefined>;

export type SaveFilterDataVariables = {
  body: Schemas.ImportSaveFilterDataTO;
  pathParams: SaveFilterDataPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveFilterData = (variables: SaveFilterDataVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ImportFileResponse,
    SaveFilterDataError,
    Schemas.ImportSaveFilterDataTO,
    {},
    {},
    SaveFilterDataPathParams
  >({ url: '/clients/{clientId}/imports/filter-data', method: 'post', ...variables, signal });

export const useSaveFilterData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      SaveFilterDataError,
      SaveFilterDataVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    SaveFilterDataError,
    SaveFilterDataVariables
  >({
    mutationFn: (variables: SaveFilterDataVariables) =>
      fetchSaveFilterData(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ImportAssetsPathParams {
  clientId: string;
}

export type ImportAssetsError = Fetcher.ErrorWrapper<undefined>;

export type ImportAssetsVariables = {
  body: Schemas.ImportAssetRequest;
  pathParams: ImportAssetsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchImportAssets = (variables: ImportAssetsVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ImportFileResponse,
    ImportAssetsError,
    Schemas.ImportAssetRequest,
    {},
    {},
    ImportAssetsPathParams
  >({ url: '/clients/{clientId}/imports/assets', method: 'post', ...variables, signal });

export const useImportAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportAssetsError,
      ImportAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportAssetsError,
    ImportAssetsVariables
  >({
    mutationFn: (variables: ImportAssetsVariables) =>
      fetchImportAssets(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ImportAccountPlanPathParams {
  clientId: string;
}

export type ImportAccountPlanError = Fetcher.ErrorWrapper<undefined>;

export type ImportAccountPlanVariables = {
  body: Schemas.ImportRequest;
  pathParams: ImportAccountPlanPathParams;
} & DiceContext['fetcherOptions'];

export const fetchImportAccountPlan = (
  variables: ImportAccountPlanVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportFileResponse,
    ImportAccountPlanError,
    Schemas.ImportRequest,
    {},
    {},
    ImportAccountPlanPathParams
  >({ url: '/clients/{clientId}/imports/account-plans', method: 'post', ...variables, signal });

export const useImportAccountPlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportAccountPlanError,
      ImportAccountPlanVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportAccountPlanError,
    ImportAccountPlanVariables
  >({
    mutationFn: (variables: ImportAccountPlanVariables) =>
      fetchImportAccountPlan(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateBasicFeeGroupPathParams {
  clientId: string;
}

export type CreateBasicFeeGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreateBasicFeeGroupVariables = {
  body?: Schemas.CostUnitBasicFeeGroupDTO;
  pathParams: CreateBasicFeeGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateBasicFeeGroup = (
  variables: CreateBasicFeeGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitBasicFeeGroupDTO,
    CreateBasicFeeGroupError,
    Schemas.CostUnitBasicFeeGroupDTO,
    {},
    {},
    CreateBasicFeeGroupPathParams
  >({
    url: '/clients/{clientId}/cost-unit/basic-fee/groups',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBasicFeeGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitBasicFeeGroupDTO,
      CreateBasicFeeGroupError,
      CreateBasicFeeGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitBasicFeeGroupDTO,
    CreateBasicFeeGroupError,
    CreateBasicFeeGroupVariables
  >({
    mutationFn: (variables: CreateBasicFeeGroupVariables) =>
      fetchCreateBasicFeeGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdateBasicFeeGroupsPathParams {
  clientId: string;
}

export type UpdateBasicFeeGroupsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateBasicFeeGroupsRequestBody = Schemas.CostUnitBasicFeeGroupDTO[];

export type UpdateBasicFeeGroupsVariables = {
  body?: UpdateBasicFeeGroupsRequestBody;
  pathParams: UpdateBasicFeeGroupsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateBasicFeeGroups = (
  variables: UpdateBasicFeeGroupsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdateBasicFeeGroupsError,
    UpdateBasicFeeGroupsRequestBody,
    {},
    {},
    UpdateBasicFeeGroupsPathParams
  >({
    url: '/clients/{clientId}/cost-unit/basic-fee/groups',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdateBasicFeeGroups = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateBasicFeeGroupsError,
      UpdateBasicFeeGroupsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateBasicFeeGroupsError,
    UpdateBasicFeeGroupsVariables
  >({
    mutationFn: (variables: UpdateBasicFeeGroupsVariables) =>
      fetchUpdateBasicFeeGroups(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateBasicFeeGroupEntryPathParams {
  groupId: string;
  clientId: string;
}

export type CreateBasicFeeGroupEntryError = Fetcher.ErrorWrapper<undefined>;

export type CreateBasicFeeGroupEntryVariables = {
  body?: Schemas.CostUnitBasicFeeEntryDTO;
  pathParams: CreateBasicFeeGroupEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateBasicFeeGroupEntry = (
  variables: CreateBasicFeeGroupEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitBasicFeeEntryDTO,
    CreateBasicFeeGroupEntryError,
    Schemas.CostUnitBasicFeeEntryDTO,
    {},
    {},
    CreateBasicFeeGroupEntryPathParams
  >({
    url: '/clients/{clientId}/cost-unit/basic-fee/groups/{groupId}/entries',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBasicFeeGroupEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitBasicFeeEntryDTO,
      CreateBasicFeeGroupEntryError,
      CreateBasicFeeGroupEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitBasicFeeEntryDTO,
    CreateBasicFeeGroupEntryError,
    CreateBasicFeeGroupEntryVariables
  >({
    mutationFn: (variables: CreateBasicFeeGroupEntryVariables) =>
      fetchCreateBasicFeeGroupEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAssetExtendedInfoPathParams {
  clientId: string;
}

export interface GetAssetExtendedInfoQueryParams {
  configRequest: Schemas.ConfigRequest;
  ['config-type']: string;
}

export type GetAssetExtendedInfoError = Fetcher.ErrorWrapper<undefined>;

export type GetAssetExtendedInfoVariables = {
  pathParams: GetAssetExtendedInfoPathParams;
  queryParams: GetAssetExtendedInfoQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAssetExtendedInfo = (
  variables: GetAssetExtendedInfoVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    string,
    GetAssetExtendedInfoError,
    undefined,
    {},
    GetAssetExtendedInfoQueryParams,
    GetAssetExtendedInfoPathParams
  >({ url: '/clients/{clientId}/config', method: 'get', ...variables, signal });

export function getAssetExtendedInfoQuery(variables: GetAssetExtendedInfoVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<string>;
};

export function getAssetExtendedInfoQuery(
  variables: GetAssetExtendedInfoVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<string>) | reactQuery.SkipToken;
};

export function getAssetExtendedInfoQuery(
  variables: GetAssetExtendedInfoVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/config',
      operationId: 'getAssetExtendedInfo',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAssetExtendedInfo(variables, signal),
  };
}

export const useSuspenseGetAssetExtendedInfo = <TData = string>(
  variables: GetAssetExtendedInfoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetAssetExtendedInfoError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, GetAssetExtendedInfoError, TData>({
    ...getAssetExtendedInfoQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAssetExtendedInfo = <TData = string>(
  variables: GetAssetExtendedInfoVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetAssetExtendedInfoError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, GetAssetExtendedInfoError, TData>({
    ...getAssetExtendedInfoQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface ProcessConfigPathParams {
  clientId: string;
}

export interface ProcessConfigQueryParams {
  ['config-type']: string;
}

export type ProcessConfigError = Fetcher.ErrorWrapper<undefined>;

export type ProcessConfigVariables = {
  body: Schemas.ConfigRequest;
  pathParams: ProcessConfigPathParams;
  queryParams: ProcessConfigQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchProcessConfig = (variables: ProcessConfigVariables, signal?: AbortSignal) =>
  diceFetch<
    string,
    ProcessConfigError,
    Schemas.ConfigRequest,
    {},
    ProcessConfigQueryParams,
    ProcessConfigPathParams
  >({ url: '/clients/{clientId}/config', method: 'post', ...variables, signal });

export const useProcessConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, ProcessConfigError, ProcessConfigVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, ProcessConfigError, ProcessConfigVariables>({
    mutationFn: (variables: ProcessConfigVariables) =>
      fetchProcessConfig(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CreateBabCostCenterPathParams {
  yearId: string;
  masterConfigId: string;
  creationType: 'PROFIT_CENTER' | 'COST_CENTER' | 'JOB_ID';
  clientId: string;
}

export type CreateBabCostCenterError = Fetcher.ErrorWrapper<undefined>;

export type CreateBabCostCenterVariables = {
  pathParams: CreateBabCostCenterPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateBabCostCenter = (
  variables: CreateBabCostCenterVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.BabCostCenterCreationResponse,
    CreateBabCostCenterError,
    undefined,
    {},
    {},
    CreateBabCostCenterPathParams
  >({
    url: '/clients/{clientId}/bab-cost-centers/{yearId}/{masterConfigId}/{creationType}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBabCostCenter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.BabCostCenterCreationResponse,
      CreateBabCostCenterError,
      CreateBabCostCenterVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.BabCostCenterCreationResponse,
    CreateBabCostCenterError,
    CreateBabCostCenterVariables
  >({
    mutationFn: (variables: CreateBabCostCenterVariables) =>
      fetchCreateBabCostCenter(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetCostCenterChildrenAssetPathParams {
  clientId: string;
}

export type GetCostCenterChildrenAssetError = Fetcher.ErrorWrapper<undefined>;

export type GetCostCenterChildrenAssetResponse = Schemas.CostCenterNode[];

export type GetCostCenterChildrenAssetVariables = {
  body?: Schemas.CostCenterTreeRequest;
  pathParams: GetCostCenterChildrenAssetPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetCostCenterChildrenAsset = (
  variables: GetCostCenterChildrenAssetVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostCenterChildrenAssetResponse,
    GetCostCenterChildrenAssetError,
    Schemas.CostCenterTreeRequest,
    {},
    {},
    GetCostCenterChildrenAssetPathParams
  >({ url: '/clients/{clientId}/asset-costCenter', method: 'post', ...variables, signal });

export const useGetCostCenterChildrenAsset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetCostCenterChildrenAssetResponse,
      GetCostCenterChildrenAssetError,
      GetCostCenterChildrenAssetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    GetCostCenterChildrenAssetResponse,
    GetCostCenterChildrenAssetError,
    GetCostCenterChildrenAssetVariables
  >({
    mutationFn: (variables: GetCostCenterChildrenAssetVariables) =>
      fetchGetCostCenterChildrenAsset(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface MapLabelsToAssetsByYearIdPathParams {
  clientId: string;
}

export type MapLabelsToAssetsByYearIdError = Fetcher.ErrorWrapper<undefined>;

export type MapLabelsToAssetsByYearIdVariables = {
  body?: Schemas.AssetConfigMappingRequest;
  pathParams: MapLabelsToAssetsByYearIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchMapLabelsToAssetsByYearId = (
  variables: MapLabelsToAssetsByYearIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    MapLabelsToAssetsByYearIdError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    MapLabelsToAssetsByYearIdPathParams
  >({ url: '/clients/{clientId}/asset-cc-config/labels', method: 'post', ...variables, signal });

export const useMapLabelsToAssetsByYearId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      MapLabelsToAssetsByYearIdError,
      MapLabelsToAssetsByYearIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    MapLabelsToAssetsByYearIdError,
    MapLabelsToAssetsByYearIdVariables
  >({
    mutationFn: (variables: MapLabelsToAssetsByYearIdVariables) =>
      fetchMapLabelsToAssetsByYearId(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface MapOnlyBabCcToAssetsPathParams {
  clientId: string;
}

export type MapOnlyBabCcToAssetsError = Fetcher.ErrorWrapper<undefined>;

export type MapOnlyBabCcToAssetsVariables = {
  body?: Schemas.AssetConfigMappingRequest;
  pathParams: MapOnlyBabCcToAssetsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchMapOnlyBabCcToAssets = (
  variables: MapOnlyBabCcToAssetsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    MapOnlyBabCcToAssetsError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    MapOnlyBabCcToAssetsPathParams
  >({ url: '/clients/{clientId}/asset-cc-config/bab-cc', method: 'post', ...variables, signal });

export const useMapOnlyBabCcToAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      MapOnlyBabCcToAssetsError,
      MapOnlyBabCcToAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    MapOnlyBabCcToAssetsError,
    MapOnlyBabCcToAssetsVariables
  >({
    mutationFn: (variables: MapOnlyBabCcToAssetsVariables) =>
      fetchMapOnlyBabCcToAssets(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ShareClientPathParams {
  clientId: string;
}

export interface ShareClientHeaders {
  authorization?: string;
}

export type ShareClientError = Fetcher.ErrorWrapper<undefined>;

export type ShareClientRequestBody = string[];

export type ShareClientVariables = {
  body?: ShareClientRequestBody;
  headers?: ShareClientHeaders;
  pathParams: ShareClientPathParams;
} & DiceContext['fetcherOptions'];

export const fetchShareClient = (variables: ShareClientVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    ShareClientError,
    ShareClientRequestBody,
    ShareClientHeaders,
    {},
    ShareClientPathParams
  >({ url: '/clients/share/{clientId}', method: 'post', ...variables, signal });

export const useShareClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ShareClientError, ShareClientVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, ShareClientError, ShareClientVariables>({
    mutationFn: (variables: ShareClientVariables) =>
      fetchShareClient(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface ShareClientNewPathParams {
  clientId: string;
}

export interface ShareClientNewHeaders {
  authorization?: string;
}

export type ShareClientNewError = Fetcher.ErrorWrapper<undefined>;

export type ShareClientNewRequestBody = Schemas.UserRoleMapTO[];

export type ShareClientNewVariables = {
  body?: ShareClientNewRequestBody;
  headers?: ShareClientNewHeaders;
  pathParams: ShareClientNewPathParams;
} & DiceContext['fetcherOptions'];

export const fetchShareClientNew = (variables: ShareClientNewVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    ShareClientNewError,
    ShareClientNewRequestBody,
    ShareClientNewHeaders,
    {},
    ShareClientNewPathParams
  >({ url: '/clients/share/user-management/{clientId}', method: 'post', ...variables, signal });

export const useShareClientNew = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ShareClientNewError, ShareClientNewVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, ShareClientNewError, ShareClientNewVariables>({
    mutationFn: (variables: ShareClientNewVariables) =>
      fetchShareClientNew(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface CheckAccessHeaders {
  authorization?: string;
}

export type CheckAccessError = Fetcher.ErrorWrapper<undefined>;

export type CheckAccessVariables = {
  body?: Schemas.ClientCheckAccessDTO;
  headers?: CheckAccessHeaders;
} & DiceContext['fetcherOptions'];

export const fetchCheckAccess = (variables: CheckAccessVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ClientCheckAccessResponse,
    CheckAccessError,
    Schemas.ClientCheckAccessDTO,
    CheckAccessHeaders,
    {},
    {}
  >({ url: '/client/check-access', method: 'post', ...variables, signal });

export const useCheckAccess = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ClientCheckAccessResponse,
      CheckAccessError,
      CheckAccessVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ClientCheckAccessResponse,
    CheckAccessError,
    CheckAccessVariables
  >({
    mutationFn: (variables: CheckAccessVariables) =>
      fetchCheckAccess(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdatePriceSubGroupPathParams {
  clientId: string;
}

export type UpdatePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePriceSubGroupRequestBody = Schemas.PatchPriceSheetSubGroupDTO[];

export type UpdatePriceSubGroupVariables = {
  body?: UpdatePriceSubGroupRequestBody;
  pathParams: UpdatePriceSubGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdatePriceSubGroup = (
  variables: UpdatePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdatePriceSubGroupError,
    UpdatePriceSubGroupRequestBody,
    {},
    {},
    UpdatePriceSubGroupPathParams
  >({ url: '/clients/{clientId}/price-groups/sub-groups', method: 'patch', ...variables, signal });

export const useUpdatePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdatePriceSubGroupError,
      UpdatePriceSubGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, UpdatePriceSubGroupError, UpdatePriceSubGroupVariables>({
    mutationFn: (variables: UpdatePriceSubGroupVariables) =>
      fetchUpdatePriceSubGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface MovePriceSubGroupPathParams {
  clientId: string;
}

export type MovePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type MovePriceSubGroupVariables = {
  body?: Schemas.MovePriceSheetSubgroupDTO;
  pathParams: MovePriceSubGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchMovePriceSubGroup = (
  variables: MovePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    MovePriceSubGroupError,
    Schemas.MovePriceSheetSubgroupDTO,
    {},
    {},
    MovePriceSubGroupPathParams
  >({
    url: '/clients/{clientId}/price-groups/sub-groups/move',
    method: 'patch',
    ...variables,
    signal,
  });

export const useMovePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, MovePriceSubGroupError, MovePriceSubGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, MovePriceSubGroupError, MovePriceSubGroupVariables>({
    mutationFn: (variables: MovePriceSubGroupVariables) =>
      fetchMovePriceSubGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdatePriceSubGroupEntriesPathParams {
  clientId: string;
}

export type UpdatePriceSubGroupEntriesError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePriceSubGroupEntriesRequestBody = Schemas.PriceSheetSubGroupEntryDTO[];

export type UpdatePriceSubGroupEntriesVariables = {
  body?: UpdatePriceSubGroupEntriesRequestBody;
  pathParams: UpdatePriceSubGroupEntriesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdatePriceSubGroupEntries = (
  variables: UpdatePriceSubGroupEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdatePriceSubGroupEntriesError,
    UpdatePriceSubGroupEntriesRequestBody,
    {},
    {},
    UpdatePriceSubGroupEntriesPathParams
  >({
    url: '/clients/{clientId}/price-groups/sub-groups/entries',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdatePriceSubGroupEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdatePriceSubGroupEntriesError,
      UpdatePriceSubGroupEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdatePriceSubGroupEntriesError,
    UpdatePriceSubGroupEntriesVariables
  >({
    mutationFn: (variables: UpdatePriceSubGroupEntriesVariables) =>
      fetchUpdatePriceSubGroupEntries(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface UpdateBasicFeeEntriesPathParams {
  clientId: string;
}

export type UpdateBasicFeeEntriesError = Fetcher.ErrorWrapper<undefined>;

export type UpdateBasicFeeEntriesRequestBody = Schemas.CostUnitBasicFeeEntryDTO[];

export type UpdateBasicFeeEntriesVariables = {
  body?: UpdateBasicFeeEntriesRequestBody;
  pathParams: UpdateBasicFeeEntriesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateBasicFeeEntries = (
  variables: UpdateBasicFeeEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdateBasicFeeEntriesError,
    UpdateBasicFeeEntriesRequestBody,
    {},
    {},
    UpdateBasicFeeEntriesPathParams
  >({
    url: '/clients/{clientId}/cost-unit/basic-fee/groups/entries',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdateBasicFeeEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateBasicFeeEntriesError,
      UpdateBasicFeeEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateBasicFeeEntriesError,
    UpdateBasicFeeEntriesVariables
  >({
    mutationFn: (variables: UpdateBasicFeeEntriesVariables) =>
      fetchUpdateBasicFeeEntries(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetUserRolesHeaders {
  authorization?: string;
}

export type GetUserRolesError = Fetcher.ErrorWrapper<undefined>;

export type GetUserRolesResponse = (
  | 'PWC_GLOBAL_ADMIN'
  | 'PWC_CLIENT_ADMIN'
  | 'USER'
  | 'VIEWER'
  | 'DASHBOARD_VIEWER'
)[];

export type GetUserRolesVariables = {
  headers?: GetUserRolesHeaders;
} & DiceContext['fetcherOptions'];

export const fetchGetUserRoles = (variables: GetUserRolesVariables, signal?: AbortSignal) =>
  diceFetch<GetUserRolesResponse, GetUserRolesError, undefined, GetUserRolesHeaders, {}, {}>({
    url: '/user-roles',
    method: 'get',
    ...variables,
    signal,
  });

export function getUserRolesQuery(variables: GetUserRolesVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetUserRolesResponse>;
};

export function getUserRolesQuery(variables: GetUserRolesVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetUserRolesResponse>) | reactQuery.SkipToken;
};

export function getUserRolesQuery(variables: GetUserRolesVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/user-roles',
      operationId: 'getUserRoles',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetUserRoles(variables, signal),
  };
}

export const useSuspenseGetUserRoles = <TData = GetUserRolesResponse>(
  variables: GetUserRolesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetUserRolesResponse, GetUserRolesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetUserRolesResponse, GetUserRolesError, TData>({
    ...getUserRolesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetUserRoles = <TData = GetUserRolesResponse>(
  variables: GetUserRolesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetUserRolesResponse, GetUserRolesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetUserRolesResponse, GetUserRolesError, TData>({
    ...getUserRolesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export type TestEndpointError = Fetcher.ErrorWrapper<undefined>;

export type TestEndpointVariables = DiceContext['fetcherOptions'];

export const fetchTestEndpoint = (variables: TestEndpointVariables, signal?: AbortSignal) =>
  diceFetch<string, TestEndpointError, undefined, {}, {}, {}>({
    url: '/migration/test-endpoint',
    method: 'get',
    ...variables,
    signal,
  });

export function testEndpointQuery(variables: TestEndpointVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<string>;
};

export function testEndpointQuery(variables: TestEndpointVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<string>) | reactQuery.SkipToken;
};

export function testEndpointQuery(variables: TestEndpointVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/migration/test-endpoint',
      operationId: 'testEndpoint',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchTestEndpoint(variables, signal),
  };
}

export const useSuspenseTestEndpoint = <TData = string>(
  variables: TestEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, TestEndpointError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, TestEndpointError, TData>({
    ...testEndpointQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useTestEndpoint = <TData = string>(
  variables: TestEndpointVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<string, TestEndpointError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, TestEndpointError, TData>({
    ...testEndpointQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export type HealthcheckError = Fetcher.ErrorWrapper<undefined>;

export type HealthcheckVariables = DiceContext['fetcherOptions'];

export const fetchHealthcheck = (variables: HealthcheckVariables, signal?: AbortSignal) =>
  diceFetch<string, HealthcheckError, undefined, {}, {}, {}>({
    url: '/healthcheck',
    method: 'get',
    ...variables,
    signal,
  });

export function healthcheckQuery(variables: HealthcheckVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<string>;
};

export function healthcheckQuery(variables: HealthcheckVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<string>) | reactQuery.SkipToken;
};

export function healthcheckQuery(variables: HealthcheckVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/healthcheck',
      operationId: 'healthcheck',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchHealthcheck(variables, signal),
  };
}

export const useSuspenseHealthcheck = <TData = string>(
  variables: HealthcheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthcheckError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, HealthcheckError, TData>({
    ...healthcheckQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useHealthcheck = <TData = string>(
  variables: HealthcheckVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthcheckError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, HealthcheckError, TData>({
    ...healthcheckQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetClientPathParams {
  name: string;
}

export interface GetClientHeaders {
  authorization?: string;
}

export type GetClientError = Fetcher.ErrorWrapper<undefined>;

export type GetClientVariables = {
  headers?: GetClientHeaders;
  pathParams: GetClientPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetClient = (variables: GetClientVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ClientDTO,
    GetClientError,
    undefined,
    GetClientHeaders,
    {},
    GetClientPathParams
  >({ url: '/clients/{name}', method: 'get', ...variables, signal });

export function getClientQuery(variables: GetClientVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.ClientDTO>;
};

export function getClientQuery(variables: GetClientVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.ClientDTO>) | reactQuery.SkipToken;
};

export function getClientQuery(variables: GetClientVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{name}',
      operationId: 'getClient',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetClient(variables, signal),
  };
}

export const useSuspenseGetClient = <TData = Schemas.ClientDTO>(
  variables: GetClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientDTO, GetClientError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.ClientDTO, GetClientError, TData>({
    ...getClientQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetClient = <TData = Schemas.ClientDTO>(
  variables: GetClientVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientDTO, GetClientError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.ClientDTO, GetClientError, TData>({
    ...getClientQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetProfitCenterTreeForCostUnitPathParams {
  yearId: string;
  masterConfigId: string;
  clientId: string;
}

export type GetProfitCenterTreeForCostUnitError = Fetcher.ErrorWrapper<undefined>;

export type GetProfitCenterTreeForCostUnitResponse = Record<
  string,
  Schemas.AcctTrxSelectCostUnitCriteriaDTO
>;

export type GetProfitCenterTreeForCostUnitVariables = {
  pathParams: GetProfitCenterTreeForCostUnitPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetProfitCenterTreeForCostUnit = (
  variables: GetProfitCenterTreeForCostUnitVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetProfitCenterTreeForCostUnitResponse,
    GetProfitCenterTreeForCostUnitError,
    undefined,
    {},
    {},
    GetProfitCenterTreeForCostUnitPathParams
  >({
    url: '/clients/{clientId}/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees',
    method: 'get',
    ...variables,
    signal,
  });

export function getProfitCenterTreeForCostUnitQuery(
  variables: GetProfitCenterTreeForCostUnitVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetProfitCenterTreeForCostUnitResponse>;
};

export function getProfitCenterTreeForCostUnitQuery(
  variables: GetProfitCenterTreeForCostUnitVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetProfitCenterTreeForCostUnitResponse>)
    | reactQuery.SkipToken;
};

export function getProfitCenterTreeForCostUnitQuery(
  variables: GetProfitCenterTreeForCostUnitVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees',
      operationId: 'getProfitCenterTreeForCostUnit',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetProfitCenterTreeForCostUnit(variables, signal),
  };
}

export const useSuspenseGetProfitCenterTreeForCostUnit = <
  TData = GetProfitCenterTreeForCostUnitResponse,
>(
  variables: GetProfitCenterTreeForCostUnitVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetProfitCenterTreeForCostUnitResponse,
      GetProfitCenterTreeForCostUnitError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetProfitCenterTreeForCostUnitResponse,
    GetProfitCenterTreeForCostUnitError,
    TData
  >({
    ...getProfitCenterTreeForCostUnitQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetProfitCenterTreeForCostUnit = <TData = GetProfitCenterTreeForCostUnitResponse>(
  variables: GetProfitCenterTreeForCostUnitVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetProfitCenterTreeForCostUnitResponse,
      GetProfitCenterTreeForCostUnitError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetProfitCenterTreeForCostUnitResponse,
    GetProfitCenterTreeForCostUnitError,
    TData
  >({
    ...getProfitCenterTreeForCostUnitQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetDataContainerByIdPathParams {
  ids: string[];
  clientId: string;
}

export type GetDataContainerByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetDataContainerByIdResponse = Schemas.YearDTO[];

export type GetDataContainerByIdVariables = {
  pathParams: GetDataContainerByIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetDataContainerById = (
  variables: GetDataContainerByIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetDataContainerByIdResponse,
    GetDataContainerByIdError,
    undefined,
    {},
    {},
    GetDataContainerByIdPathParams
  >({ url: '/clients/{clientId}/years/{ids}', method: 'get', ...variables, signal });

export function getDataContainerByIdQuery(variables: GetDataContainerByIdVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetDataContainerByIdResponse>;
};

export function getDataContainerByIdQuery(
  variables: GetDataContainerByIdVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetDataContainerByIdResponse>)
    | reactQuery.SkipToken;
};

export function getDataContainerByIdQuery(
  variables: GetDataContainerByIdVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/years/{ids}',
      operationId: 'getDataContainerById',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetDataContainerById(variables, signal),
  };
}

export const useSuspenseGetDataContainerById = <TData = GetDataContainerByIdResponse>(
  variables: GetDataContainerByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetDataContainerByIdResponse, GetDataContainerByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetDataContainerByIdResponse,
    GetDataContainerByIdError,
    TData
  >({
    ...getDataContainerByIdQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetDataContainerById = <TData = GetDataContainerByIdResponse>(
  variables: GetDataContainerByIdVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetDataContainerByIdResponse, GetDataContainerByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetDataContainerByIdResponse, GetDataContainerByIdError, TData>({
    ...getDataContainerByIdQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetYearCalculationPathParams {
  yearCalculationId: string;
  clientId: string;
}

export type GetYearCalculationError = Fetcher.ErrorWrapper<undefined>;

export type GetYearCalculationVariables = {
  pathParams: GetYearCalculationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetYearCalculation = (
  variables: GetYearCalculationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.YearCalculationDTO,
    GetYearCalculationError,
    undefined,
    {},
    {},
    GetYearCalculationPathParams
  >({
    url: '/clients/{clientId}/year-calculations/{yearCalculationId}',
    method: 'get',
    ...variables,
    signal,
  });

export function getYearCalculationQuery(variables: GetYearCalculationVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.YearCalculationDTO>;
};

export function getYearCalculationQuery(
  variables: GetYearCalculationVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.YearCalculationDTO>)
    | reactQuery.SkipToken;
};

export function getYearCalculationQuery(
  variables: GetYearCalculationVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}',
      operationId: 'getYearCalculation',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetYearCalculation(variables, signal),
  };
}

export const useSuspenseGetYearCalculation = <TData = Schemas.YearCalculationDTO>(
  variables: GetYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearCalculationDTO, GetYearCalculationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.YearCalculationDTO, GetYearCalculationError, TData>({
    ...getYearCalculationQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetYearCalculation = <TData = Schemas.YearCalculationDTO>(
  variables: GetYearCalculationVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearCalculationDTO, GetYearCalculationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.YearCalculationDTO, GetYearCalculationError, TData>({
    ...getYearCalculationQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationByYearCalculationPathParams {
  yearCalculationId: string;
  clientId: string;
}

export interface GetMasterConfigurationByYearCalculationHeaders {
  authorization?: string;
}

export type GetMasterConfigurationByYearCalculationError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationByYearCalculationResponse =
  Schemas.MasterConfigurationEntityDTO[];

export type GetMasterConfigurationByYearCalculationVariables = {
  headers?: GetMasterConfigurationByYearCalculationHeaders;
  pathParams: GetMasterConfigurationByYearCalculationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurationByYearCalculation = (
  variables: GetMasterConfigurationByYearCalculationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    undefined,
    GetMasterConfigurationByYearCalculationHeaders,
    {},
    GetMasterConfigurationByYearCalculationPathParams
  >({
    url: '/clients/{clientId}/year-calculations/{yearCalculationId}/master-configurations/entities',
    method: 'get',
    ...variables,
    signal,
  });

export function getMasterConfigurationByYearCalculationQuery(
  variables: GetMasterConfigurationByYearCalculationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetMasterConfigurationByYearCalculationResponse>;
};

export function getMasterConfigurationByYearCalculationQuery(
  variables: GetMasterConfigurationByYearCalculationVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetMasterConfigurationByYearCalculationResponse>)
    | reactQuery.SkipToken;
};

export function getMasterConfigurationByYearCalculationQuery(
  variables: GetMasterConfigurationByYearCalculationVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}/master-configurations/entities',
      operationId: 'getMasterConfigurationByYearCalculation',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) =>
            fetchGetMasterConfigurationByYearCalculation(variables, signal),
  };
}

export const useSuspenseGetMasterConfigurationByYearCalculation = <
  TData = GetMasterConfigurationByYearCalculationResponse,
>(
  variables: GetMasterConfigurationByYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationByYearCalculationResponse,
      GetMasterConfigurationByYearCalculationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    TData
  >({
    ...getMasterConfigurationByYearCalculationQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurationByYearCalculation = <
  TData = GetMasterConfigurationByYearCalculationResponse,
>(
  variables: GetMasterConfigurationByYearCalculationVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationByYearCalculationResponse,
      GetMasterConfigurationByYearCalculationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    TData
  >({
    ...getMasterConfigurationByYearCalculationQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetFilesMetadataPathParams {
  yearCalculationId: string;
  clientId: string;
}

export type GetFilesMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetFilesMetadataResponse = Schemas.FileMetadataDTO[];

export type GetFilesMetadataVariables = {
  pathParams: GetFilesMetadataPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFilesMetadata = (variables: GetFilesMetadataVariables, signal?: AbortSignal) =>
  diceFetch<
    GetFilesMetadataResponse,
    GetFilesMetadataError,
    undefined,
    {},
    {},
    GetFilesMetadataPathParams
  >({
    url: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata',
    method: 'get',
    ...variables,
    signal,
  });

export function getFilesMetadataQuery(variables: GetFilesMetadataVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetFilesMetadataResponse>;
};

export function getFilesMetadataQuery(
  variables: GetFilesMetadataVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetFilesMetadataResponse>) | reactQuery.SkipToken;
};

export function getFilesMetadataQuery(variables: GetFilesMetadataVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata',
      operationId: 'getFilesMetadata',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetFilesMetadata(variables, signal),
  };
}

export const useSuspenseGetFilesMetadata = <TData = GetFilesMetadataResponse>(
  variables: GetFilesMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFilesMetadataResponse, GetFilesMetadataError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetFilesMetadataResponse, GetFilesMetadataError, TData>({
    ...getFilesMetadataQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetFilesMetadata = <TData = GetFilesMetadataResponse>(
  variables: GetFilesMetadataVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFilesMetadataResponse, GetFilesMetadataError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetFilesMetadataResponse, GetFilesMetadataError, TData>({
    ...getFilesMetadataQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteFileMetadataPathParams {
  yearCalculationId: string;
  clientId: string;
}

export type DeleteFileMetadataError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileMetadataVariables = {
  pathParams: DeleteFileMetadataPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFileMetadata = (
  variables: DeleteFileMetadataVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteFileMetadataError, undefined, {}, {}, DeleteFileMetadataPathParams>({
    url: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFileMetadata = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFileMetadataError, DeleteFileMetadataVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteFileMetadataError, DeleteFileMetadataVariables>({
    mutationFn: (variables: DeleteFileMetadataVariables) =>
      fetchDeleteFileMetadata(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetFilesMetadataStatusPathParams {
  yearCalculationId: string;
  clientId: string;
}

export type GetFilesMetadataStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetFilesMetadataStatusVariables = {
  pathParams: GetFilesMetadataStatusPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFilesMetadataStatus = (
  variables: GetFilesMetadataStatusVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.InputDataStatusDTO,
    GetFilesMetadataStatusError,
    undefined,
    {},
    {},
    GetFilesMetadataStatusPathParams
  >({
    url: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata/status',
    method: 'get',
    ...variables,
    signal,
  });

export function getFilesMetadataStatusQuery(variables: GetFilesMetadataStatusVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.InputDataStatusDTO>;
};

export function getFilesMetadataStatusQuery(
  variables: GetFilesMetadataStatusVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.InputDataStatusDTO>)
    | reactQuery.SkipToken;
};

export function getFilesMetadataStatusQuery(
  variables: GetFilesMetadataStatusVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata/status',
      operationId: 'getFilesMetadataStatus',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetFilesMetadataStatus(variables, signal),
  };
}

export const useSuspenseGetFilesMetadataStatus = <TData = Schemas.InputDataStatusDTO>(
  variables: GetFilesMetadataStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.InputDataStatusDTO,
    GetFilesMetadataStatusError,
    TData
  >({
    ...getFilesMetadataStatusQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetFilesMetadataStatus = <TData = Schemas.InputDataStatusDTO>(
  variables: GetFilesMetadataStatusVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>({
    ...getFilesMetadataStatusQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetPublicFacilityPathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface GetPublicFacilityHeaders {
  authorization?: string;
}

export type GetPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicFacilityVariables = {
  headers?: GetPublicFacilityHeaders;
  pathParams: GetPublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPublicFacility = (
  variables: GetPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PublicFacilityDTO,
    GetPublicFacilityError,
    undefined,
    GetPublicFacilityHeaders,
    {},
    GetPublicFacilityPathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}',
    method: 'get',
    ...variables,
    signal,
  });

export function getPublicFacilityQuery(variables: GetPublicFacilityVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.PublicFacilityDTO>;
};

export function getPublicFacilityQuery(
  variables: GetPublicFacilityVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.PublicFacilityDTO>) | reactQuery.SkipToken;
};

export function getPublicFacilityQuery(
  variables: GetPublicFacilityVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}',
      operationId: 'getPublicFacility',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetPublicFacility(variables, signal),
  };
}

export const useSuspenseGetPublicFacility = <TData = Schemas.PublicFacilityDTO>(
  variables: GetPublicFacilityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>({
    ...getPublicFacilityQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetPublicFacility = <TData = Schemas.PublicFacilityDTO>(
  variables: GetPublicFacilityVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>({
    ...getPublicFacilityQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface DeletePublicFacilityPathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface DeletePublicFacilityHeaders {
  authorization?: string;
}

export type DeletePublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type DeletePublicFacilityVariables = {
  headers?: DeletePublicFacilityHeaders;
  pathParams: DeletePublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePublicFacility = (
  variables: DeletePublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeletePublicFacilityError,
    undefined,
    DeletePublicFacilityHeaders,
    {},
    DeletePublicFacilityPathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePublicFacilityError,
      DeletePublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeletePublicFacilityError,
    DeletePublicFacilityVariables
  >({
    mutationFn: (variables: DeletePublicFacilityVariables) =>
      fetchDeletePublicFacility(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetMasterConfigurationsPathParams {
  publicFacilityId: string;
  clientId: string;
}

export type GetMasterConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationsResponse = Schemas.YearExtendedDTOV2[];

export type GetMasterConfigurationsVariables = {
  pathParams: GetMasterConfigurationsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurations = (
  variables: GetMasterConfigurationsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetMasterConfigurationsResponse,
    GetMasterConfigurationsError,
    undefined,
    {},
    {},
    GetMasterConfigurationsPathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export function getMasterConfigurationsQuery(variables: GetMasterConfigurationsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetMasterConfigurationsResponse>;
};

export function getMasterConfigurationsQuery(
  variables: GetMasterConfigurationsVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetMasterConfigurationsResponse>)
    | reactQuery.SkipToken;
};

export function getMasterConfigurationsQuery(
  variables: GetMasterConfigurationsVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations',
      operationId: 'getMasterConfigurations',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetMasterConfigurations(variables, signal),
  };
}

export const useSuspenseGetMasterConfigurations = <TData = GetMasterConfigurationsResponse>(
  variables: GetMasterConfigurationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsResponse,
      GetMasterConfigurationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetMasterConfigurationsResponse,
    GetMasterConfigurationsError,
    TData
  >({
    ...getMasterConfigurationsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurations = <TData = GetMasterConfigurationsResponse>(
  variables: GetMasterConfigurationsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsResponse,
      GetMasterConfigurationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetMasterConfigurationsResponse, GetMasterConfigurationsError, TData>({
    ...getMasterConfigurationsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetPriceSheetConfigPathParams {
  masterConfigId: string;
  yearId: string;
  clientId: string;
}

export type GetPriceSheetConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetPriceSheetConfigResponse = Schemas.PriceSheetGroupDTO[];

export type GetPriceSheetConfigVariables = {
  pathParams: GetPriceSheetConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPriceSheetConfig = (
  variables: GetPriceSheetConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetPriceSheetConfigResponse,
    GetPriceSheetConfigError,
    undefined,
    {},
    {},
    GetPriceSheetConfigPathParams
  >({
    url: '/clients/{clientId}/price-groups/years/{yearId}/master-configs/{masterConfigId}',
    method: 'get',
    ...variables,
    signal,
  });

export function getPriceSheetConfigQuery(variables: GetPriceSheetConfigVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetPriceSheetConfigResponse>;
};

export function getPriceSheetConfigQuery(
  variables: GetPriceSheetConfigVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetPriceSheetConfigResponse>)
    | reactQuery.SkipToken;
};

export function getPriceSheetConfigQuery(
  variables: GetPriceSheetConfigVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/price-groups/years/{yearId}/master-configs/{masterConfigId}',
      operationId: 'getPriceSheetConfig',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetPriceSheetConfig(variables, signal),
  };
}

export const useSuspenseGetPriceSheetConfig = <TData = GetPriceSheetConfigResponse>(
  variables: GetPriceSheetConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>({
    ...getPriceSheetConfigQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetPriceSheetConfig = <TData = GetPriceSheetConfigResponse>(
  variables: GetPriceSheetConfigVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>({
    ...getPriceSheetConfigQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetPriceSheetReportPathParams {
  masterConfigId: string;
  yearId: string;
  clientId: string;
}

export type GetPriceSheetReportError = Fetcher.ErrorWrapper<undefined>;

export type GetPriceSheetReportResponse = Schemas.PriceSheetReportGroupDTO[];

export type GetPriceSheetReportVariables = {
  pathParams: GetPriceSheetReportPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPriceSheetReport = (
  variables: GetPriceSheetReportVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetPriceSheetReportResponse,
    GetPriceSheetReportError,
    undefined,
    {},
    {},
    GetPriceSheetReportPathParams
  >({
    url: '/clients/{clientId}/price-groups/years/{yearId}/master-configs/{masterConfigId}/reports',
    method: 'get',
    ...variables,
    signal,
  });

export function getPriceSheetReportQuery(variables: GetPriceSheetReportVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetPriceSheetReportResponse>;
};

export function getPriceSheetReportQuery(
  variables: GetPriceSheetReportVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetPriceSheetReportResponse>)
    | reactQuery.SkipToken;
};

export function getPriceSheetReportQuery(
  variables: GetPriceSheetReportVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/price-groups/years/{yearId}/master-configs/{masterConfigId}/reports',
      operationId: 'getPriceSheetReport',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetPriceSheetReport(variables, signal),
  };
}

export const useSuspenseGetPriceSheetReport = <TData = GetPriceSheetReportResponse>(
  variables: GetPriceSheetReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>({
    ...getPriceSheetReportQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetPriceSheetReport = <TData = GetPriceSheetReportResponse>(
  variables: GetPriceSheetReportVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>({
    ...getPriceSheetReportQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetPlannedCostRevenueConfigPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetPlannedCostRevenueConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetPlannedCostRevenueConfigVariables = {
  pathParams: GetPlannedCostRevenueConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPlannedCostRevenueConfig = (
  variables: GetPlannedCostRevenueConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    undefined,
    {},
    {},
    GetPlannedCostRevenueConfigPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue',
    method: 'get',
    ...variables,
    signal,
  });

export function getPlannedCostRevenueConfigQuery(variables: GetPlannedCostRevenueConfigVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.PlannedCostRevenueConfigDTO>;
};

export function getPlannedCostRevenueConfigQuery(
  variables: GetPlannedCostRevenueConfigVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.PlannedCostRevenueConfigDTO>)
    | reactQuery.SkipToken;
};

export function getPlannedCostRevenueConfigQuery(
  variables: GetPlannedCostRevenueConfigVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue',
      operationId: 'getPlannedCostRevenueConfig',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetPlannedCostRevenueConfig(variables, signal),
  };
}

export const useSuspenseGetPlannedCostRevenueConfig = <TData = Schemas.PlannedCostRevenueConfigDTO>(
  variables: GetPlannedCostRevenueConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      GetPlannedCostRevenueConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    TData
  >({
    ...getPlannedCostRevenueConfigQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetPlannedCostRevenueConfig = <TData = Schemas.PlannedCostRevenueConfigDTO>(
  variables: GetPlannedCostRevenueConfigVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      GetPlannedCostRevenueConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    TData
  >({
    ...getPlannedCostRevenueConfigQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetPlannedCostRevenuePreselectedValuesPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetPlannedCostRevenuePreselectedValuesError = Fetcher.ErrorWrapper<undefined>;

export type GetPlannedCostRevenuePreselectedValuesVariables = {
  pathParams: GetPlannedCostRevenuePreselectedValuesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPlannedCostRevenuePreselectedValues = (
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    undefined,
    {},
    {},
    GetPlannedCostRevenuePreselectedValuesPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values',
    method: 'get',
    ...variables,
    signal,
  });

export function getPlannedCostRevenuePreselectedValuesQuery(
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.PlannedCostRevenuePreselectedValuesDTO>;
};

export function getPlannedCostRevenuePreselectedValuesQuery(
  variables: GetPlannedCostRevenuePreselectedValuesVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.PlannedCostRevenuePreselectedValuesDTO>)
    | reactQuery.SkipToken;
};

export function getPlannedCostRevenuePreselectedValuesQuery(
  variables: GetPlannedCostRevenuePreselectedValuesVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values',
      operationId: 'getPlannedCostRevenuePreselectedValues',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) =>
            fetchGetPlannedCostRevenuePreselectedValues(variables, signal),
  };
}

export const useSuspenseGetPlannedCostRevenuePreselectedValues = <
  TData = Schemas.PlannedCostRevenuePreselectedValuesDTO,
>(
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenuePreselectedValuesDTO,
      GetPlannedCostRevenuePreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    TData
  >({
    ...getPlannedCostRevenuePreselectedValuesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetPlannedCostRevenuePreselectedValues = <
  TData = Schemas.PlannedCostRevenuePreselectedValuesDTO,
>(
  variables: GetPlannedCostRevenuePreselectedValuesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenuePreselectedValuesDTO,
      GetPlannedCostRevenuePreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    TData
  >({
    ...getPlannedCostRevenuePreselectedValuesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetCostUnitAllocationPreselectedValuesPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetCostUnitAllocationPreselectedValuesError = Fetcher.ErrorWrapper<undefined>;

export type GetCostUnitAllocationPreselectedValuesVariables = {
  pathParams: GetCostUnitAllocationPreselectedValuesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetCostUnitAllocationPreselectedValues = (
  variables: GetCostUnitAllocationPreselectedValuesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitAllocationPreselectedValuesDTO,
    GetCostUnitAllocationPreselectedValuesError,
    undefined,
    {},
    {},
    GetCostUnitAllocationPreselectedValuesPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations/preselected-values',
    method: 'get',
    ...variables,
    signal,
  });

export function getCostUnitAllocationPreselectedValuesQuery(
  variables: GetCostUnitAllocationPreselectedValuesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.CostUnitAllocationPreselectedValuesDTO>;
};

export function getCostUnitAllocationPreselectedValuesQuery(
  variables: GetCostUnitAllocationPreselectedValuesVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.CostUnitAllocationPreselectedValuesDTO>)
    | reactQuery.SkipToken;
};

export function getCostUnitAllocationPreselectedValuesQuery(
  variables: GetCostUnitAllocationPreselectedValuesVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations/preselected-values',
      operationId: 'getCostUnitAllocationPreselectedValues',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) =>
            fetchGetCostUnitAllocationPreselectedValues(variables, signal),
  };
}

export const useSuspenseGetCostUnitAllocationPreselectedValues = <
  TData = Schemas.CostUnitAllocationPreselectedValuesDTO,
>(
  variables: GetCostUnitAllocationPreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CostUnitAllocationPreselectedValuesDTO,
      GetCostUnitAllocationPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.CostUnitAllocationPreselectedValuesDTO,
    GetCostUnitAllocationPreselectedValuesError,
    TData
  >({
    ...getCostUnitAllocationPreselectedValuesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetCostUnitAllocationPreselectedValues = <
  TData = Schemas.CostUnitAllocationPreselectedValuesDTO,
>(
  variables: GetCostUnitAllocationPreselectedValuesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CostUnitAllocationPreselectedValuesDTO,
      GetCostUnitAllocationPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.CostUnitAllocationPreselectedValuesDTO,
    GetCostUnitAllocationPreselectedValuesError,
    TData
  >({
    ...getCostUnitAllocationPreselectedValuesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationByIdPathParams {
  id: string;
  clientId: string;
}

export type GetMasterConfigurationByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationByIdVariables = {
  pathParams: GetMasterConfigurationByIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurationById = (
  variables: GetMasterConfigurationByIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    GetMasterConfigurationByIdError,
    undefined,
    {},
    {},
    GetMasterConfigurationByIdPathParams
  >({ url: '/clients/{clientId}/master-configuration/{id}', method: 'get', ...variables, signal });

export function getMasterConfigurationByIdQuery(variables: GetMasterConfigurationByIdVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.MasterConfiguration>;
};

export function getMasterConfigurationByIdQuery(
  variables: GetMasterConfigurationByIdVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.MasterConfiguration>)
    | reactQuery.SkipToken;
};

export function getMasterConfigurationByIdQuery(
  variables: GetMasterConfigurationByIdVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configuration/{id}',
      operationId: 'getMasterConfigurationById',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetMasterConfigurationById(variables, signal),
  };
}

export const useSuspenseGetMasterConfigurationById = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.MasterConfiguration,
    GetMasterConfigurationByIdError,
    TData
  >({
    ...getMasterConfigurationByIdQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurationById = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationByIdVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>({
    ...getMasterConfigurationByIdQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteMasterConfigurationByIdPathParams {
  id: string;
  clientId: string;
}

export interface DeleteMasterConfigurationByIdHeaders {
  authorization?: string;
}

export type DeleteMasterConfigurationByIdError = Fetcher.ErrorWrapper<undefined>;

export type DeleteMasterConfigurationByIdVariables = {
  headers?: DeleteMasterConfigurationByIdHeaders;
  pathParams: DeleteMasterConfigurationByIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteMasterConfigurationById = (
  variables: DeleteMasterConfigurationByIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteMasterConfigurationByIdError,
    undefined,
    DeleteMasterConfigurationByIdHeaders,
    {},
    DeleteMasterConfigurationByIdPathParams
  >({
    url: '/clients/{clientId}/master-configuration/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteMasterConfigurationById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteMasterConfigurationByIdError,
      DeleteMasterConfigurationByIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteMasterConfigurationByIdError,
    DeleteMasterConfigurationByIdVariables
  >({
    mutationFn: (variables: DeleteMasterConfigurationByIdVariables) =>
      fetchDeleteMasterConfigurationById(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllMasterConfigurationsByYearPathParams {
  clientId: string;
}

export type GetAllMasterConfigurationsByYearError = Fetcher.ErrorWrapper<undefined>;

export type GetAllMasterConfigurationsByYearResponse = Record<
  string,
  Schemas.MasterConfiguration[]
>;

export type GetAllMasterConfigurationsByYearVariables = {
  pathParams: GetAllMasterConfigurationsByYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllMasterConfigurationsByYear = (
  variables: GetAllMasterConfigurationsByYearVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    undefined,
    {},
    {},
    GetAllMasterConfigurationsByYearPathParams
  >({ url: '/clients/{clientId}/master-configuration/year', method: 'get', ...variables, signal });

export function getAllMasterConfigurationsByYearQuery(
  variables: GetAllMasterConfigurationsByYearVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllMasterConfigurationsByYearResponse>;
};

export function getAllMasterConfigurationsByYearQuery(
  variables: GetAllMasterConfigurationsByYearVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAllMasterConfigurationsByYearResponse>)
    | reactQuery.SkipToken;
};

export function getAllMasterConfigurationsByYearQuery(
  variables: GetAllMasterConfigurationsByYearVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configuration/year',
      operationId: 'getAllMasterConfigurationsByYear',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllMasterConfigurationsByYear(variables, signal),
  };
}

export const useSuspenseGetAllMasterConfigurationsByYear = <
  TData = GetAllMasterConfigurationsByYearResponse,
>(
  variables: GetAllMasterConfigurationsByYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllMasterConfigurationsByYearResponse,
      GetAllMasterConfigurationsByYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    TData
  >({
    ...getAllMasterConfigurationsByYearQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllMasterConfigurationsByYear = <
  TData = GetAllMasterConfigurationsByYearResponse,
>(
  variables: GetAllMasterConfigurationsByYearVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllMasterConfigurationsByYearResponse,
      GetAllMasterConfigurationsByYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    TData
  >({
    ...getAllMasterConfigurationsByYearQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationsByYearIdPathParams {
  yearId: string;
  clientId: string;
}

export type GetMasterConfigurationsByYearIdError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationsByYearIdResponse = Schemas.MasterConfiguration[];

export type GetMasterConfigurationsByYearIdVariables = {
  pathParams: GetMasterConfigurationsByYearIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurationsByYearId = (
  variables: GetMasterConfigurationsByYearIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    undefined,
    {},
    {},
    GetMasterConfigurationsByYearIdPathParams
  >({
    url: '/clients/{clientId}/master-configuration/year/{yearId}',
    method: 'get',
    ...variables,
    signal,
  });

export function getMasterConfigurationsByYearIdQuery(
  variables: GetMasterConfigurationsByYearIdVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetMasterConfigurationsByYearIdResponse>;
};

export function getMasterConfigurationsByYearIdQuery(
  variables: GetMasterConfigurationsByYearIdVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetMasterConfigurationsByYearIdResponse>)
    | reactQuery.SkipToken;
};

export function getMasterConfigurationsByYearIdQuery(
  variables: GetMasterConfigurationsByYearIdVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configuration/year/{yearId}',
      operationId: 'getMasterConfigurationsByYearId',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetMasterConfigurationsByYearId(variables, signal),
  };
}

export const useSuspenseGetMasterConfigurationsByYearId = <
  TData = GetMasterConfigurationsByYearIdResponse,
>(
  variables: GetMasterConfigurationsByYearIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsByYearIdResponse,
      GetMasterConfigurationsByYearIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    TData
  >({
    ...getMasterConfigurationsByYearIdQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurationsByYearId = <TData = GetMasterConfigurationsByYearIdResponse>(
  variables: GetMasterConfigurationsByYearIdVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsByYearIdResponse,
      GetMasterConfigurationsByYearIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    TData
  >({
    ...getMasterConfigurationsByYearIdQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetConfigsStatusPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetConfigsStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetConfigsStatusVariables = {
  pathParams: GetConfigsStatusPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetConfigsStatus = (variables: GetConfigsStatusVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ConfigsStatusDTO,
    GetConfigsStatusError,
    undefined,
    {},
    {},
    GetConfigsStatusPathParams
  >({
    url: '/clients/{clientId}/master-configs/{masterConfigurationId}/status',
    method: 'get',
    ...variables,
    signal,
  });

export function getConfigsStatusQuery(variables: GetConfigsStatusVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.ConfigsStatusDTO>;
};

export function getConfigsStatusQuery(
  variables: GetConfigsStatusVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.ConfigsStatusDTO>) | reactQuery.SkipToken;
};

export function getConfigsStatusQuery(variables: GetConfigsStatusVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/status',
      operationId: 'getConfigsStatus',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetConfigsStatus(variables, signal),
  };
}

export const useSuspenseGetConfigsStatus = <TData = Schemas.ConfigsStatusDTO>(
  variables: GetConfigsStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>({
    ...getConfigsStatusQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetConfigsStatus = <TData = Schemas.ConfigsStatusDTO>(
  variables: GetConfigsStatusVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>({
    ...getConfigsStatusQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllocationKeyConfigPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetAllocationKeyConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetAllocationKeyConfigResponse = Schemas.AllocationKeyConfigDTO[];

export type GetAllocationKeyConfigVariables = {
  pathParams: GetAllocationKeyConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllocationKeyConfig = (
  variables: GetAllocationKeyConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllocationKeyConfigResponse,
    GetAllocationKeyConfigError,
    undefined,
    {},
    {},
    GetAllocationKeyConfigPathParams
  >({
    url: '/clients/{clientId}/master-configs/{masterConfigurationId}/allocation-key-configs',
    method: 'get',
    ...variables,
    signal,
  });

export function getAllocationKeyConfigQuery(variables: GetAllocationKeyConfigVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllocationKeyConfigResponse>;
};

export function getAllocationKeyConfigQuery(
  variables: GetAllocationKeyConfigVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAllocationKeyConfigResponse>)
    | reactQuery.SkipToken;
};

export function getAllocationKeyConfigQuery(
  variables: GetAllocationKeyConfigVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/allocation-key-configs',
      operationId: 'getAllocationKeyConfig',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllocationKeyConfig(variables, signal),
  };
}

export const useSuspenseGetAllocationKeyConfig = <TData = GetAllocationKeyConfigResponse>(
  variables: GetAllocationKeyConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllocationKeyConfigResponse, GetAllocationKeyConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllocationKeyConfigResponse,
    GetAllocationKeyConfigError,
    TData
  >({
    ...getAllocationKeyConfigQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllocationKeyConfig = <TData = GetAllocationKeyConfigResponse>(
  variables: GetAllocationKeyConfigVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllocationKeyConfigResponse, GetAllocationKeyConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllocationKeyConfigResponse, GetAllocationKeyConfigError, TData>({
    ...getAllocationKeyConfigQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllocationKeyConfigStatusPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetAllocationKeyConfigStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetAllocationKeyConfigStatusVariables = {
  pathParams: GetAllocationKeyConfigStatusPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllocationKeyConfigStatus = (
  variables: GetAllocationKeyConfigStatusVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
    GetAllocationKeyConfigStatusError,
    undefined,
    {},
    {},
    GetAllocationKeyConfigStatusPathParams
  >({
    url: '/clients/{clientId}/master-configs/{masterConfigurationId}/allocation-key-configs-status',
    method: 'get',
    ...variables,
    signal,
  });

export function getAllocationKeyConfigStatusQuery(
  variables: GetAllocationKeyConfigStatusVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (
    options: QueryFnOptions,
  ) => Promise<'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED'>;
};

export function getAllocationKeyConfigStatusQuery(
  variables: GetAllocationKeyConfigStatusVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((
        options: QueryFnOptions,
      ) => Promise<'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED'>)
    | reactQuery.SkipToken;
};

export function getAllocationKeyConfigStatusQuery(
  variables: GetAllocationKeyConfigStatusVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/allocation-key-configs-status',
      operationId: 'getAllocationKeyConfigStatus',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllocationKeyConfigStatus(variables, signal),
  };
}

export const useSuspenseGetAllocationKeyConfigStatus = <
  TData = 'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
>(
  variables: GetAllocationKeyConfigStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
      GetAllocationKeyConfigStatusError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
    GetAllocationKeyConfigStatusError,
    TData
  >({
    ...getAllocationKeyConfigStatusQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllocationKeyConfigStatus = <
  TData = 'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
>(
  variables: GetAllocationKeyConfigStatusVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
      GetAllocationKeyConfigStatusError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
    GetAllocationKeyConfigStatusError,
    TData
  >({
    ...getAllocationKeyConfigStatusQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAccountsByMasterConfigurationPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetAccountsByMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetAccountsByMasterConfigurationResponse = Schemas.AccountDTO[];

export type GetAccountsByMasterConfigurationVariables = {
  pathParams: GetAccountsByMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAccountsByMasterConfiguration = (
  variables: GetAccountsByMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    undefined,
    {},
    {},
    GetAccountsByMasterConfigurationPathParams
  >({
    url: '/clients/{clientId}/master-configs/{masterConfigurationId}/accounts',
    method: 'get',
    ...variables,
    signal,
  });

export function getAccountsByMasterConfigurationQuery(
  variables: GetAccountsByMasterConfigurationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAccountsByMasterConfigurationResponse>;
};

export function getAccountsByMasterConfigurationQuery(
  variables: GetAccountsByMasterConfigurationVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAccountsByMasterConfigurationResponse>)
    | reactQuery.SkipToken;
};

export function getAccountsByMasterConfigurationQuery(
  variables: GetAccountsByMasterConfigurationVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/accounts',
      operationId: 'getAccountsByMasterConfiguration',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAccountsByMasterConfiguration(variables, signal),
  };
}

export const useSuspenseGetAccountsByMasterConfiguration = <
  TData = GetAccountsByMasterConfigurationResponse,
>(
  variables: GetAccountsByMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAccountsByMasterConfigurationResponse,
      GetAccountsByMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    TData
  >({
    ...getAccountsByMasterConfigurationQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAccountsByMasterConfiguration = <
  TData = GetAccountsByMasterConfigurationResponse,
>(
  variables: GetAccountsByMasterConfigurationVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAccountsByMasterConfigurationResponse,
      GetAccountsByMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    TData
  >({
    ...getAccountsByMasterConfigurationQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetConfigPathParams {
  masterConfigId: string;
  clientId: string;
}

export type GetConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetConfigVariables = {
  pathParams: GetConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetConfig = (variables: GetConfigVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.CostTypeAccountConfigDTO,
    GetConfigError,
    undefined,
    {},
    {},
    GetConfigPathParams
  >({
    url: '/clients/{clientId}/master-configs/{masterConfigId}/cost-type-accounts',
    method: 'get',
    ...variables,
    signal,
  });

export function getConfigQuery(variables: GetConfigVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.CostTypeAccountConfigDTO>;
};

export function getConfigQuery(variables: GetConfigVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.CostTypeAccountConfigDTO>)
    | reactQuery.SkipToken;
};

export function getConfigQuery(variables: GetConfigVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/master-configs/{masterConfigId}/cost-type-accounts',
      operationId: 'getConfig',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetConfig(variables, signal),
  };
}

export const useSuspenseGetConfig = <TData = Schemas.CostTypeAccountConfigDTO>(
  variables: GetConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>({
    ...getConfigQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetConfig = <TData = Schemas.CostTypeAccountConfigDTO>(
  variables: GetConfigVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>({
    ...getConfigQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteConfigsPathParams {
  masterConfigId: string;
  clientId: string;
}

export type DeleteConfigsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteConfigsVariables = {
  pathParams: DeleteConfigsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteConfigs = (variables: DeleteConfigsVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteConfigsError, undefined, {}, {}, DeleteConfigsPathParams>({
    url: '/clients/{clientId}/master-configs/{masterConfigId}/cost-type-accounts',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteConfigs = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteConfigsError, DeleteConfigsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteConfigsError, DeleteConfigsVariables>({
    mutationFn: (variables: DeleteConfigsVariables) =>
      fetchDeleteConfigs(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllLabelsPathParams {
  clientId: string;
}

export interface GetAllLabelsQueryParams {
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllLabelsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLabelsResponse = Schemas.LabelRecord[];

export type GetAllLabelsVariables = {
  pathParams: GetAllLabelsPathParams;
  queryParams: GetAllLabelsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllLabels = (variables: GetAllLabelsVariables, signal?: AbortSignal) =>
  diceFetch<
    GetAllLabelsResponse,
    GetAllLabelsError,
    undefined,
    {},
    GetAllLabelsQueryParams,
    GetAllLabelsPathParams
  >({ url: '/clients/{clientId}/labels', method: 'get', ...variables, signal });

export function getAllLabelsQuery(variables: GetAllLabelsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllLabelsResponse>;
};

export function getAllLabelsQuery(variables: GetAllLabelsVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetAllLabelsResponse>) | reactQuery.SkipToken;
};

export function getAllLabelsQuery(variables: GetAllLabelsVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/labels',
      operationId: 'getAllLabels',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllLabels(variables, signal),
  };
}

export const useSuspenseGetAllLabels = <TData = GetAllLabelsResponse>(
  variables: GetAllLabelsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllLabelsResponse, GetAllLabelsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllLabelsResponse, GetAllLabelsError, TData>({
    ...getAllLabelsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllLabels = <TData = GetAllLabelsResponse>(
  variables: GetAllLabelsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllLabelsResponse, GetAllLabelsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllLabelsResponse, GetAllLabelsError, TData>({
    ...getAllLabelsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteLabelsPathParams {
  clientId: string;
}

export interface DeleteLabelsQueryParams {
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type DeleteLabelsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteLabelsVariables = {
  pathParams: DeleteLabelsPathParams;
  queryParams: DeleteLabelsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteLabels = (variables: DeleteLabelsVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    DeleteLabelsError,
    undefined,
    {},
    DeleteLabelsQueryParams,
    DeleteLabelsPathParams
  >({ url: '/clients/{clientId}/labels', method: 'delete', ...variables, signal });

export const useDeleteLabels = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteLabelsError, DeleteLabelsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteLabelsError, DeleteLabelsVariables>({
    mutationFn: (variables: DeleteLabelsVariables) =>
      fetchDeleteLabels(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface GetAllLabelsByTypePathParams {
  clientId: string;
}

export interface GetAllLabelsByTypeQueryParams {
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
  labelType:
    | 'PROFITCENTER_BEREICH'
    | 'PROFITCENTER'
    | 'KST_BEREICH'
    | 'KST'
    | 'AUFTRAG'
    | 'PROJEKT'
    | 'ANLAGEN_KLASSE'
    | 'BEWERTUNGSBEREICH';
}

export type GetAllLabelsByTypeError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLabelsByTypeResponse = Schemas.LabelRecord[];

export type GetAllLabelsByTypeVariables = {
  pathParams: GetAllLabelsByTypePathParams;
  queryParams: GetAllLabelsByTypeQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllLabelsByType = (
  variables: GetAllLabelsByTypeVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllLabelsByTypeResponse,
    GetAllLabelsByTypeError,
    undefined,
    {},
    GetAllLabelsByTypeQueryParams,
    GetAllLabelsByTypePathParams
  >({ url: '/clients/{clientId}/labels/type', method: 'get', ...variables, signal });

export function getAllLabelsByTypeQuery(variables: GetAllLabelsByTypeVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllLabelsByTypeResponse>;
};

export function getAllLabelsByTypeQuery(
  variables: GetAllLabelsByTypeVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAllLabelsByTypeResponse>)
    | reactQuery.SkipToken;
};

export function getAllLabelsByTypeQuery(
  variables: GetAllLabelsByTypeVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/labels/type',
      operationId: 'getAllLabelsByType',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllLabelsByType(variables, signal),
  };
}

export const useSuspenseGetAllLabelsByType = <TData = GetAllLabelsByTypeResponse>(
  variables: GetAllLabelsByTypeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllLabelsByTypeResponse, GetAllLabelsByTypeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllLabelsByTypeResponse, GetAllLabelsByTypeError, TData>({
    ...getAllLabelsByTypeQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllLabelsByType = <TData = GetAllLabelsByTypeResponse>(
  variables: GetAllLabelsByTypeVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllLabelsByTypeResponse, GetAllLabelsByTypeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllLabelsByTypeResponse, GetAllLabelsByTypeError, TData>({
    ...getAllLabelsByTypeQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllLabelsWithPagerPathParams {
  clientId: string;
}

export interface GetAllLabelsWithPagerQueryParams {
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
  /**
   * @format int32
   */
  page: number;
  /**
   * @format int32
   */
  pageSize: number;
}

export type GetAllLabelsWithPagerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLabelsWithPagerVariables = {
  pathParams: GetAllLabelsWithPagerPathParams;
  queryParams: GetAllLabelsWithPagerQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllLabelsWithPager = (
  variables: GetAllLabelsWithPagerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PageLabelRecord,
    GetAllLabelsWithPagerError,
    undefined,
    {},
    GetAllLabelsWithPagerQueryParams,
    GetAllLabelsWithPagerPathParams
  >({ url: '/clients/{clientId}/labels/page', method: 'get', ...variables, signal });

export function getAllLabelsWithPagerQuery(variables: GetAllLabelsWithPagerVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.PageLabelRecord>;
};

export function getAllLabelsWithPagerQuery(
  variables: GetAllLabelsWithPagerVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.PageLabelRecord>) | reactQuery.SkipToken;
};

export function getAllLabelsWithPagerQuery(
  variables: GetAllLabelsWithPagerVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/labels/page',
      operationId: 'getAllLabelsWithPager',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllLabelsWithPager(variables, signal),
  };
}

export const useSuspenseGetAllLabelsWithPager = <TData = Schemas.PageLabelRecord>(
  variables: GetAllLabelsWithPagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageLabelRecord, GetAllLabelsWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.PageLabelRecord, GetAllLabelsWithPagerError, TData>({
    ...getAllLabelsWithPagerQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllLabelsWithPager = <TData = Schemas.PageLabelRecord>(
  variables: GetAllLabelsWithPagerVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageLabelRecord, GetAllLabelsWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.PageLabelRecord, GetAllLabelsWithPagerError, TData>({
    ...getAllLabelsWithPagerQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetIndexFactorsAllYearsPathParams {
  clientId: string;
}

export type GetIndexFactorsAllYearsError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorsAllYearsResponse = Schemas.IndexFactorYear[];

export type GetIndexFactorsAllYearsVariables = {
  pathParams: GetIndexFactorsAllYearsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetIndexFactorsAllYears = (
  variables: GetIndexFactorsAllYearsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetIndexFactorsAllYearsResponse,
    GetIndexFactorsAllYearsError,
    undefined,
    {},
    {},
    GetIndexFactorsAllYearsPathParams
  >({ url: '/clients/{clientId}/index-factors/years', method: 'get', ...variables, signal });

export function getIndexFactorsAllYearsQuery(variables: GetIndexFactorsAllYearsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetIndexFactorsAllYearsResponse>;
};

export function getIndexFactorsAllYearsQuery(
  variables: GetIndexFactorsAllYearsVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetIndexFactorsAllYearsResponse>)
    | reactQuery.SkipToken;
};

export function getIndexFactorsAllYearsQuery(
  variables: GetIndexFactorsAllYearsVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/index-factors/years',
      operationId: 'getIndexFactorsAllYears',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetIndexFactorsAllYears(variables, signal),
  };
}

export const useSuspenseGetIndexFactorsAllYears = <TData = GetIndexFactorsAllYearsResponse>(
  variables: GetIndexFactorsAllYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorsAllYearsResponse,
      GetIndexFactorsAllYearsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetIndexFactorsAllYearsResponse,
    GetIndexFactorsAllYearsError,
    TData
  >({
    ...getIndexFactorsAllYearsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorsAllYears = <TData = GetIndexFactorsAllYearsResponse>(
  variables: GetIndexFactorsAllYearsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorsAllYearsResponse,
      GetIndexFactorsAllYearsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetIndexFactorsAllYearsResponse, GetIndexFactorsAllYearsError, TData>({
    ...getIndexFactorsAllYearsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetIndexFactorForYearAndFieldPathParams {
  configYear: string;
  distinctByField: string;
  clientId: string;
}

export type GetIndexFactorForYearAndFieldError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorForYearAndFieldResponse = Schemas.IndexFactor[];

export type GetIndexFactorForYearAndFieldVariables = {
  pathParams: GetIndexFactorForYearAndFieldPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetIndexFactorForYearAndField = (
  variables: GetIndexFactorForYearAndFieldVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetIndexFactorForYearAndFieldResponse,
    GetIndexFactorForYearAndFieldError,
    undefined,
    {},
    {},
    GetIndexFactorForYearAndFieldPathParams
  >({
    url: '/clients/{clientId}/index-factors/years/{configYear}/distinct-field/{distinctByField}',
    method: 'get',
    ...variables,
    signal,
  });

export function getIndexFactorForYearAndFieldQuery(
  variables: GetIndexFactorForYearAndFieldVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetIndexFactorForYearAndFieldResponse>;
};

export function getIndexFactorForYearAndFieldQuery(
  variables: GetIndexFactorForYearAndFieldVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetIndexFactorForYearAndFieldResponse>)
    | reactQuery.SkipToken;
};

export function getIndexFactorForYearAndFieldQuery(
  variables: GetIndexFactorForYearAndFieldVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/index-factors/years/{configYear}/distinct-field/{distinctByField}',
      operationId: 'getIndexFactorForYearAndField',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetIndexFactorForYearAndField(variables, signal),
  };
}

export const useSuspenseGetIndexFactorForYearAndField = <
  TData = GetIndexFactorForYearAndFieldResponse,
>(
  variables: GetIndexFactorForYearAndFieldVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorForYearAndFieldResponse,
      GetIndexFactorForYearAndFieldError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetIndexFactorForYearAndFieldResponse,
    GetIndexFactorForYearAndFieldError,
    TData
  >({
    ...getIndexFactorForYearAndFieldQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorForYearAndField = <TData = GetIndexFactorForYearAndFieldResponse>(
  variables: GetIndexFactorForYearAndFieldVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorForYearAndFieldResponse,
      GetIndexFactorForYearAndFieldError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetIndexFactorForYearAndFieldResponse,
    GetIndexFactorForYearAndFieldError,
    TData
  >({
    ...getIndexFactorForYearAndFieldQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetIndexFactorYearsPathParams {
  onlyYears: string;
  clientId: string;
}

export type GetIndexFactorYearsError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorYearsResponse = string[];

export type GetIndexFactorYearsVariables = {
  pathParams: GetIndexFactorYearsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetIndexFactorYears = (
  variables: GetIndexFactorYearsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetIndexFactorYearsResponse,
    GetIndexFactorYearsError,
    undefined,
    {},
    {},
    GetIndexFactorYearsPathParams
  >({
    url: '/clients/{clientId}/index-factors/only-years/{onlyYears}',
    method: 'get',
    ...variables,
    signal,
  });

export function getIndexFactorYearsQuery(variables: GetIndexFactorYearsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetIndexFactorYearsResponse>;
};

export function getIndexFactorYearsQuery(
  variables: GetIndexFactorYearsVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetIndexFactorYearsResponse>)
    | reactQuery.SkipToken;
};

export function getIndexFactorYearsQuery(
  variables: GetIndexFactorYearsVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/index-factors/only-years/{onlyYears}',
      operationId: 'getIndexFactorYears',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetIndexFactorYears(variables, signal),
  };
}

export const useSuspenseGetIndexFactorYears = <TData = GetIndexFactorYearsResponse>(
  variables: GetIndexFactorYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>({
    ...getIndexFactorYearsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorYears = <TData = GetIndexFactorYearsResponse>(
  variables: GetIndexFactorYearsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>({
    ...getIndexFactorYearsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetIndexFactorDataSourcesPathParams {
  clientId: string;
}

export type GetIndexFactorDataSourcesError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorDataSourcesResponse = ('WIBERA' | 'OTHERS')[];

export type GetIndexFactorDataSourcesVariables = {
  pathParams: GetIndexFactorDataSourcesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetIndexFactorDataSources = (
  variables: GetIndexFactorDataSourcesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetIndexFactorDataSourcesResponse,
    GetIndexFactorDataSourcesError,
    undefined,
    {},
    {},
    GetIndexFactorDataSourcesPathParams
  >({ url: '/clients/{clientId}/index-factors/data-sources', method: 'get', ...variables, signal });

export function getIndexFactorDataSourcesQuery(variables: GetIndexFactorDataSourcesVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetIndexFactorDataSourcesResponse>;
};

export function getIndexFactorDataSourcesQuery(
  variables: GetIndexFactorDataSourcesVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetIndexFactorDataSourcesResponse>)
    | reactQuery.SkipToken;
};

export function getIndexFactorDataSourcesQuery(
  variables: GetIndexFactorDataSourcesVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/index-factors/data-sources',
      operationId: 'getIndexFactorDataSources',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetIndexFactorDataSources(variables, signal),
  };
}

export const useSuspenseGetIndexFactorDataSources = <TData = GetIndexFactorDataSourcesResponse>(
  variables: GetIndexFactorDataSourcesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorDataSourcesResponse,
      GetIndexFactorDataSourcesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetIndexFactorDataSourcesResponse,
    GetIndexFactorDataSourcesError,
    TData
  >({
    ...getIndexFactorDataSourcesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorDataSources = <TData = GetIndexFactorDataSourcesResponse>(
  variables: GetIndexFactorDataSourcesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorDataSourcesResponse,
      GetIndexFactorDataSourcesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetIndexFactorDataSourcesResponse,
    GetIndexFactorDataSourcesError,
    TData
  >({
    ...getIndexFactorDataSourcesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetImportLedgerAccountFiltersPathParams {
  clientId: string;
}

export interface GetImportLedgerAccountFiltersQueryParams {
  esraClientId: string;
  yearCalculationId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  erpType: string;
}

export type GetImportLedgerAccountFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetImportLedgerAccountFiltersVariables = {
  pathParams: GetImportLedgerAccountFiltersPathParams;
  queryParams: GetImportLedgerAccountFiltersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetImportLedgerAccountFilters = (
  variables: GetImportLedgerAccountFiltersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportLedgerFilteringGroupResponse,
    GetImportLedgerAccountFiltersError,
    undefined,
    {},
    GetImportLedgerAccountFiltersQueryParams,
    GetImportLedgerAccountFiltersPathParams
  >({
    url: '/clients/{clientId}/imports/ledger-account-filters',
    method: 'get',
    ...variables,
    signal,
  });

export function getImportLedgerAccountFiltersQuery(
  variables: GetImportLedgerAccountFiltersVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.ImportLedgerFilteringGroupResponse>;
};

export function getImportLedgerAccountFiltersQuery(
  variables: GetImportLedgerAccountFiltersVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.ImportLedgerFilteringGroupResponse>)
    | reactQuery.SkipToken;
};

export function getImportLedgerAccountFiltersQuery(
  variables: GetImportLedgerAccountFiltersVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/imports/ledger-account-filters',
      operationId: 'getImportLedgerAccountFilters',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetImportLedgerAccountFilters(variables, signal),
  };
}

export const useSuspenseGetImportLedgerAccountFilters = <
  TData = Schemas.ImportLedgerFilteringGroupResponse,
>(
  variables: GetImportLedgerAccountFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportLedgerFilteringGroupResponse,
      GetImportLedgerAccountFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.ImportLedgerFilteringGroupResponse,
    GetImportLedgerAccountFiltersError,
    TData
  >({
    ...getImportLedgerAccountFiltersQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetImportLedgerAccountFilters = <
  TData = Schemas.ImportLedgerFilteringGroupResponse,
>(
  variables: GetImportLedgerAccountFiltersVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportLedgerFilteringGroupResponse,
      GetImportLedgerAccountFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.ImportLedgerFilteringGroupResponse,
    GetImportLedgerAccountFiltersError,
    TData
  >({
    ...getImportLedgerAccountFiltersQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetImportJournalFiltersPathParams {
  clientId: string;
}

export interface GetImportJournalFiltersQueryParams {
  esraClientId: string;
  yearCalculationId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  isPostCalculation: boolean;
  erpType: string;
}

export type GetImportJournalFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetImportJournalFiltersVariables = {
  pathParams: GetImportJournalFiltersPathParams;
  queryParams: GetImportJournalFiltersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetImportJournalFilters = (
  variables: GetImportJournalFiltersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportJournalFilteringGroupResponse,
    GetImportJournalFiltersError,
    undefined,
    {},
    GetImportJournalFiltersQueryParams,
    GetImportJournalFiltersPathParams
  >({ url: '/clients/{clientId}/imports/journal-filters', method: 'get', ...variables, signal });

export function getImportJournalFiltersQuery(variables: GetImportJournalFiltersVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.ImportJournalFilteringGroupResponse>;
};

export function getImportJournalFiltersQuery(
  variables: GetImportJournalFiltersVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.ImportJournalFilteringGroupResponse>)
    | reactQuery.SkipToken;
};

export function getImportJournalFiltersQuery(
  variables: GetImportJournalFiltersVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/imports/journal-filters',
      operationId: 'getImportJournalFilters',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetImportJournalFilters(variables, signal),
  };
}

export const useSuspenseGetImportJournalFilters = <
  TData = Schemas.ImportJournalFilteringGroupResponse,
>(
  variables: GetImportJournalFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportJournalFilteringGroupResponse,
      GetImportJournalFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.ImportJournalFilteringGroupResponse,
    GetImportJournalFiltersError,
    TData
  >({
    ...getImportJournalFiltersQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetImportJournalFilters = <TData = Schemas.ImportJournalFilteringGroupResponse>(
  variables: GetImportJournalFiltersVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportJournalFilteringGroupResponse,
      GetImportJournalFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.ImportJournalFilteringGroupResponse,
    GetImportJournalFiltersError,
    TData
  >({
    ...getImportJournalFiltersQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetErpTypePathParams {
  clientId: string;
}

export type GetErpTypeError = Fetcher.ErrorWrapper<undefined>;

export type GetErpTypeResponse = ('SAP_ECC6' | 'SAP_S4' | 'NAVISION_365_BC' | 'KVASY')[];

export type GetErpTypeVariables = {
  pathParams: GetErpTypePathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetErpType = (variables: GetErpTypeVariables, signal?: AbortSignal) =>
  diceFetch<GetErpTypeResponse, GetErpTypeError, undefined, {}, {}, GetErpTypePathParams>({
    url: '/clients/{clientId}/imports/erpType',
    method: 'get',
    ...variables,
    signal,
  });

export function getErpTypeQuery(variables: GetErpTypeVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetErpTypeResponse>;
};

export function getErpTypeQuery(variables: GetErpTypeVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetErpTypeResponse>) | reactQuery.SkipToken;
};

export function getErpTypeQuery(variables: GetErpTypeVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/imports/erpType',
      operationId: 'getErpType',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetErpType(variables, signal),
  };
}

export const useSuspenseGetErpType = <TData = GetErpTypeResponse>(
  variables: GetErpTypeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetErpTypeResponse, GetErpTypeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetErpTypeResponse, GetErpTypeError, TData>({
    ...getErpTypeQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetErpType = <TData = GetErpTypeResponse>(
  variables: GetErpTypeVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetErpTypeResponse, GetErpTypeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetErpTypeResponse, GetErpTypeError, TData>({
    ...getErpTypeQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetImportAssetFiltersPathParams {
  clientId: string;
}

export interface GetImportAssetFiltersQueryParams {
  esraClientId: string;
  yearCalculationId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  erpType: string;
}

export type GetImportAssetFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetImportAssetFiltersVariables = {
  pathParams: GetImportAssetFiltersPathParams;
  queryParams: GetImportAssetFiltersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetImportAssetFilters = (
  variables: GetImportAssetFiltersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportAssetFilteringGroupResponse,
    GetImportAssetFiltersError,
    undefined,
    {},
    GetImportAssetFiltersQueryParams,
    GetImportAssetFiltersPathParams
  >({ url: '/clients/{clientId}/imports/asset-filters', method: 'get', ...variables, signal });

export function getImportAssetFiltersQuery(variables: GetImportAssetFiltersVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.ImportAssetFilteringGroupResponse>;
};

export function getImportAssetFiltersQuery(
  variables: GetImportAssetFiltersVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.ImportAssetFilteringGroupResponse>)
    | reactQuery.SkipToken;
};

export function getImportAssetFiltersQuery(
  variables: GetImportAssetFiltersVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/imports/asset-filters',
      operationId: 'getImportAssetFilters',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetImportAssetFilters(variables, signal),
  };
}

export const useSuspenseGetImportAssetFilters = <TData = Schemas.ImportAssetFilteringGroupResponse>(
  variables: GetImportAssetFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportAssetFilteringGroupResponse,
      GetImportAssetFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.ImportAssetFilteringGroupResponse,
    GetImportAssetFiltersError,
    TData
  >({
    ...getImportAssetFiltersQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetImportAssetFilters = <TData = Schemas.ImportAssetFilteringGroupResponse>(
  variables: GetImportAssetFiltersVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportAssetFilteringGroupResponse,
      GetImportAssetFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.ImportAssetFilteringGroupResponse,
    GetImportAssetFiltersError,
    TData
  >({
    ...getImportAssetFiltersQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetFlattenedProfitCenterTreesPathParams {
  clientId: string;
}

export interface GetFlattenedProfitCenterTreesQueryParams {
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetFlattenedProfitCenterTreesError = Fetcher.ErrorWrapper<undefined>;

export type GetFlattenedProfitCenterTreesResponse = Record<
  string,
  Schemas.AcctTrxSelectCriteriaDTO
>;

export type GetFlattenedProfitCenterTreesVariables = {
  pathParams: GetFlattenedProfitCenterTreesPathParams;
  queryParams: GetFlattenedProfitCenterTreesQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFlattenedProfitCenterTrees = (
  variables: GetFlattenedProfitCenterTreesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetFlattenedProfitCenterTreesResponse,
    GetFlattenedProfitCenterTreesError,
    undefined,
    {},
    GetFlattenedProfitCenterTreesQueryParams,
    GetFlattenedProfitCenterTreesPathParams
  >({ url: '/clients/{clientId}/flat_profit_center_tree', method: 'get', ...variables, signal });

export function getFlattenedProfitCenterTreesQuery(
  variables: GetFlattenedProfitCenterTreesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetFlattenedProfitCenterTreesResponse>;
};

export function getFlattenedProfitCenterTreesQuery(
  variables: GetFlattenedProfitCenterTreesVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetFlattenedProfitCenterTreesResponse>)
    | reactQuery.SkipToken;
};

export function getFlattenedProfitCenterTreesQuery(
  variables: GetFlattenedProfitCenterTreesVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/flat_profit_center_tree',
      operationId: 'getFlattenedProfitCenterTrees',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetFlattenedProfitCenterTrees(variables, signal),
  };
}

export const useSuspenseGetFlattenedProfitCenterTrees = <
  TData = GetFlattenedProfitCenterTreesResponse,
>(
  variables: GetFlattenedProfitCenterTreesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFlattenedProfitCenterTreesResponse,
      GetFlattenedProfitCenterTreesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetFlattenedProfitCenterTreesResponse,
    GetFlattenedProfitCenterTreesError,
    TData
  >({
    ...getFlattenedProfitCenterTreesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetFlattenedProfitCenterTrees = <TData = GetFlattenedProfitCenterTreesResponse>(
  variables: GetFlattenedProfitCenterTreesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFlattenedProfitCenterTreesResponse,
      GetFlattenedProfitCenterTreesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetFlattenedProfitCenterTreesResponse,
    GetFlattenedProfitCenterTreesError,
    TData
  >({
    ...getFlattenedProfitCenterTreesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetFilePathParams {
  clientId: string;
}

export interface GetFileQueryParams {
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetFileError = Fetcher.ErrorWrapper<undefined>;

export type GetFileVariables = {
  pathParams: GetFilePathParams;
  queryParams: GetFileQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFile = (variables: GetFileVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.FileResponse,
    GetFileError,
    undefined,
    {},
    GetFileQueryParams,
    GetFilePathParams
  >({ url: '/clients/{clientId}/files', method: 'get', ...variables, signal });

export function getFileQuery(variables: GetFileVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.FileResponse>;
};

export function getFileQuery(variables: GetFileVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.FileResponse>) | reactQuery.SkipToken;
};

export function getFileQuery(variables: GetFileVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/files',
      operationId: 'getFile',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetFile(variables, signal),
  };
}

export const useSuspenseGetFile = <TData = Schemas.FileResponse>(
  variables: GetFileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.FileResponse, GetFileError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.FileResponse, GetFileError, TData>({
    ...getFileQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetFile = <TData = Schemas.FileResponse>(
  variables: GetFileVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.FileResponse, GetFileError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.FileResponse, GetFileError, TData>({
    ...getFileQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetEsraExceptionPathParams {
  clientId: string;
}

export type GetEsraExceptionError = Fetcher.ErrorWrapper<undefined>;

export type GetEsraExceptionVariables = {
  pathParams: GetEsraExceptionPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetEsraException = (variables: GetEsraExceptionVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.EsraException,
    GetEsraExceptionError,
    undefined,
    {},
    {},
    GetEsraExceptionPathParams
  >({ url: '/clients/{clientId}/exception', method: 'get', ...variables, signal });

export function getEsraExceptionQuery(variables: GetEsraExceptionVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.EsraException>;
};

export function getEsraExceptionQuery(
  variables: GetEsraExceptionVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.EsraException>) | reactQuery.SkipToken;
};

export function getEsraExceptionQuery(variables: GetEsraExceptionVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/exception',
      operationId: 'getEsraException',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetEsraException(variables, signal),
  };
}

export const useSuspenseGetEsraException = <TData = Schemas.EsraException>(
  variables: GetEsraExceptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.EsraException, GetEsraExceptionError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.EsraException, GetEsraExceptionError, TData>({
    ...getEsraExceptionQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetEsraException = <TData = Schemas.EsraException>(
  variables: GetEsraExceptionVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.EsraException, GetEsraExceptionError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.EsraException, GetEsraExceptionError, TData>({
    ...getEsraExceptionQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetBasicFeeConfigPathParams {
  yearId: string;
  masterConfigId: string;
  costUnitShortName: string;
  clientId: string;
}

export type GetBasicFeeConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetBasicFeeConfigVariables = {
  pathParams: GetBasicFeeConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetBasicFeeConfig = (
  variables: GetBasicFeeConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitBasicFeeDTO,
    GetBasicFeeConfigError,
    undefined,
    {},
    {},
    GetBasicFeeConfigPathParams
  >({
    url: '/clients/{clientId}/cost-unit/basic-fee/years/{yearId}/master-configs/{masterConfigId}/cost-unit/{costUnitShortName}',
    method: 'get',
    ...variables,
    signal,
  });

export function getBasicFeeConfigQuery(variables: GetBasicFeeConfigVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.CostUnitBasicFeeDTO>;
};

export function getBasicFeeConfigQuery(
  variables: GetBasicFeeConfigVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.CostUnitBasicFeeDTO>)
    | reactQuery.SkipToken;
};

export function getBasicFeeConfigQuery(
  variables: GetBasicFeeConfigVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/cost-unit/basic-fee/years/{yearId}/master-configs/{masterConfigId}/cost-unit/{costUnitShortName}',
      operationId: 'getBasicFeeConfig',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetBasicFeeConfig(variables, signal),
  };
}

export const useSuspenseGetBasicFeeConfig = <TData = Schemas.CostUnitBasicFeeDTO>(
  variables: GetBasicFeeConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>({
    ...getBasicFeeConfigQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetBasicFeeConfig = <TData = Schemas.CostUnitBasicFeeDTO>(
  variables: GetBasicFeeConfigVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>({
    ...getBasicFeeConfigQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllDistinctAssetCostCentersPathParams {
  clientId: string;
}

export interface GetAllDistinctAssetCostCentersQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAllDistinctAssetCostCentersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctAssetCostCentersResponse = string[];

export type GetAllDistinctAssetCostCentersVariables = {
  pathParams: GetAllDistinctAssetCostCentersPathParams;
  queryParams: GetAllDistinctAssetCostCentersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllDistinctAssetCostCenters = (
  variables: GetAllDistinctAssetCostCentersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllDistinctAssetCostCentersResponse,
    GetAllDistinctAssetCostCentersError,
    undefined,
    {},
    GetAllDistinctAssetCostCentersQueryParams,
    GetAllDistinctAssetCostCentersPathParams
  >({ url: '/clients/{clientId}/assets_costcenters', method: 'get', ...variables, signal });

export function getAllDistinctAssetCostCentersQuery(
  variables: GetAllDistinctAssetCostCentersVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllDistinctAssetCostCentersResponse>;
};

export function getAllDistinctAssetCostCentersQuery(
  variables: GetAllDistinctAssetCostCentersVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAllDistinctAssetCostCentersResponse>)
    | reactQuery.SkipToken;
};

export function getAllDistinctAssetCostCentersQuery(
  variables: GetAllDistinctAssetCostCentersVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/assets_costcenters',
      operationId: 'getAllDistinctAssetCostCenters',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllDistinctAssetCostCenters(variables, signal),
  };
}

export const useSuspenseGetAllDistinctAssetCostCenters = <
  TData = GetAllDistinctAssetCostCentersResponse,
>(
  variables: GetAllDistinctAssetCostCentersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCostCentersResponse,
      GetAllDistinctAssetCostCentersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllDistinctAssetCostCentersResponse,
    GetAllDistinctAssetCostCentersError,
    TData
  >({
    ...getAllDistinctAssetCostCentersQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllDistinctAssetCostCenters = <TData = GetAllDistinctAssetCostCentersResponse>(
  variables: GetAllDistinctAssetCostCentersVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCostCentersResponse,
      GetAllDistinctAssetCostCentersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAllDistinctAssetCostCentersResponse,
    GetAllDistinctAssetCostCentersError,
    TData
  >({
    ...getAllDistinctAssetCostCentersQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAssetCategoryToAssetNbrWithDescriptionPathParams {
  clientId: string;
}

export interface GetAssetCategoryToAssetNbrWithDescriptionQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAssetCategoryToAssetNbrWithDescriptionError = Fetcher.ErrorWrapper<undefined>;

export type GetAssetCategoryToAssetNbrWithDescriptionResponse = Schemas.AssetCategory[];

export type GetAssetCategoryToAssetNbrWithDescriptionVariables = {
  pathParams: GetAssetCategoryToAssetNbrWithDescriptionPathParams;
  queryParams: GetAssetCategoryToAssetNbrWithDescriptionQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAssetCategoryToAssetNbrWithDescription = (
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAssetCategoryToAssetNbrWithDescriptionResponse,
    GetAssetCategoryToAssetNbrWithDescriptionError,
    undefined,
    {},
    GetAssetCategoryToAssetNbrWithDescriptionQueryParams,
    GetAssetCategoryToAssetNbrWithDescriptionPathParams
  >({
    url: '/clients/{clientId}/assets_categories/numbers/description',
    method: 'get',
    ...variables,
    signal,
  });

export function getAssetCategoryToAssetNbrWithDescriptionQuery(
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAssetCategoryToAssetNbrWithDescriptionResponse>;
};

export function getAssetCategoryToAssetNbrWithDescriptionQuery(
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAssetCategoryToAssetNbrWithDescriptionResponse>)
    | reactQuery.SkipToken;
};

export function getAssetCategoryToAssetNbrWithDescriptionQuery(
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/assets_categories/numbers/description',
      operationId: 'getAssetCategoryToAssetNbrWithDescription',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) =>
            fetchGetAssetCategoryToAssetNbrWithDescription(variables, signal),
  };
}

export const useSuspenseGetAssetCategoryToAssetNbrWithDescription = <
  TData = GetAssetCategoryToAssetNbrWithDescriptionResponse,
>(
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryToAssetNbrWithDescriptionResponse,
      GetAssetCategoryToAssetNbrWithDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAssetCategoryToAssetNbrWithDescriptionResponse,
    GetAssetCategoryToAssetNbrWithDescriptionError,
    TData
  >({
    ...getAssetCategoryToAssetNbrWithDescriptionQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAssetCategoryToAssetNbrWithDescription = <
  TData = GetAssetCategoryToAssetNbrWithDescriptionResponse,
>(
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryToAssetNbrWithDescriptionResponse,
      GetAssetCategoryToAssetNbrWithDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAssetCategoryToAssetNbrWithDescriptionResponse,
    GetAssetCategoryToAssetNbrWithDescriptionError,
    TData
  >({
    ...getAssetCategoryToAssetNbrWithDescriptionQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAssetCategoryDescriptionPathParams {
  clientId: string;
}

export interface GetAssetCategoryDescriptionQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAssetCategoryDescriptionError = Fetcher.ErrorWrapper<undefined>;

export type GetAssetCategoryDescriptionResponse = Record<string, string>;

export type GetAssetCategoryDescriptionVariables = {
  pathParams: GetAssetCategoryDescriptionPathParams;
  queryParams: GetAssetCategoryDescriptionQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAssetCategoryDescription = (
  variables: GetAssetCategoryDescriptionVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAssetCategoryDescriptionResponse,
    GetAssetCategoryDescriptionError,
    undefined,
    {},
    GetAssetCategoryDescriptionQueryParams,
    GetAssetCategoryDescriptionPathParams
  >({
    url: '/clients/{clientId}/assets_categories/description',
    method: 'get',
    ...variables,
    signal,
  });

export function getAssetCategoryDescriptionQuery(variables: GetAssetCategoryDescriptionVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAssetCategoryDescriptionResponse>;
};

export function getAssetCategoryDescriptionQuery(
  variables: GetAssetCategoryDescriptionVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAssetCategoryDescriptionResponse>)
    | reactQuery.SkipToken;
};

export function getAssetCategoryDescriptionQuery(
  variables: GetAssetCategoryDescriptionVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/assets_categories/description',
      operationId: 'getAssetCategoryDescription',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAssetCategoryDescription(variables, signal),
  };
}

export const useSuspenseGetAssetCategoryDescription = <TData = GetAssetCategoryDescriptionResponse>(
  variables: GetAssetCategoryDescriptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryDescriptionResponse,
      GetAssetCategoryDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAssetCategoryDescriptionResponse,
    GetAssetCategoryDescriptionError,
    TData
  >({
    ...getAssetCategoryDescriptionQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAssetCategoryDescription = <TData = GetAssetCategoryDescriptionResponse>(
  variables: GetAssetCategoryDescriptionVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryDescriptionResponse,
      GetAssetCategoryDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAssetCategoryDescriptionResponse,
    GetAssetCategoryDescriptionError,
    TData
  >({
    ...getAssetCategoryDescriptionQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllAssetsWithPagerPathParams {
  clientId: string;
}

export interface GetAllAssetsWithPagerQueryParams {
  yearId: string;
  /**
   * @format int32
   */
  page: number;
  /**
   * @format int32
   */
  pageSize: number;
}

export type GetAllAssetsWithPagerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssetsWithPagerVariables = {
  pathParams: GetAllAssetsWithPagerPathParams;
  queryParams: GetAllAssetsWithPagerQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssetsWithPager = (
  variables: GetAllAssetsWithPagerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PageAsset,
    GetAllAssetsWithPagerError,
    undefined,
    {},
    GetAllAssetsWithPagerQueryParams,
    GetAllAssetsWithPagerPathParams
  >({ url: '/clients/{clientId}/assets/page', method: 'get', ...variables, signal });

export function getAllAssetsWithPagerQuery(variables: GetAllAssetsWithPagerVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.PageAsset>;
};

export function getAllAssetsWithPagerQuery(
  variables: GetAllAssetsWithPagerVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.PageAsset>) | reactQuery.SkipToken;
};

export function getAllAssetsWithPagerQuery(
  variables: GetAllAssetsWithPagerVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/assets/page',
      operationId: 'getAllAssetsWithPager',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllAssetsWithPager(variables, signal),
  };
}

export const useSuspenseGetAllAssetsWithPager = <TData = Schemas.PageAsset>(
  variables: GetAllAssetsWithPagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>({
    ...getAllAssetsWithPagerQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssetsWithPager = <TData = Schemas.PageAsset>(
  variables: GetAllAssetsWithPagerVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>({
    ...getAllAssetsWithPagerQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllDistinctAssetCategoriesPathParams {
  clientId: string;
}

export interface GetAllDistinctAssetCategoriesQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAllDistinctAssetCategoriesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctAssetCategoriesResponse = string[];

export type GetAllDistinctAssetCategoriesVariables = {
  pathParams: GetAllDistinctAssetCategoriesPathParams;
  queryParams: GetAllDistinctAssetCategoriesQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllDistinctAssetCategories = (
  variables: GetAllDistinctAssetCategoriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllDistinctAssetCategoriesResponse,
    GetAllDistinctAssetCategoriesError,
    undefined,
    {},
    GetAllDistinctAssetCategoriesQueryParams,
    GetAllDistinctAssetCategoriesPathParams
  >({ url: '/clients/{clientId}/asset_categories', method: 'get', ...variables, signal });

export function getAllDistinctAssetCategoriesQuery(
  variables: GetAllDistinctAssetCategoriesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetAllDistinctAssetCategoriesResponse>;
};

export function getAllDistinctAssetCategoriesQuery(
  variables: GetAllDistinctAssetCategoriesVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<GetAllDistinctAssetCategoriesResponse>)
    | reactQuery.SkipToken;
};

export function getAllDistinctAssetCategoriesQuery(
  variables: GetAllDistinctAssetCategoriesVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/asset_categories',
      operationId: 'getAllDistinctAssetCategories',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetAllDistinctAssetCategories(variables, signal),
  };
}

export const useSuspenseGetAllDistinctAssetCategories = <
  TData = GetAllDistinctAssetCategoriesResponse,
>(
  variables: GetAllDistinctAssetCategoriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCategoriesResponse,
      GetAllDistinctAssetCategoriesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllDistinctAssetCategoriesResponse,
    GetAllDistinctAssetCategoriesError,
    TData
  >({
    ...getAllDistinctAssetCategoriesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllDistinctAssetCategories = <TData = GetAllDistinctAssetCategoriesResponse>(
  variables: GetAllDistinctAssetCategoriesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCategoriesResponse,
      GetAllDistinctAssetCategoriesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAllDistinctAssetCategoriesResponse,
    GetAllDistinctAssetCategoriesError,
    TData
  >({
    ...getAllDistinctAssetCategoriesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface FindGroupsPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type FindGroupsError = Fetcher.ErrorWrapper<undefined>;

export type FindGroupsResponse = Schemas.AssetGroupDTO[];

export type FindGroupsVariables = {
  pathParams: FindGroupsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchFindGroups = (variables: FindGroupsVariables, signal?: AbortSignal) =>
  diceFetch<FindGroupsResponse, FindGroupsError, undefined, {}, {}, FindGroupsPathParams>({
    url: '/clients/{clientId}/asset-group/masterConfiguration/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export function findGroupsQuery(variables: FindGroupsVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<FindGroupsResponse>;
};

export function findGroupsQuery(variables: FindGroupsVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<FindGroupsResponse>) | reactQuery.SkipToken;
};

export function findGroupsQuery(variables: FindGroupsVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/asset-group/masterConfiguration/{masterConfigurationId}',
      operationId: 'findGroups',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchFindGroups(variables, signal),
  };
}

export const useSuspenseFindGroups = <TData = FindGroupsResponse>(
  variables: FindGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<FindGroupsResponse, FindGroupsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<FindGroupsResponse, FindGroupsError, TData>({
    ...findGroupsQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useFindGroups = <TData = FindGroupsResponse>(
  variables: FindGroupsVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<FindGroupsResponse, FindGroupsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<FindGroupsResponse, FindGroupsError, TData>({
    ...findGroupsQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllocationKeyConfigPreselectedValuesPathParams {
  masterConfigurationId: string;
  clientId: string;
}

export type GetAllocationKeyConfigPreselectedValuesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllocationKeyConfigPreselectedValuesVariables = {
  pathParams: GetAllocationKeyConfigPreselectedValuesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllocationKeyConfigPreselectedValues = (
  variables: GetAllocationKeyConfigPreselectedValuesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AllocationConfigPreselectedValuesDTO,
    GetAllocationKeyConfigPreselectedValuesError,
    undefined,
    {},
    {},
    GetAllocationKeyConfigPreselectedValuesPathParams
  >({
    url: '/clients/{clientId}/allocation-key-config/preselected-values/master-configs/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export function getAllocationKeyConfigPreselectedValuesQuery(
  variables: GetAllocationKeyConfigPreselectedValuesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.AllocationConfigPreselectedValuesDTO>;
};

export function getAllocationKeyConfigPreselectedValuesQuery(
  variables: GetAllocationKeyConfigPreselectedValuesVariables | reactQuery.SkipToken,
): {
  queryKey: reactQuery.QueryKey;
  queryFn:
    | ((options: QueryFnOptions) => Promise<Schemas.AllocationConfigPreselectedValuesDTO>)
    | reactQuery.SkipToken;
};

export function getAllocationKeyConfigPreselectedValuesQuery(
  variables: GetAllocationKeyConfigPreselectedValuesVariables | reactQuery.SkipToken,
) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/allocation-key-config/preselected-values/master-configs/{masterConfigurationId}',
      operationId: 'getAllocationKeyConfigPreselectedValues',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) =>
            fetchGetAllocationKeyConfigPreselectedValues(variables, signal),
  };
}

export const useSuspenseGetAllocationKeyConfigPreselectedValues = <
  TData = Schemas.AllocationConfigPreselectedValuesDTO,
>(
  variables: GetAllocationKeyConfigPreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AllocationConfigPreselectedValuesDTO,
      GetAllocationKeyConfigPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.AllocationConfigPreselectedValuesDTO,
    GetAllocationKeyConfigPreselectedValuesError,
    TData
  >({
    ...getAllocationKeyConfigPreselectedValuesQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllocationKeyConfigPreselectedValues = <
  TData = Schemas.AllocationConfigPreselectedValuesDTO,
>(
  variables: GetAllocationKeyConfigPreselectedValuesVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AllocationConfigPreselectedValuesDTO,
      GetAllocationKeyConfigPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.AllocationConfigPreselectedValuesDTO,
    GetAllocationKeyConfigPreselectedValuesError,
    TData
  >({
    ...getAllocationKeyConfigPreselectedValuesQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetClientsNewQueryParams {
  onlyArchived?: boolean;
}

export interface GetClientsNewHeaders {
  authorization?: string;
}

export type GetClientsNewError = Fetcher.ErrorWrapper<undefined>;

export type GetClientsNewVariables = {
  headers?: GetClientsNewHeaders;
  queryParams?: GetClientsNewQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetClientsNew = (variables: GetClientsNewVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.UserTO,
    GetClientsNewError,
    undefined,
    GetClientsNewHeaders,
    GetClientsNewQueryParams,
    {}
  >({ url: '/clients/user-management', method: 'get', ...variables, signal });

export function getClientsNewQuery(variables: GetClientsNewVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<Schemas.UserTO>;
};

export function getClientsNewQuery(variables: GetClientsNewVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<Schemas.UserTO>) | reactQuery.SkipToken;
};

export function getClientsNewQuery(variables: GetClientsNewVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/clients/user-management',
      operationId: 'getClientsNew',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetClientsNew(variables, signal),
  };
}

export const useSuspenseGetClientsNew = <TData = Schemas.UserTO>(
  variables: GetClientsNewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.UserTO, GetClientsNewError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.UserTO, GetClientsNewError, TData>({
    ...getClientsNewQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetClientsNew = <TData = Schemas.UserTO>(
  variables: GetClientsNewVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.UserTO, GetClientsNewError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.UserTO, GetClientsNewError, TData>({
    ...getClientsNewQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface GetClientUsersPathParams {
  clientId: string;
}

export interface GetClientUsersHeaders {
  authorization?: string;
}

export type GetClientUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetClientUsersResponse = Schemas.UserRoleMapTO[];

export type GetClientUsersVariables = {
  headers?: GetClientUsersHeaders;
  pathParams: GetClientUsersPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetClientUsers = (variables: GetClientUsersVariables, signal?: AbortSignal) =>
  diceFetch<
    GetClientUsersResponse,
    GetClientUsersError,
    undefined,
    GetClientUsersHeaders,
    {},
    GetClientUsersPathParams
  >({ url: '/client/{clientId}/users', method: 'get', ...variables, signal });

export function getClientUsersQuery(variables: GetClientUsersVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<GetClientUsersResponse>;
};

export function getClientUsersQuery(variables: GetClientUsersVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<GetClientUsersResponse>) | reactQuery.SkipToken;
};

export function getClientUsersQuery(variables: GetClientUsersVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/client/{clientId}/users',
      operationId: 'getClientUsers',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchGetClientUsers(variables, signal),
  };
}

export const useSuspenseGetClientUsers = <TData = GetClientUsersResponse>(
  variables: GetClientUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientUsersResponse, GetClientUsersError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetClientUsersResponse, GetClientUsersError, TData>({
    ...getClientUsersQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useGetClientUsers = <TData = GetClientUsersResponse>(
  variables: GetClientUsersVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientUsersResponse, GetClientUsersError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetClientUsersResponse, GetClientUsersError, TData>({
    ...getClientUsersQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export type BaseError = Fetcher.ErrorWrapper<undefined>;

export type BaseVariables = DiceContext['fetcherOptions'];

export const fetchBase = (variables: BaseVariables, signal?: AbortSignal) =>
  diceFetch<string, BaseError, undefined, {}, {}, {}>({
    url: '/',
    method: 'get',
    ...variables,
    signal,
  });

export function baseQuery(variables: BaseVariables): {
  queryKey: reactQuery.QueryKey;
  queryFn: (options: QueryFnOptions) => Promise<string>;
};

export function baseQuery(variables: BaseVariables | reactQuery.SkipToken): {
  queryKey: reactQuery.QueryKey;
  queryFn: ((options: QueryFnOptions) => Promise<string>) | reactQuery.SkipToken;
};

export function baseQuery(variables: BaseVariables | reactQuery.SkipToken) {
  return {
    queryKey: queryKeyFn({
      path: '/',
      operationId: 'base',
      variables,
    }),
    queryFn:
      variables === reactQuery.skipToken
        ? reactQuery.skipToken
        : ({ signal }: QueryFnOptions) => fetchBase(variables, signal),
  };
}

export const useSuspenseBase = <TData = string>(
  variables: BaseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, BaseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, BaseError, TData>({
    ...baseQuery(deepMerge(fetcherOptions, variables)),
    ...options,
    ...queryOptions,
  });
};

export const useBase = <TData = string>(
  variables: BaseVariables | reactQuery.SkipToken,
  options?: Omit<
    reactQuery.UseQueryOptions<string, BaseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions, fetcherOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, BaseError, TData>({
    ...baseQuery(
      variables === reactQuery.skipToken ? variables : deepMerge(fetcherOptions, variables),
    ),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteDataContainerPathParams {
  id: string;
  clientId: string;
}

export type DeleteDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type DeleteDataContainerVariables = {
  pathParams: DeleteDataContainerPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteDataContainer = (
  variables: DeleteDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteDataContainerError, undefined, {}, {}, DeleteDataContainerPathParams>({
    url: '/clients/{clientId}/years/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDataContainerError,
      DeleteDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteDataContainerError, DeleteDataContainerVariables>({
    mutationFn: (variables: DeleteDataContainerVariables) =>
      fetchDeleteDataContainer(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteFileMetadata1PathParams {
  yearCalculationId: string;
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  clientId: string;
}

export type DeleteFileMetadata1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileMetadata1Variables = {
  pathParams: DeleteFileMetadata1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFileMetadata1 = (
  variables: DeleteFileMetadata1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteFileMetadata1Error, undefined, {}, {}, DeleteFileMetadata1PathParams>({
    url: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata/type/{fileType}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFileMetadata1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteFileMetadata1Error,
      DeleteFileMetadata1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteFileMetadata1Error, DeleteFileMetadata1Variables>({
    mutationFn: (variables: DeleteFileMetadata1Variables) =>
      fetchDeleteFileMetadata1(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteYearPathParams {
  yearId: string;
  clientId: string;
}

export interface DeleteYearHeaders {
  authorization?: string;
}

export type DeleteYearError = Fetcher.ErrorWrapper<undefined>;

export type DeleteYearVariables = {
  headers?: DeleteYearHeaders;
  pathParams: DeleteYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteYear = (variables: DeleteYearVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteYearError, undefined, DeleteYearHeaders, {}, DeleteYearPathParams>({
    url: '/clients/{clientId}/v2/years/{yearId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteYearError, DeleteYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteYearError, DeleteYearVariables>({
    mutationFn: (variables: DeleteYearVariables) =>
      fetchDeleteYear(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeletePriceGroupPathParams {
  priceGroupId: string;
  clientId: string;
}

export type DeletePriceGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeletePriceGroupVariables = {
  pathParams: DeletePriceGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePriceGroup = (variables: DeletePriceGroupVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeletePriceGroupError, undefined, {}, {}, DeletePriceGroupPathParams>({
    url: '/clients/{clientId}/price-groups/{priceGroupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePriceGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeletePriceGroupError, DeletePriceGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeletePriceGroupError, DeletePriceGroupVariables>({
    mutationFn: (variables: DeletePriceGroupVariables) =>
      fetchDeletePriceGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeletePriceSubGroupPathParams {
  priceSubGroupId: string;
  clientId: string;
}

export type DeletePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeletePriceSubGroupVariables = {
  pathParams: DeletePriceSubGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePriceSubGroup = (
  variables: DeletePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeletePriceSubGroupError, undefined, {}, {}, DeletePriceSubGroupPathParams>({
    url: '/clients/{clientId}/price-groups/sub-groups/{priceSubGroupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePriceSubGroupError,
      DeletePriceSubGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeletePriceSubGroupError, DeletePriceSubGroupVariables>({
    mutationFn: (variables: DeletePriceSubGroupVariables) =>
      fetchDeletePriceSubGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeletePriceSubGroupEntriesPathParams {
  priceSubGroupEntryId: string;
  clientId: string;
}

export type DeletePriceSubGroupEntriesError = Fetcher.ErrorWrapper<undefined>;

export type DeletePriceSubGroupEntriesVariables = {
  pathParams: DeletePriceSubGroupEntriesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePriceSubGroupEntries = (
  variables: DeletePriceSubGroupEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeletePriceSubGroupEntriesError,
    undefined,
    {},
    {},
    DeletePriceSubGroupEntriesPathParams
  >({
    url: '/clients/{clientId}/price-groups/sub-groups/entries/{priceSubGroupEntryId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePriceSubGroupEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePriceSubGroupEntriesError,
      DeletePriceSubGroupEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeletePriceSubGroupEntriesError,
    DeletePriceSubGroupEntriesVariables
  >({
    mutationFn: (variables: DeletePriceSubGroupEntriesVariables) =>
      fetchDeletePriceSubGroupEntries(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteTechnicalDataEntryPathParams {
  masterConfigurationId: string;
  ids: string[];
  clientId: string;
}

export type DeleteTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteTechnicalDataEntryVariables = {
  pathParams: DeleteTechnicalDataEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteTechnicalDataEntry = (
  variables: DeleteTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteTechnicalDataEntryError,
    undefined,
    {},
    {},
    DeleteTechnicalDataEntryPathParams
  >({
    url: '/clients/{clientId}/master-configurations/{masterConfigurationId}/technical-data/{ids}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteTechnicalDataEntryError,
      DeleteTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteTechnicalDataEntryError,
    DeleteTechnicalDataEntryVariables
  >({
    mutationFn: (variables: DeleteTechnicalDataEntryVariables) =>
      fetchDeleteTechnicalDataEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteBasicFeeGroupPathParams {
  groupId: string;
  clientId: string;
}

export type DeleteBasicFeeGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeleteBasicFeeGroupVariables = {
  pathParams: DeleteBasicFeeGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteBasicFeeGroup = (
  variables: DeleteBasicFeeGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteBasicFeeGroupError, undefined, {}, {}, DeleteBasicFeeGroupPathParams>({
    url: '/clients/{clientId}/cost-unit/basic-fee/groups/{groupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteBasicFeeGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBasicFeeGroupError,
      DeleteBasicFeeGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteBasicFeeGroupError, DeleteBasicFeeGroupVariables>({
    mutationFn: (variables: DeleteBasicFeeGroupVariables) =>
      fetchDeleteBasicFeeGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteBasicFeeGroupEntryPathParams {
  groupEntryId: string;
  clientId: string;
}

export type DeleteBasicFeeGroupEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteBasicFeeGroupEntryVariables = {
  pathParams: DeleteBasicFeeGroupEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteBasicFeeGroupEntry = (
  variables: DeleteBasicFeeGroupEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteBasicFeeGroupEntryError,
    undefined,
    {},
    {},
    DeleteBasicFeeGroupEntryPathParams
  >({
    url: '/clients/{clientId}/cost-unit/basic-fee/groups/entries/{groupEntryId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteBasicFeeGroupEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBasicFeeGroupEntryError,
      DeleteBasicFeeGroupEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteBasicFeeGroupEntryError,
    DeleteBasicFeeGroupEntryVariables
  >({
    mutationFn: (variables: DeleteBasicFeeGroupEntryVariables) =>
      fetchDeleteBasicFeeGroupEntry(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteAssetPathParams {
  assetId: string;
  clientId: string;
}

export type DeleteAssetError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAssetVariables = {
  pathParams: DeleteAssetPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteAsset = (variables: DeleteAssetVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteAssetError, undefined, {}, {}, DeleteAssetPathParams>({
    url: '/clients/{clientId}/assets/id/{assetId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAsset = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAssetError, DeleteAssetVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteAssetError, DeleteAssetVariables>({
    mutationFn: (variables: DeleteAssetVariables) =>
      fetchDeleteAsset(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteAllPathParams {
  clientId: string;
}

export type DeleteAllError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAllRequestBody = string[];

export type DeleteAllVariables = {
  body?: DeleteAllRequestBody;
  pathParams: DeleteAllPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteAll = (variables: DeleteAllVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteAllError, DeleteAllRequestBody, {}, {}, DeleteAllPathParams>({
    url: '/clients/{clientId}/assets/batch',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAll = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAllError, DeleteAllVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteAllError, DeleteAllVariables>({
    mutationFn: (variables: DeleteAllVariables) =>
      fetchDeleteAll(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export interface DeleteAssetGroupPathParams {
  groupId: string;
  clientId: string;
}

export type DeleteAssetGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAssetGroupVariables = {
  pathParams: DeleteAssetGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteAssetGroup = (variables: DeleteAssetGroupVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteAssetGroupError, undefined, {}, {}, DeleteAssetGroupPathParams>({
    url: '/clients/{clientId}/asset-group/{groupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAssetGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAssetGroupError, DeleteAssetGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteAssetGroupError, DeleteAssetGroupVariables>({
    mutationFn: (variables: DeleteAssetGroupVariables) =>
      fetchDeleteAssetGroup(deepMerge(fetcherOptions, variables)),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/clients/{clientId}/years';
      operationId: 'getAllDataContainer';
      variables: GetAllDataContainerVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/public-facilities';
      operationId: 'getPublicFacilitiesByClient';
      variables: GetPublicFacilitiesByClientVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/{yearId}';
      operationId: 'getYear';
      variables: GetYearVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}';
      operationId: 'getMasterConfiguration';
      variables: GetMasterConfigurationVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/technical-data';
      operationId: 'getTechnicalDataByMasterConfigurationId';
      variables: GetTechnicalDataByMasterConfigurationIdVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations';
      operationId: 'getCostUnitAllocationConfig';
      variables: GetCostUnitAllocationConfigVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/imports/fileErpTypes';
      operationId: 'getFileErpTypes';
      variables: GetFileErpTypesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/assets';
      operationId: 'getAllAssets';
      variables: GetAllAssetsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/asset-cc-config';
      operationId: 'getAllAssets1';
      variables: GetAllAssets1Variables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/asset-category';
      operationId: 'getAllAssetCategoryConfigs';
      variables: GetAllAssetCategoryConfigsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients';
      operationId: 'getClients';
      variables: GetClientsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}/years';
      operationId: 'getYears';
      variables: GetYearsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/account-ranges';
      operationId: 'getRangeConfigForMasterConfiguration';
      variables: GetRangeConfigForMasterConfigurationVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/index-factors';
      operationId: 'getAllIndexFactors';
      variables: GetAllIndexFactorsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/config';
      operationId: 'getAssetExtendedInfo';
      variables: GetAssetExtendedInfoVariables | reactQuery.SkipToken;
    }
  | {
      path: '/user-roles';
      operationId: 'getUserRoles';
      variables: GetUserRolesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/migration/test-endpoint';
      operationId: 'testEndpoint';
      variables: TestEndpointVariables | reactQuery.SkipToken;
    }
  | {
      path: '/healthcheck';
      operationId: 'healthcheck';
      variables: HealthcheckVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{name}';
      operationId: 'getClient';
      variables: GetClientVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees';
      operationId: 'getProfitCenterTreeForCostUnit';
      variables: GetProfitCenterTreeForCostUnitVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/years/{ids}';
      operationId: 'getDataContainerById';
      variables: GetDataContainerByIdVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}';
      operationId: 'getYearCalculation';
      variables: GetYearCalculationVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}/master-configurations/entities';
      operationId: 'getMasterConfigurationByYearCalculation';
      variables: GetMasterConfigurationByYearCalculationVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata';
      operationId: 'getFilesMetadata';
      variables: GetFilesMetadataVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/year-calculations/{yearCalculationId}/files-metadata/status';
      operationId: 'getFilesMetadataStatus';
      variables: GetFilesMetadataStatusVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}';
      operationId: 'getPublicFacility';
      variables: GetPublicFacilityVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations';
      operationId: 'getMasterConfigurations';
      variables: GetMasterConfigurationsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/price-groups/years/{yearId}/master-configs/{masterConfigId}';
      operationId: 'getPriceSheetConfig';
      variables: GetPriceSheetConfigVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/price-groups/years/{yearId}/master-configs/{masterConfigId}/reports';
      operationId: 'getPriceSheetReport';
      variables: GetPriceSheetReportVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue';
      operationId: 'getPlannedCostRevenueConfig';
      variables: GetPlannedCostRevenueConfigVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values';
      operationId: 'getPlannedCostRevenuePreselectedValues';
      variables: GetPlannedCostRevenuePreselectedValuesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configurations/{masterConfigurationId}/cost-unit-allocations/preselected-values';
      operationId: 'getCostUnitAllocationPreselectedValues';
      variables: GetCostUnitAllocationPreselectedValuesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configuration/{id}';
      operationId: 'getMasterConfigurationById';
      variables: GetMasterConfigurationByIdVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configuration/year';
      operationId: 'getAllMasterConfigurationsByYear';
      variables: GetAllMasterConfigurationsByYearVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configuration/year/{yearId}';
      operationId: 'getMasterConfigurationsByYearId';
      variables: GetMasterConfigurationsByYearIdVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/status';
      operationId: 'getConfigsStatus';
      variables: GetConfigsStatusVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/allocation-key-configs';
      operationId: 'getAllocationKeyConfig';
      variables: GetAllocationKeyConfigVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/allocation-key-configs-status';
      operationId: 'getAllocationKeyConfigStatus';
      variables: GetAllocationKeyConfigStatusVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configs/{masterConfigurationId}/accounts';
      operationId: 'getAccountsByMasterConfiguration';
      variables: GetAccountsByMasterConfigurationVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/master-configs/{masterConfigId}/cost-type-accounts';
      operationId: 'getConfig';
      variables: GetConfigVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/labels';
      operationId: 'getAllLabels';
      variables: GetAllLabelsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/labels/type';
      operationId: 'getAllLabelsByType';
      variables: GetAllLabelsByTypeVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/labels/page';
      operationId: 'getAllLabelsWithPager';
      variables: GetAllLabelsWithPagerVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/index-factors/years';
      operationId: 'getIndexFactorsAllYears';
      variables: GetIndexFactorsAllYearsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/index-factors/years/{configYear}/distinct-field/{distinctByField}';
      operationId: 'getIndexFactorForYearAndField';
      variables: GetIndexFactorForYearAndFieldVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/index-factors/only-years/{onlyYears}';
      operationId: 'getIndexFactorYears';
      variables: GetIndexFactorYearsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/index-factors/data-sources';
      operationId: 'getIndexFactorDataSources';
      variables: GetIndexFactorDataSourcesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/imports/ledger-account-filters';
      operationId: 'getImportLedgerAccountFilters';
      variables: GetImportLedgerAccountFiltersVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/imports/journal-filters';
      operationId: 'getImportJournalFilters';
      variables: GetImportJournalFiltersVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/imports/erpType';
      operationId: 'getErpType';
      variables: GetErpTypeVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/imports/asset-filters';
      operationId: 'getImportAssetFilters';
      variables: GetImportAssetFiltersVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/flat_profit_center_tree';
      operationId: 'getFlattenedProfitCenterTrees';
      variables: GetFlattenedProfitCenterTreesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/files';
      operationId: 'getFile';
      variables: GetFileVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/exception';
      operationId: 'getEsraException';
      variables: GetEsraExceptionVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/cost-unit/basic-fee/years/{yearId}/master-configs/{masterConfigId}/cost-unit/{costUnitShortName}';
      operationId: 'getBasicFeeConfig';
      variables: GetBasicFeeConfigVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/assets_costcenters';
      operationId: 'getAllDistinctAssetCostCenters';
      variables: GetAllDistinctAssetCostCentersVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/assets_categories/numbers/description';
      operationId: 'getAssetCategoryToAssetNbrWithDescription';
      variables: GetAssetCategoryToAssetNbrWithDescriptionVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/assets_categories/description';
      operationId: 'getAssetCategoryDescription';
      variables: GetAssetCategoryDescriptionVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/assets/page';
      operationId: 'getAllAssetsWithPager';
      variables: GetAllAssetsWithPagerVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/asset_categories';
      operationId: 'getAllDistinctAssetCategories';
      variables: GetAllDistinctAssetCategoriesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/asset-group/masterConfiguration/{masterConfigurationId}';
      operationId: 'findGroups';
      variables: FindGroupsVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/{clientId}/allocation-key-config/preselected-values/master-configs/{masterConfigurationId}';
      operationId: 'getAllocationKeyConfigPreselectedValues';
      variables: GetAllocationKeyConfigPreselectedValuesVariables | reactQuery.SkipToken;
    }
  | {
      path: '/clients/user-management';
      operationId: 'getClientsNew';
      variables: GetClientsNewVariables | reactQuery.SkipToken;
    }
  | {
      path: '/client/{clientId}/users';
      operationId: 'getClientUsers';
      variables: GetClientUsersVariables | reactQuery.SkipToken;
    }
  | {
      path: '/';
      operationId: 'base';
      variables: BaseVariables | reactQuery.SkipToken;
    };
